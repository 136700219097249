import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum CompanyDepartment {
  ADMINISTRATION = 'Administration/General Staff',
  CUSTOMER_SERVICE = 'Customer Service/Client Service',
  EXECUTIVE_LEADERSHIP = 'Executive Leadership',
  FINANCE = 'Finance/Accounting',
  HUMAN_RESOURCES = 'Human Resources',
  LEGAL = 'Legal/Law',
  MARKETING = 'Marketing',
  OPERATIONS = 'Operations',
  PROCUREMENT = 'Procurement',
  SALES = 'Sales',
  TECHNOLOGY_HARDWARE = 'Technology Development Hardware (not only IT)',
  TECHNOLOGY_SOFTWARE = 'Technology Development Software (not only IT)',
  TECHNOLOGY_IMPLEMENTATION = 'Technology Implementation',
  OTHER = 'Other',
  DONT_WORK = 'I don\'t work'
}

export const CompanyDepartmentMetadata: MultiselectDemographicFilterMetadata<CompanyDepartment> = {
  title: 'Company Dept.',
  question: 'Which department do you primarily work within at your organization?',
  options: Object.values(CompanyDepartment)
}

// lucid question ID is 646
export enum LucidCompanyDepartment {
  ADMINISTRATION = '1',
  CUSTOMER_SERVICE = '2',
  EXECUTIVE_LEADERSHIP = '3',
  FINANCE = '4',
  HUMAN_RESOURCES = '5',
  LEGAL = '6',
  MARKETING = '7',
  OPERATIONS = '8',
  PROCUREMENT = '9',
  SALES = '10',
  TECHNOLOGY_HARDWARE = '11',
  TECHNOLOGY_SOFTWARE = '12',
  TECHNOLOGY_IMPLEMENTATION = '13',
  OTHER = '14',
  DONT_WORK = '15'
}

export const CompanyDepartmentMapper: Record<CompanyDepartment, LucidCompanyDepartment> = {
  [CompanyDepartment.ADMINISTRATION]: LucidCompanyDepartment.ADMINISTRATION,
  [CompanyDepartment.CUSTOMER_SERVICE]: LucidCompanyDepartment.CUSTOMER_SERVICE,
  [CompanyDepartment.EXECUTIVE_LEADERSHIP]: LucidCompanyDepartment.EXECUTIVE_LEADERSHIP,
  [CompanyDepartment.FINANCE]: LucidCompanyDepartment.FINANCE,
  [CompanyDepartment.HUMAN_RESOURCES]: LucidCompanyDepartment.HUMAN_RESOURCES,
  [CompanyDepartment.LEGAL]: LucidCompanyDepartment.LEGAL,
  [CompanyDepartment.MARKETING]: LucidCompanyDepartment.MARKETING,
  [CompanyDepartment.OPERATIONS]: LucidCompanyDepartment.OPERATIONS,
  [CompanyDepartment.PROCUREMENT]: LucidCompanyDepartment.PROCUREMENT,
  [CompanyDepartment.SALES]: LucidCompanyDepartment.SALES,
  [CompanyDepartment.TECHNOLOGY_HARDWARE]: LucidCompanyDepartment.TECHNOLOGY_HARDWARE,
  [CompanyDepartment.TECHNOLOGY_SOFTWARE]: LucidCompanyDepartment.TECHNOLOGY_SOFTWARE,
  [CompanyDepartment.TECHNOLOGY_IMPLEMENTATION]: LucidCompanyDepartment.TECHNOLOGY_IMPLEMENTATION,
  [CompanyDepartment.OTHER]: LucidCompanyDepartment.OTHER,
  [CompanyDepartment.DONT_WORK]: LucidCompanyDepartment.DONT_WORK
}
