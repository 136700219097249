// Disqo-only Demographic
import { DemographicFilterMetadata } from '../demographicFilterMetadata'

export enum FinancialDecisionMaker {
  YES = 'Yes',
  NO = 'No'
}

export enum FinancialDecisionMakerPrecodes {
  YES = '1',
  NO = '2'
}

export const FinancialDecisionMakerMetadata: DemographicFilterMetadata<FinancialDecisionMaker> = {
  title: 'Financial Decision Maker',
  question: 'Are you the main financial decision maker in your household?',
  options: Object.values(FinancialDecisionMaker)
}

export const FinancialDecisionMakerMapper: Record<FinancialDecisionMaker, FinancialDecisionMakerPrecodes> = {
  [FinancialDecisionMaker.YES]: FinancialDecisionMakerPrecodes.YES,
  [FinancialDecisionMaker.NO]: FinancialDecisionMakerPrecodes.NO
}
