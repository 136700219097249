export function getVariantContext (originalHtmlString: string | undefined) {
  if (!originalHtmlString) return
  // Replace is lookin for any html tags and and replacing with a delimiter
  // using delimiter to split so we can iterate and filter out legacy text
  const texts = originalHtmlString.replace(/(<([^>]+)>)/gi, '::::').split('::::').filter(t => t !== '' && ![
    'When you are finished, return here and click next to begin the survey.',
    '**Important! Please keep the link open in the separate tab, you will need it to complete the survey and you will NOT be able to reference it after.**',
    'Please CLICK ON THE LINK BELOW to open the page in a separate tab of your browser.'
  ].includes(t))

  if (texts.length === 1) return texts[0]
}
