// This file serves to map provider details to our domains, i.e. RID for sessions
// Some business logic is here, and later should be moved out once we implement a mappig layer
import {
  getQueryParams,
  mapStandardEducationToStandardEducationV2,
  mapStandardEducationV2ToStandardEducation,
  mapStandardHHIToStandardHHIUS,
  mapStandardHHIUSToStandardHHI
} from '@feedbackloop/shared'
import {
  decodePanelProvider,
  PanelProvider,
  disqoDemographicFilterToDemographicFilter,
  DisqoDemographicFilter
} from '@feedbackloop/demographics'
import { v4 as uuidv4 } from 'uuid'
import { VUE_APP_ACTIVE_MEASURE_URL } from '@/config'
import { SurveyQueueTarget, ProviderCompensationStatus, ActiveMeasureStatusCode } from '@/types'

export function getOrderIdentifiersFromUrl (
  locationSearchParams: string,
  surveyId: string
) {
  const queryParams = getQueryParams(locationSearchParams)
  const source = queryParams?.provider && decodePanelProvider(queryParams.provider)
  const { RID, OID, tid, projectId } = processProviderSpecificDemographics(queryParams, source, surveyId)
  return {
    source,
    RID: RID || tid,
    OID: OID || projectId
  }
}

export function getRedFlagFromQueryString (
  locationSearchParams: string,
  surveyId: string,
  allTagsSet: string[],
  showInternalErrorFlow: Function
) {
  const queryParams = getQueryParams(locationSearchParams)
  const source = queryParams?.provider && decodePanelProvider(queryParams.provider)
  if (!source) {
    showInternalErrorFlow()
    throw new Error('Missing SUPPLIER_NAME')
  }

  const { RID, OID, tid, projectId } = processProviderSpecificDemographics(queryParams, source, surveyId)

  if (!RID && !tid) {
    showInternalErrorFlow()
    throw new Error('Missing RID')
  }
  if (!OID && !projectId) {
    showInternalErrorFlow()
    throw new Error('Missing OID')
  }
  return {
    audienceId: surveyId,
    source,
    tagsSet: allTagsSet,
    RID,
    OID
  }
}

enum DisqoQueryStringDemographic {
  V100001 = 'V100001',
  Q2 = 'Q2',
  Q6 = 'Q6',
  Q13 = 'Q13',
  Q15 = 'Q15',
  Q36 = 'Q36',
  Q38 = 'Q38',
  IsMobile = 'IsMobile',
}

export function processProviderSpecificDemographics (queryStringVars: Record<string, string>, panelProvider: PanelProvider | undefined | '', surveyId: string): Record<string, string> {
  if (panelProvider === PanelProvider.LUCID) {
    processLucidSpecificDemographics(queryStringVars)
    return queryStringVars
  }

  if (panelProvider === PanelProvider.CINT && queryStringVars.RID && queryStringVars.OID) {
    return { ...queryStringVars }
  }

  if (panelProvider === PanelProvider.DISQO || panelProvider === PanelProvider.ACTIVE_MEASURE) {
    processDisqoSpecificDemographics(queryStringVars)
    return queryStringVars
  }

  if (panelProvider === PanelProvider.BYOA) {
    processByoaDemographics(queryStringVars, surveyId)
    return queryStringVars
  }

  return {}
}

// Map Disqo Fields to Our "Domain" fields. Note that our Domain fields are from Lucid
export function processDisqoSpecificDemographics (queryStringVars: Record<string, string>) {
  // Domain Provider Fields
  if (queryStringVars.projectId) queryStringVars.OID = queryStringVars.projectId
  if (queryStringVars.tid) queryStringVars.RID = queryStringVars.tid
  if (queryStringVars.pid) queryStringVars.PID = queryStringVars.pid

  // Demographic Question Mapping
  // https://developer.disqo.com/docs/audience-api/#tracking-and-redirects
  const DisqoQueryStringsToDemographicQueryStrings = {
    [DisqoQueryStringDemographic.V100001]: ['AGE', DisqoDemographicFilter.AGE],
    [DisqoQueryStringDemographic.Q2]: ['GENDER', DisqoDemographicFilter.GENDER],
    [DisqoQueryStringDemographic.Q6]: ['STATE', DisqoDemographicFilter.STATE],
    [DisqoQueryStringDemographic.Q13]: ['ETHNICITY', DisqoDemographicFilter.ETHNICITY],
    [DisqoQueryStringDemographic.Q15]: ['HISPANIC', DisqoDemographicFilter.HISPANICS],
    [DisqoQueryStringDemographic.Q36]: ['STANDARD_HHI_US', DisqoDemographicFilter.HHIUS],
    [DisqoQueryStringDemographic.Q38]: ['STANDARD_EMPLOYMENT', DisqoDemographicFilter.EMPLOYMENT],
    [DisqoQueryStringDemographic.IsMobile]: ['MS_is_mobile', DisqoDemographicFilter.MOBILE_DEVICE]
  }

  for (const disqoQueryKey of Object.keys(DisqoQueryStringsToDemographicQueryStrings)) {
    const disqoQueryCode = queryStringVars[disqoQueryKey]
    const [queryKey, demoFilter] = DisqoQueryStringsToDemographicQueryStrings[disqoQueryKey as DisqoQueryStringDemographic]
    queryStringVars[queryKey] = disqoDemographicFilterToDemographicFilter(demoFilter as DisqoDemographicFilter, disqoQueryCode)
  }
}

// bring your own audience needs ids generated since there is no provider
export function processByoaDemographics (queryStringVars: Record<string, string>, surveyId: string) {
  queryStringVars.OID = surveyId
  queryStringVars.RID = uuidv4()
  queryStringVars.PID = uuidv4()
}

export function processLucidSpecificDemographics (queryStringVars: Record<string, string>) {
  if (queryStringVars.STANDARD_EDUCATION_v2) queryStringVars.STANDARD_EDUCATION = queryStringVars.STANDARD_EDUCATION || mapStandardEducationV2ToStandardEducation(queryStringVars.STANDARD_EDUCATION_v2).toString()
  if (queryStringVars.STANDARD_EDUCATION) queryStringVars.STANDARD_EDUCATION_v2 = queryStringVars.STANDARD_EDUCATION_v2 || mapStandardEducationToStandardEducationV2(queryStringVars.STANDARD_EDUCATION).toString()
  if (queryStringVars.STANDARD_HHI_US) queryStringVars.STANDARD_HHI = queryStringVars.STANDARD_HHI || mapStandardHHIUSToStandardHHI(queryStringVars.STANDARD_HHI_US).toString()
  if (queryStringVars.STANDARD_HHI) queryStringVars.STANDARD_HHI_US = queryStringVars.STANDARD_HHI_US || mapStandardHHIToStandardHHIUS(queryStringVars.STANDARD_HHI).toString()
}

export function getSurveyQueueUrlForProvider (provider: PanelProvider, surveyQueueTarget: SurveyQueueTarget) {
  const baseUrl = '/api/survey-queue'

  // TODO: Add routes for all providers once kraken endpoints are split up
  if (provider === PanelProvider.BYOA || provider === PanelProvider.ACTIVE_MEASURE) {
    return { url: `${baseUrl}/byoa/survey`, httpMethod: 'get' }
  }

  if (surveyQueueTarget === SurveyQueueTarget.IndividualSurvey) {
    return { url: `${baseUrl}/survey`, httpMethod: 'get' }
  }

  return { url: `${baseUrl}/audience`, httpMethod: 'post' }
}

export function activeMeasureCompletionRedirectUrl (status: ProviderCompensationStatus | null, queryParams: Record<string, string | number>) {
  const baseUrl = getActiveMeasureBaseUrl()
  const statusCode = mapSuveyStatusToActiveMeasureStatusCode(status)
  const { PID, RID } = queryParams
  if (!statusCode) {
    throw new Error(`Received status '${status}' but no active measure redirect statusCode exists for it.`)
  }
  if (!PID || !RID) {
    console.error(
      `Missing PID or RID value for active measure test so redirect is 
      invalid.\nbaseUrl=${baseUrl}\nPID=${PID}\nRID=${RID}`
    )
    return ''
  }
  const url = `${baseUrl}?status=${statusCode}&pid=${PID}&tid=${RID}`
  return url
}

function getActiveMeasureBaseUrl () {
  const url = new URL(VUE_APP_ACTIVE_MEASURE_URL)
  return url.origin
}

function mapSuveyStatusToActiveMeasureStatusCode (status: ProviderCompensationStatus | null) {
  switch (status) {
    case ProviderCompensationStatus.FAILURE:
      return ActiveMeasureStatusCode.FAILURE
    case ProviderCompensationStatus.OVER_QUOTA:
      return ActiveMeasureStatusCode.OVER_QUOTA
    case ProviderCompensationStatus.QUALITY_TERMINATION:
      return ActiveMeasureStatusCode.QUALITY_TERMINATION
    case ProviderCompensationStatus.SUCCESS:
      return ActiveMeasureStatusCode.SUCCESS
    default:
      return null
  }
}
