import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum HHIUS {
  N_LESS_THAN_5000 = 'Less than $5,000',
  N_5000_TO_9999 = '$5,000 to $9,999',
  N_10000_TO_14999 = '$10,000 to $14,999',
  N_15000_TO_19999 = '$15,000 to $19,999',
  N_20000_TO_24999 = '$20,000 to $24,999',
  N_25000_TO_29999 = '$25,000 to $29,999',
  N_30000_TO_34999 = '$30,000 to $34,999',
  N_35000_TO_39999 = '$35,000 to $39,999',
  N_40000_TO_44999 = '$40,000 to $44,999',
  N_45000_TO_49999 = '$45,000 to $49,999',
  N_50000_TO_54999 = '$50,000 to $54,999',
  N_55000_TO_59999 = '$55,000 to $59,999',
  N_60000_TO_64999 = '$60,000 to $64,999',
  N_65000_TO_69999 = '$65,000 to $69,999',
  N_70000_TO_74999 = '$70,000 to $74,999',
  N_75000_TO_79999 = '$75,000 to $79,999',
  N_80000_TO_84999 = '$80,000 to $84,999',
  N_85000_TO_89999 = '$85,000 to $89,999',
  N_90000_TO_94999 = '$90,000 to $94,999',
  N_95000_TO_99999 = '$95,000 to $99,999',
  N_100000_TO_124999 = '$100,000 to $124,999',
  N_125000_TO_149999 = '$125,000 to $149,999',
  N_150000_TO_174999 = '$150,000 to $174,999',
  N_175000_TO_199999 = '$175,000 to $199,999',
  N_200000_TO_249999 = '$200,000 to $249,999',
  N_250000_OR_MORE = '$250,000 and above',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer'
}

export const HHIUSMetadata: MultiselectDemographicFilterMetadata<HHIUS> = {
  title: 'Household Income',
  question: 'How much total combined income do all members of your household earn before taxes?',
  options: Object.values(HHIUS)
}

// lucid question ID is 14785
export enum LucidHHIUS {
  N_LESS_THAN_5000 = '1',
  N_5000_TO_9999 = '2',
  N_10000_TO_14999 = '3',
  N_15000_TO_19999 = '4',
  N_20000_TO_24999 = '5',
  N_25000_TO_29999 = '6',
  N_30000_TO_34999 = '7',
  N_35000_TO_39999 = '8',
  N_40000_TO_44999 = '9',
  N_45000_TO_49999 = '10',
  N_50000_TO_54999 = '11',
  N_55000_TO_59999 = '12',
  N_60000_TO_64999 = '13',
  N_65000_TO_69999 = '14',
  N_70000_TO_74999 = '15',
  N_75000_TO_79999 = '16',
  N_80000_TO_84999 = '17',
  N_85000_TO_89999 = '18',
  N_90000_TO_94999 = '19',
  N_95000_TO_99999 = '20',
  N_100000_TO_124999 = '21',
  N_125000_TO_149999 = '22',
  N_150000_TO_174999 = '23',
  N_175000_TO_199999 = '24',
  N_200000_TO_249999 = '25',
  N_250000_OR_MORE = '26',
  PREFER_NOT_TO_ANSWER = '27'
}

export const HHIUSMapper: Record<HHIUS, LucidHHIUS> = {
  [HHIUS.N_LESS_THAN_5000]: LucidHHIUS.N_LESS_THAN_5000,
  [HHIUS.N_5000_TO_9999]: LucidHHIUS.N_5000_TO_9999,
  [HHIUS.N_10000_TO_14999]: LucidHHIUS.N_10000_TO_14999,
  [HHIUS.N_15000_TO_19999]: LucidHHIUS.N_15000_TO_19999,
  [HHIUS.N_20000_TO_24999]: LucidHHIUS.N_20000_TO_24999,
  [HHIUS.N_25000_TO_29999]: LucidHHIUS.N_25000_TO_29999,
  [HHIUS.N_30000_TO_34999]: LucidHHIUS.N_30000_TO_34999,
  [HHIUS.N_35000_TO_39999]: LucidHHIUS.N_35000_TO_39999,
  [HHIUS.N_40000_TO_44999]: LucidHHIUS.N_40000_TO_44999,
  [HHIUS.N_45000_TO_49999]: LucidHHIUS.N_45000_TO_49999,
  [HHIUS.N_50000_TO_54999]: LucidHHIUS.N_50000_TO_54999,
  [HHIUS.N_55000_TO_59999]: LucidHHIUS.N_55000_TO_59999,
  [HHIUS.N_60000_TO_64999]: LucidHHIUS.N_60000_TO_64999,
  [HHIUS.N_65000_TO_69999]: LucidHHIUS.N_65000_TO_69999,
  [HHIUS.N_70000_TO_74999]: LucidHHIUS.N_70000_TO_74999,
  [HHIUS.N_75000_TO_79999]: LucidHHIUS.N_75000_TO_79999,
  [HHIUS.N_80000_TO_84999]: LucidHHIUS.N_80000_TO_84999,
  [HHIUS.N_85000_TO_89999]: LucidHHIUS.N_85000_TO_89999,
  [HHIUS.N_90000_TO_94999]: LucidHHIUS.N_90000_TO_94999,
  [HHIUS.N_95000_TO_99999]: LucidHHIUS.N_95000_TO_99999,
  [HHIUS.N_100000_TO_124999]: LucidHHIUS.N_100000_TO_124999,
  [HHIUS.N_125000_TO_149999]: LucidHHIUS.N_125000_TO_149999,
  [HHIUS.N_150000_TO_174999]: LucidHHIUS.N_150000_TO_174999,
  [HHIUS.N_175000_TO_199999]: LucidHHIUS.N_175000_TO_199999,
  [HHIUS.N_200000_TO_249999]: LucidHHIUS.N_200000_TO_249999,
  [HHIUS.N_250000_OR_MORE]: LucidHHIUS.N_250000_OR_MORE,
  [HHIUS.PREFER_NOT_TO_ANSWER]: LucidHHIUS.PREFER_NOT_TO_ANSWER
}

// lucid question ID is 61076
export enum LucidHHI {
  N_LESS_THAN_14999 = '1',
  N_15000_TO_19999 = '2',
  N_20000_TO_24999 = '3',
  N_25000_TO_29999 = '4',
  N_30000_TO_34999 = '5',
  N_35000_TO_39999 = '6',
  N_40000_TO_44999 = '7',
  N_45000_TO_49999 = '8',
  N_50000_TO_54999 = '9',
  N_55000_TO_59999 = '10',
  N_60000_TO_64999 = '11',
  N_65000_TO_69999 = '12',
  N_70000_TO_74999 = '13',
  N_75000_TO_79999 = '14',
  N_80000_TO_84999 = '15',
  N_85000_TO_89999 = '16',
  N_90000_TO_94999 = '17',
  N_95000_TO_99999 = '18',
  N_100000_TO_124999 = '19',
  N_125000_TO_149999 = '20',
  N_150000_TO_174999 = '21',
  N_175000_TO_199999 = '22',
  N_200000_TO_249999 = '23',
  N_250000_OR_MORE = '24',
  PREFER_NOT_TO_ANSWER = '-3105'
}

export const LucidHHIMapper: Record<LucidHHI, HHIUS> = {
  [LucidHHI.N_LESS_THAN_14999]: HHIUS.N_10000_TO_14999,
  [LucidHHI.N_15000_TO_19999]: HHIUS.N_15000_TO_19999,
  [LucidHHI.N_20000_TO_24999]: HHIUS.N_20000_TO_24999,
  [LucidHHI.N_25000_TO_29999]: HHIUS.N_25000_TO_29999,
  [LucidHHI.N_30000_TO_34999]: HHIUS.N_30000_TO_34999,
  [LucidHHI.N_35000_TO_39999]: HHIUS.N_35000_TO_39999,
  [LucidHHI.N_40000_TO_44999]: HHIUS.N_40000_TO_44999,
  [LucidHHI.N_45000_TO_49999]: HHIUS.N_45000_TO_49999,
  [LucidHHI.N_50000_TO_54999]: HHIUS.N_50000_TO_54999,
  [LucidHHI.N_55000_TO_59999]: HHIUS.N_55000_TO_59999,
  [LucidHHI.N_60000_TO_64999]: HHIUS.N_60000_TO_64999,
  [LucidHHI.N_65000_TO_69999]: HHIUS.N_65000_TO_69999,
  [LucidHHI.N_70000_TO_74999]: HHIUS.N_70000_TO_74999,
  [LucidHHI.N_75000_TO_79999]: HHIUS.N_75000_TO_79999,
  [LucidHHI.N_80000_TO_84999]: HHIUS.N_80000_TO_84999,
  [LucidHHI.N_85000_TO_89999]: HHIUS.N_85000_TO_89999,
  [LucidHHI.N_90000_TO_94999]: HHIUS.N_90000_TO_94999,
  [LucidHHI.N_95000_TO_99999]: HHIUS.N_95000_TO_99999,
  [LucidHHI.N_100000_TO_124999]: HHIUS.N_100000_TO_124999,
  [LucidHHI.N_125000_TO_149999]: HHIUS.N_125000_TO_149999,
  [LucidHHI.N_150000_TO_174999]: HHIUS.N_150000_TO_174999,
  [LucidHHI.N_175000_TO_199999]: HHIUS.N_175000_TO_199999,
  [LucidHHI.N_200000_TO_249999]: HHIUS.N_200000_TO_249999,
  [LucidHHI.N_250000_OR_MORE]: HHIUS.N_250000_OR_MORE,
  [LucidHHI.PREFER_NOT_TO_ANSWER]: HHIUS.PREFER_NOT_TO_ANSWER
}
