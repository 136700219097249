import type { IRule, IRuleSet } from '@feedbackloop/types'
import type { RuleSetAction } from '@feedbackloop/types-graphql'
import { EqualityOperator, LogicalOperator, ReefQuestionType } from '@feedbackloop/types-graphql'
import { areSameTypeValidator, RuleEngineError, ruleValueToCompareValidator } from './rule-engine-validators'
import { isEqual } from 'lodash'
import type { IQuestionAnswerPair } from './rule-engine-converters'
import {
  getIntersection,
  getSubmittedAnswerValueByQuestionType,
  isNullOrEmpty,
  toArrayString,
  toCompareString,
  toNumber,
  valueToCompareProvider
} from './rule-engine-converters'
import type { RRQuestion } from '@feedbackloop/shared'
import { isNil } from 'lodash-es'

export const evaluateRuleSets = (
  question: RRQuestion,
  questionAnswerPairs: IQuestionAnswerPair[],
  actionToCheck: RuleSetAction
): IRuleSet | undefined => {
  if (!question.ruleSets || isEqual(question.ruleSets, [])) return undefined
  const hasRuleSetWithAction = question.ruleSets.some((x: unknown) => {
    if (typeof x !== 'object' || x === null) return false
    if ('action' in x) return x.action !== actionToCheck
  })
  if (hasRuleSetWithAction) return undefined

  if (question.type === ReefQuestionType.Description) return undefined
  return question.ruleSets?.find((ruleSet: IRuleSet) => evaluateRules(ruleSet, questionAnswerPairs))
}

const applyLogicalOperatorLogic = (rule: IRule, accum: boolean, result: boolean): boolean => {
  if (rule.logicalOperator === LogicalOperator.AND) return (accum && result)
  if (rule.logicalOperator === LogicalOperator.OR) return (accum || result)
  return false
}

export const evaluateRules = (
  ruleSet: IRuleSet,
  questionAnswerPairs: IQuestionAnswerPair[]
): boolean => {
  if (!ruleSet?.rules || isEqual(ruleSet.rules, [])) return false

  return ruleSet.rules.reduce((accum: boolean, currRule: IRule, i: number) => {
    if (isNil(currRule.questionIndex)) {
      return applyLogicalOperatorLogic(currRule, accum, false)
    }

    if (!currRule?.valueToCompare) {
      console.error('Missing Value to Compare')
      return applyLogicalOperatorLogic(currRule, accum, false)
    }

    const pair: IQuestionAnswerPair = questionAnswerPairs[currRule.questionIndex]
    const answer = getSubmittedAnswerValueByQuestionType(pair.question.type, pair.answer)
    const ruleResult: boolean = evaluateRule(answer, currRule)

    // if it is the first rule in the set there should NOT be a logical operator
    // so we are going to ignore it!
    if (!currRule?.logicalOperator || i === 0) return ruleResult

    if (
      currRule.logicalOperator === LogicalOperator.AND ||
      currRule.logicalOperator === LogicalOperator.OR
    ) return applyLogicalOperatorLogic(currRule, accum, ruleResult)

    return accum
  }, false)
}

export const evaluateRule = (value: any, rule: IRule): boolean => {
  switch (rule?.equalityOperator) {
    // ALL?
    case EqualityOperator.EQUAL: {
      ruleValueToCompareValidator(rule)
      const ruleValue: any = valueToCompareProvider(rule)
      areSameTypeValidator(value, ruleValue, rule?.equalityOperator)
      // console.log(`${toCompareString(value)} === ${toCompareString(ruleValue)}`)
      return toCompareString(value) === toCompareString(ruleValue)
    }

    case EqualityOperator.NOT_EQUAL: {
      ruleValueToCompareValidator(rule)

      // sc-19400: null/undefined value => user didn't answer comparison question at all
      if (value === null || value === undefined) return false

      const ruleValue: any = valueToCompareProvider(rule)
      areSameTypeValidator(value, ruleValue, rule?.equalityOperator)
      return toCompareString(value) !== toCompareString(ruleValue)
    }

    // NUMBER - assumes you tried to pass a number in some format
    // and will try to parse it to a number if it can, otherwise error
    case EqualityOperator.GREATER_THAN: {
      ruleValueToCompareValidator(rule)
      const ruleValue = valueToCompareProvider(rule)
      return toNumber(value) > toNumber(ruleValue)
    }

    case EqualityOperator.GREATER_THAN_OR_EQUAL: {
      ruleValueToCompareValidator(rule)
      const ruleValue = valueToCompareProvider(rule)
      return toNumber(value) >= toNumber(ruleValue)
    }

    case EqualityOperator.LESS_THAN: {
      ruleValueToCompareValidator(rule)
      const ruleValue = valueToCompareProvider(rule)
      return toNumber(value) < toNumber(ruleValue)
    }

    case EqualityOperator.LESS_THAN_OR_EQUAL: {
      ruleValueToCompareValidator(rule)
      const ruleValue = valueToCompareProvider(rule)
      return toNumber(value) <= toNumber(ruleValue)
    }

    // NULL CHECKS
    case EqualityOperator.IS_NULL: {
      return isNullOrEmpty(value)
    }

    case EqualityOperator.IS_NOT_NULL: {
      return !isNullOrEmpty(value)
    }

    // LIST
    case EqualityOperator.HAS_ANY: {
      ruleValueToCompareValidator(rule)
      let ruleValue: string[] | undefined = valueToCompareProvider(rule)
      ruleValue = toArrayString(ruleValue)
      const valueArray = toArrayString(value)
      return !!getIntersection(valueArray, ruleValue)?.length
    }

    case EqualityOperator.HAS_NONE: {
      ruleValueToCompareValidator(rule)
      let ruleValue: string[] | undefined = valueToCompareProvider(rule)
      ruleValue = toArrayString(ruleValue)

      // sc-19400: null/undefined value => user didn't answer comparison question at all
      if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) return false

      const valueArray = toArrayString(value)
      return !getIntersection(valueArray, ruleValue)?.length
    }

    case EqualityOperator.HAS_ALL: {
      ruleValueToCompareValidator(rule)
      let ruleValue: string[] | undefined = valueToCompareProvider(rule)
      ruleValue = toArrayString(ruleValue)
      const valueArray = toArrayString(value)
      return getIntersection(valueArray, ruleValue)?.length === ruleValue?.length
    }

    case EqualityOperator.HAS_EXACTLY: {
      ruleValueToCompareValidator(rule)
      let ruleValue: string[] | undefined = valueToCompareProvider(rule)
      ruleValue = toArrayString(ruleValue)
      const valueArray = toArrayString(value)
      const intersection = getIntersection(valueArray, ruleValue)
      return intersection?.length === ruleValue?.length && intersection?.length === valueArray?.length
    }

    default:
      throw new RuleEngineError(`Equality operator not found "${rule?.equalityOperator}"`)
  }
}
