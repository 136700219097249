/* istanbul ignore file */
import 'core-js/stable'
import type { IAttributionEvent } from '@feedbackloop/shared'
import { AttributionEventType } from '@feedbackloop/shared'
import type { AttributionUrlStimuliType } from './enums'
import { AttributionDataType } from './enums'
import { VUE_APP_ATTRIBUTION_ORIGIN } from '@/config'

export default class AttributionEvent implements IAttributionEvent {
  respondentId: string = ''
  orderId: string = ''
  eventTime: Date = new Date()
  eventType: AttributionEventType
  tapId: string = ''
  questionId: string = ''
  tagsApplied: string = ''
  audienceId: string = ''
  sessionId: string = ''
  eventContext: string[] = []
  origin: string = VUE_APP_ATTRIBUTION_ORIGIN
  source: string = ''
  keyedMap: Map<AttributionDataType | AttributionUrlStimuliType | string, string> = new Map<AttributionDataType | AttributionUrlStimuliType | string, string>()
  respondentSurvey: string | undefined
  respondentSession: string | undefined
  answer: string | undefined
  respondentSessionClientId: string | undefined
  respondentSurveyClientId: string | undefined
  failureReason: string | undefined
  expectedResponses: number = 0
  expectedSurveys: number = 0

  constructor (public aEvent: AttributionEventType) {
    this.eventType = aEvent
  }

  public map (userData: Map<AttributionDataType | AttributionUrlStimuliType | string, string>) {
    let qType: string | undefined
    this.keyedMap = userData
    for (const key of userData.keys()) {
      let userKey = userData.get(key)
      switch (key) {
        case AttributionDataType.Answer:
          this.answer = JSON.stringify(userKey)
          break
        case AttributionDataType.RespondentSurvey:
          this.respondentSurvey = JSON.stringify(userKey)
          break
        case AttributionDataType.RespondentSession:
          this.respondentSession = JSON.stringify(userKey)
          break
        case AttributionDataType.Marketplace:
          this.source = userKey ?? ''
          break
        case AttributionDataType.RespondentId:
          this.respondentId = userKey ?? ''
          break
        case AttributionDataType.OrderId:
          this.orderId = userKey ?? ''
          break
        case AttributionDataType.Origin:
          this.origin = userKey ?? ''
          break
        case AttributionDataType.SessionId:
          this.sessionId = userKey ?? ''
          break
        case AttributionDataType.TapId:
          this.tapId = userKey ?? ''
          break
        case AttributionDataType.QuestionId:
          this.questionId = userKey ?? ''
          break
        case AttributionDataType.AudienceId:
          this.audienceId = userKey ?? ''
          break
        case AttributionDataType.ExpectedSurveys:
          this.expectedSurveys = parseInt(userKey ?? '0')
          break
        case AttributionDataType.ExpectedResponses:
          this.expectedResponses = parseInt(userKey ?? '0')
          break
        case AttributionDataType.TagsApplied:
          this.tagsApplied = userKey ?? ''
          break
        case AttributionDataType.SessionClientId:
          this.respondentSessionClientId = userKey ?? ''
          break
        case AttributionDataType.SurveyClientId:
          this.respondentSurveyClientId = userKey ?? ''
          break
        case AttributionDataType.FailureReason:
          this.failureReason = userKey ?? ''
          break
        case AttributionDataType.QuestionType:
          qType = userKey
          if (userKey === 'rate') {
            qType = 'rateAndExplain'
          } else if (userKey === 'textarea') {
            qType = 'textResponse'
          }
          this.pushContext({ key: 'question_type', val: qType })
          break
        default:
          if (typeof userKey !== 'undefined' && userKey != null) {
            if (typeof userKey !== 'string') {
              userKey = JSON.stringify(userKey)
            }
            this.pushContext({ key, val: userKey })
          }
          break
      }
    }
  }

  /** # getHash
    * This hash represents how the system determines an event is unique.
    * We won't send the same event repeatedly even if the front end asks us to, with the exception of
    * time-based events like {@link ClickedStimulus}
    * ## Once per session
    * These events can only be called once per session
    * - AttributionEventType.LoadedQueue
    * - AttributionEventType.RespondentSession:
    * - AttributionEventType.Land:
    * - AttributionEventType.LoadedDemographic:
    * - AttributionEventType.NavigatedAway:
    * - AttributionEventType.FailedRedFlag:
    * - AttributionEventType.Redirected:
    * - AttributionEventType.RedirectedToFeedbackFalcon:
    * - AttributionEventType.CompletedSession:
    * - AttributionEventType.FailedInTestHerring:
    * ## Once per tap (survey)
    * - AttributionEventType.StartNewTest:
    * - AttributionEventType.CompletedTest:
    * ..etc
    * **/
  public getHash (): string {
    let finalHash: string = `${this.eventType.valueOf()}`
    switch (this.eventType) {
      case AttributionEventType.AnsweredDemographic:
        finalHash += this.keyedMap.get(AttributionDataType.Demographic)
        break
      case AttributionEventType.QualifiedForAudience:
      case AttributionEventType.FailedToQualifyForAudience:
        finalHash += this.audienceId
        break
      case AttributionEventType.SeenQuestion:
      case AttributionEventType.AnsweredQuestion:
        finalHash += this.questionId
        break
      case AttributionEventType.TagsApplied:
        finalHash += this.tagsApplied
        break
      case AttributionEventType.StartNewTest:
      case AttributionEventType.CompletedTest:
        finalHash += this.tapId
        break
      case AttributionEventType.StimuliLoaded:
        for (const key of this.keyedMap.keys()) {
          if (key.includes('Stimuli')) {
            finalHash += this.keyedMap.get(key)
          }
        }
        break
      case AttributionEventType.ClickedStimulus:
        finalHash += this.eventTime
        break
      case AttributionEventType.LoadedQueue:
      case AttributionEventType.RespondentSession:
      case AttributionEventType.Land:
      case AttributionEventType.LoadedDemographic:
      case AttributionEventType.NavigatedAway:
      case AttributionEventType.FailedRedFlag:
      case AttributionEventType.Redirected:
      case AttributionEventType.RedirectedToFeedbackFalcon:
      case AttributionEventType.CompletedSession:
      case AttributionEventType.FailedInTestHerring:
      default:
    }
    return finalHash
  }

  private pushContext (info: { val: string | undefined, key: string }) {
    this.eventContext.unshift(`${info.key}:${info.val}`)
  }
}
