/* istanbul ignore file */
import AttributionTarget from './AttributionTarget'
import type AttributionEvent from '../core/AttributionEvent'
import { logger } from '@/utilities/LogHandler'
import type { ILog } from '@feedbackloop/types/src/ILog'

export default class AttributionLogTarget extends AttributionTarget implements ILog {
  public inform (aEvent: AttributionEvent): void {
    logger.log(aEvent.getHash(), aEvent)
  }

  assign (): void { }
  log (key: string, ...args: any[]): void { logger.log(key, ...args) }
  error (key: string, ...args: any[]): void { logger.error(key, ...args) }
  warn (message: string, ...args: any[]): void { logger.warn(message, ...args) }
  info (message: string, ...args: any[]): void { logger.info(message, ...args) }
  debug (message: string, ...args: any[]): void { logger.debug(message, ...args) }
}
