import { defineModule } from 'direct-vuex'
import { moduleActionContext } from '..'
import api from '@/api'
import type { BrowserInfo, RRSurvey, QueueType } from '@feedbackloop/shared'
import { parseRespondentSurveyFromRRSurvey, DESKTOP_BREAKPOINT } from '@/utilities'
import type { RespondentSurvey, BalancedSurveyPermutation, SurveyQueueTarget } from '@/types'
import type { PanelProvider, TideQueryParamMap } from '@feedbackloop/demographics'
import { cloneDeep } from 'lodash-es'
import { logger } from '@/utilities/LogHandler'

export interface GetSurveyPayload {
  id: string
  validEncodedDemographics: TideQueryParamMap<string | number>
  sessionId: string
  browserInfo?: BrowserInfo
  queueType: QueueType
  surveyQueueTarget: SurveyQueueTarget
  provider: PanelProvider
}

export interface SurveyState {
  rrSurvey: null | RRSurvey
  respondentSurvey: null | RespondentSurvey
  dedupeIds: string[]
  permutations: Map<string, number> // ie: [ ['61572e27e4d8ca00045b1dfe', 301], ['efd1b54000ac8d4e72e27516', 32] ]
}

const createInitialState = (): SurveyState => ({
  rrSurvey: null,
  respondentSurvey: null,
  dedupeIds: [],
  permutations: new Map<string, number>()
})

const surveyModule = defineModule({
  namespaced: true,
  state: createInitialState,
  mutations: {
    setRRSurvey (state, rrSurvey: RRSurvey) {
      state.rrSurvey = rrSurvey
    },
    setRespondentSurvey (state, respondentSurvey: RespondentSurvey) {
      state.respondentSurvey = respondentSurvey
    },
    setDedupeIds (state, dedupeIds: string[]) {
      state.dedupeIds = dedupeIds
    },
    reset (state) {
      Object.assign(state, createInitialState())
    },
    setPermutation (state, permutations) {
      state.permutations = permutations
    }
  },
  actions: {
    async getSurvey (context, { id, validEncodedDemographics, sessionId, browserInfo, queueType, surveyQueueTarget, provider }: GetSurveyPayload) {
      const { commit, dispatch } = surveyActionContext(context)
      const devMode = moduleActionContext(context, surveyModule).rootState.devMode
      const ffCompatible = navigator.userAgent.includes('Chrome') && window.innerWidth > DESKTOP_BREAKPOINT
      const requestBody: any = {}
      if (devMode) {
        requestBody.development = true
      } else {
        Object.assign(requestBody, validEncodedDemographics, { sessionId })
      }
      if (ffCompatible) {
        requestBody.ff_compatible = true
      }
      if (browserInfo) {
        requestBody.browserInfo = browserInfo
      }
      const rrSurvey = await api.rrSurvey(id, requestBody, queueType, provider, surveyQueueTarget)
      if (rrSurvey.usingKrakenGp) logger.info('Using kraken gp') // Used for logrocket to checkon kraken gp queue migration

      commit.setRRSurvey(rrSurvey)
      const respondentSurvey = parseRespondentSurveyFromRRSurvey(rrSurvey, surveyQueueTarget, provider, id)
      commit.setRespondentSurvey(respondentSurvey)
      if (rrSurvey.dedupe) {
        dispatch.addDedupeIds(rrSurvey.dedupe).catch(logger.error)
      }
    },
    async dedupe (context, { uid, dedupeId, ips }: { uid: string, dedupeId?: string | null, ips: string[] }) {
      const { dispatch } = surveyActionContext(context)
      if (dedupeId) {
        await dispatch.addDedupeIds([dedupeId])
        await api.dedupe(uid, dedupeId, ips)
      }
    },
    addDedupeIds (context, ids: string[]) {
      const { commit, state } = surveyActionContext(context)
      const newDedupeIds = state.dedupeIds.concat(ids)
      commit.setDedupeIds(newDedupeIds)
    },
    reset (context) {
      const { commit } = surveyActionContext(context)
      commit.reset()
    },
    setPermutation (context, permutation: BalancedSurveyPermutation) {
      const { state, commit } = surveyActionContext(context)
      const clonedPermuationSet = cloneDeep(state.permutations)!
      clonedPermuationSet.set(permutation.identifier, permutation.permutation)
      commit.setPermutation(clonedPermuationSet)
    }
  }
})

export default surveyModule
export const surveyActionContext = (context: any) => moduleActionContext(context, surveyModule)
