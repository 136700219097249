import type { AxiosRequestConfig, AxiosInstance } from 'axios'
import axios from 'axios'
import { VUE_APP_CHANNEL_URL, VUE_APP_KRAKEN_URL } from '@/config'
import log from '@/utilities/LogHandler'
const logger = log.logger

export interface ISendRequest {
  devMode: boolean
}
/** This class wraps api calls to qtest */
export default class TideRequester implements ISendRequest {
  devMode: boolean = false
  isAnswerBot: boolean = false
  private validQtestUrls: boolean = VUE_APP_CHANNEL_URL !== 'false'
  private static get transport (): AxiosInstance { return axios.create({}) }

  constructor (devMode: boolean = false, isAnswerBot: boolean = false) {
    this.devMode = devMode
    this.isAnswerBot = isAnswerBot
    logger.log(`Devmode: ${devMode}`)
  }

  shouldSendQtestRequests (overrideDevMode: boolean) {
    if (this.isAnswerBot && !overrideDevMode) return false
    return (this.validQtestUrls && (!this.devMode || (this.devMode && overrideDevMode)))
  }

  async sendKrakenRequest<T = any> (method: AxiosRequestConfig['method'], url: string, requestData?: any, contentType: string = 'application/json', overrideDevMode: boolean = false): Promise<T> {
    const targetUrl = new URL(url, VUE_APP_KRAKEN_URL).href

    const { data } = await TideRequester.sendRequest(method, targetUrl, contentType, requestData, this.shouldSendQtestRequests(overrideDevMode))
    return Promise.resolve(data as T)
  }

  /** content type set to text/plain to avoid the overhead needed for CORS. */
  private static async sendRequest<T = any> (method: AxiosRequestConfig['method'], targetUrl: string, contentType: string, requestData?: any, canSend: boolean = false) {
    if (!canSend) {
      logger.info('DEV MODE: blocked request:', targetUrl, JSON.stringify(requestData, null, 2))
      return {} as T
    }

    try {
      const transportObject: AxiosRequestConfig = {
        url: targetUrl,
        ...(method && { method }),
        data: requestData,
        headers: {
          Accept: 'application/json',
          'Content-Type': `${contentType}`
        },
        withCredentials: true // Send cookies
      }
      return TideRequester.transport(transportObject)
    } catch (err: any) {
      if (err?.response.status === 401 && window.location.pathname !== '/login') {
        logger.error(err)
      }
      throw err
    }
  }
}
