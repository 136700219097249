import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum MobileDevice {
  NO = 'No',
  YES = 'Yes'
}

export const MobileDeviceMetadata: MultiselectDemographicFilterMetadata<MobileDevice> = {
  title: 'Mobile Device',
  question: 'Are you using a mobile or tablet device?',
  options: Object.values(MobileDevice)
}

// lucid question ID is 8214
export enum LucidMobileDevice {
  NO = 'false',
  YES = 'true'
}

export const MobileDeviceMapper: Record<MobileDevice, LucidMobileDevice> = {
  [MobileDevice.YES]: LucidMobileDevice.YES,
  [MobileDevice.NO]: LucidMobileDevice.NO
}
