import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum JobTitle {
  EXECUTIVE = 'C-Level (e.g. CEO, CFO), Owner, Partner, President',
  VICE_PRESIDENT = 'Vice President (EVP, SVP, AVP, VP)',
  DIRECTOR = 'Director (Group Director, Sr. Director, Director)',
  MANAGER = 'Manager (Group Manager, Sr. Manager, Manager, Program Manager)',
  ANALYST = 'Analyst',
  ASSISTANT_ASSOCIATE = 'Assistant or Associate',
  ADMINISTRATIVE = 'Administrative (Clerical or Support Staff)',
  CONSULTANT = 'Consultant',
  INTERN = 'Intern',
  VOLUNTEER = 'Volunteer',
  NONE_OF_THE_ABOVE = 'None of the above'
}

export const JobTitleMetadata: MultiselectDemographicFilterMetadata<JobTitle> = {
  title: 'Job Title',
  question: 'What is your job title, level or responsibility?',
  options: Object.values(JobTitle)
}

// lucid question ID is 15297
export enum LucidJobTitle {
  EXECUTIVE = '1',
  VICE_PRESIDENT = '2',
  DIRECTOR = '3',
  MANAGER = '4',
  ANALYST = '5',
  ASSISTANT_ASSOCIATE = '6',
  ADMINISTRATIVE = '7',
  CONSULTANT = '8',
  INTERN = '9',
  VOLUNTEER = '10',
  NONE_OF_THE_ABOVE = '11'
}

export const JobTitleMapper: Record<JobTitle, LucidJobTitle> = {
  [JobTitle.EXECUTIVE]: LucidJobTitle.EXECUTIVE,
  [JobTitle.VICE_PRESIDENT]: LucidJobTitle.VICE_PRESIDENT,
  [JobTitle.DIRECTOR]: LucidJobTitle.DIRECTOR,
  [JobTitle.MANAGER]: LucidJobTitle.MANAGER,
  [JobTitle.ANALYST]: LucidJobTitle.ANALYST,
  [JobTitle.ASSISTANT_ASSOCIATE]: LucidJobTitle.ASSISTANT_ASSOCIATE,
  [JobTitle.ADMINISTRATIVE]: LucidJobTitle.ADMINISTRATIVE,
  [JobTitle.CONSULTANT]: LucidJobTitle.CONSULTANT,
  [JobTitle.INTERN]: LucidJobTitle.INTERN,
  [JobTitle.VOLUNTEER]: LucidJobTitle.VOLUNTEER,
  [JobTitle.NONE_OF_THE_ABOVE]: LucidJobTitle.NONE_OF_THE_ABOVE
}
