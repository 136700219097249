import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum ParentalStatus {
  PREGNANT = 'I am pregnant/expecting a child within the next 9 months',
  HAVE_CHILDREN_UNDER_18 = 'I have one or more children under the age of 18 living in my household',
  HAVE_CHILDREN_OVER_18 = 'I have one or more children aged 18 or older living in my household',
  NO_CHILDREN_IN_HOUSEHOLD = 'I have no children living in my household and I am not pregnant/expecting a child within the next 9 months'
}

export const ParentalStatusMetadata: MultiselectDemographicFilterMetadata<ParentalStatus> = {
  title: 'Parental Status',
  question: 'Please choose the options that best describe your household:',
  options: Object.values(ParentalStatus)
}

// lucid question ID is 7064
export enum LucidParentalStatus {
  PREGNANT = '1',
  HAVE_CHILDREN_UNDER_18 = '2',
  HAVE_CHILDREN_OVER_18 = '3',
  NO_CHILDREN_IN_HOUSEHOLD = '4'
}

export const ParentalStatusMapper: Record<ParentalStatus, LucidParentalStatus> = {
  [ParentalStatus.PREGNANT]: LucidParentalStatus.PREGNANT,
  [ParentalStatus.HAVE_CHILDREN_UNDER_18]: LucidParentalStatus.HAVE_CHILDREN_UNDER_18,
  [ParentalStatus.HAVE_CHILDREN_OVER_18]: LucidParentalStatus.HAVE_CHILDREN_OVER_18,
  [ParentalStatus.NO_CHILDREN_IN_HOUSEHOLD]: LucidParentalStatus.NO_CHILDREN_IN_HOUSEHOLD
}
