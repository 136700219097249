import Vue from 'vue'
import type { RouteConfig } from 'vue-router'
import VueRouter from 'vue-router'
import { SurveyQueueTarget } from '../types'

Vue.use(VueRouter)

const surveyQueueTarget = SurveyQueueTarget

const routes: Array<RouteConfig> = [
  {
    path: '/audiences/:id',
    name: 'Audience',
    component: () => import(/* webpackChunkName: "survey" */ '../views/SurveyView.vue'),
    props: route => ({ id: route.params.id, surveyQueueTarget: surveyQueueTarget.Audience })
  },
  {
    path: '/surveys/:id',
    name: 'Survey',
    component: () => import(/* webpackChunkName: "survey" */ '../views/SurveyView.vue'),
    props: route => ({ id: route.params.id, surveyQueueTarget: surveyQueueTarget.IndividualSurvey })
  },
  {
    path: '/byoa/survey/:id',
    name: 'ByoaSurveyView',
    component: () => import(/* webpackChunkName: "survey" */ '../views/SurveyView.vue'),
    props: route => ({ id: route.params.id, surveyQueueTarget: surveyQueueTarget.IndividualSurvey })
  },
  {
    path: '/am/survey/:id',
    name: 'ActiveMeasureSurveyView',
    component: () => import(/* webpackChunkName: "survey" */ '../views/SurveyView.vue'),
    props: route => ({ id: route.params.id, surveyQueueTarget: surveyQueueTarget.IndividualSurvey })
  },
  {
    path: '/preview',
    name: 'Preview',
    component: () => import(/* webpackChunkName: "survey" */ '../views/SurveyPreview.vue')
  },
  {
    path: '/',
    name: 'Landing',
    component: () => import(/* webpackChunkName: "landing" */ '../views/LandingView.vue')
  }, {
    path: '/terms-of-service',
    name: 'Terms Of Service',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsOfService.vue')
  }, {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPolicy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
