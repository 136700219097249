import type { RRQuestion } from '@feedbackloop/shared'
import type { SelectedAnswer } from '@/types/SelectedAnswer'

export interface ClientSurvey {
  id: string
  type: string
  questions: RRQuestion[]
  subQuotaMet?: boolean
  dedupeId?: string | null | undefined
}

export interface NumberScaleProperties {
  minValue?: number
  maxValue?: number
  naOption: boolean
}

export interface RateAnswer extends SelectedAnswer {
  answer: string | null
}

export interface RankAnswer extends SelectedAnswer {
  answer: number | null
  explainAnswer: string | null
}

export enum ButtonState {
  NORMAL = 'normal',
  DISABLED = 'disabled'
}

export enum ScaleLabelNames {
  FIVE = 'five-point-scale',
  SEVEN = 'seven-point-scale',
  NPS = 'nps-scale'
}

export interface BalancedSurveyPermutation {
  identifier: string
  permutation: number
}
