import Vue from 'vue'
import Vuex from 'vuex'
import { createDirectStore } from 'direct-vuex'
import type { SurveyState } from './modules/survey-store-module'
import survey from './modules/survey-store-module'
import type { SessionState } from './modules/session-store-module'
import session from './modules/session-store-module'
import { getQueryParams } from '@feedbackloop/shared'

export interface RootState {
  errorCode: string | null
  devMode: boolean
  youTubeIframeApiReady: boolean
  survey: SurveyState
  session: SessionState
}

Vue.use(Vuex)

const state: RootState = {
  errorCode: null,
  devMode: getQueryParams(window.location.search.toLowerCase()).hasOwnProperty('devmode'),
  youTubeIframeApiReady: false
} as RootState // You have to cast it here else it complains about not having a user property initialization... however vuex composes the submodules itself so you can't actually provide a def

const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
} = createDirectStore({
  state,
  modules: {
    survey,
    session
  },
  mutations: {
    setErrorCode (state, errorCode: string) {
      state.errorCode = errorCode
    },
    setYouTubeApiIframeReady (state, readyState: boolean) {
      state.youTubeIframeApiReady = readyState
    }
  },
  actions: {
    setErrorCode (context, errorCode: string) {
      const { commit } = rootActionContext(context)
      commit.setErrorCode(errorCode)
    },
    async reset (context) {
      const { dispatch } = rootActionContext(context)
      await dispatch.survey.reset()
      await dispatch.session.reset()
    }
  }
})

// Export the direct-store instead of the classic Vuex store.
export default store

// The following exports will be used to enable types in the
// implementation of actions.
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext }

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    direct: AppStore
  }
}
