import type AttributionEvent from '../core/AttributionEvent'
import type { AttributionDataType, AttributionUrlStimuliType } from '../core/enums'
import type { IAttribute } from '../Attributor'

export interface IAttributionTarget {
  inform(aEvent: AttributionEvent): void

  assign(key: AttributionDataType | AttributionUrlStimuliType | string, value: any): void
}

export default abstract class AttributionTarget implements IAttributionTarget {
  protected attributorInstance: IAttribute | undefined

  abstract assign(key: AttributionDataType | AttributionUrlStimuliType | string, value: any): void

  abstract inform(aEvent: AttributionEvent): void
}
