import AttributionTarget from './AttributionTarget'
import type AttributionEvent from '../core/AttributionEvent'
import type MessageBuffer from '@/utilities/MessageBuffer'
import type { IAttributionEvent } from '@feedbackloop/shared'

export default class AttributionBufferTarget extends AttributionTarget {
  buffer: MessageBuffer<IAttributionEvent>

  constructor (buffer: MessageBuffer<IAttributionEvent>) {
    super()
    this.buffer = buffer
  }

  public inform (aEvent: AttributionEvent): void {
    this.buffer.add(aEvent)
  }

  assign (): void {
  }
}
