import { defineModule } from 'direct-vuex'
import { moduleActionContext } from '..'
import {
  RedFlag,
  isSpam,
  cleanText,
  questionTypeLOI,
  getFreeResponseFromSubmittedAnswer
} from '@/utilities/redFlags'
import { uniq, intersection } from 'lodash-es'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import type { BrowserInfo } from '@/types/RespondentSession'
import type { TideQueryParamMap } from '@feedbackloop/demographics/src/tide/demographics'
import type { PanelProvider } from '@feedbackloop/demographics/src/tide/toSessionStore'
import { decodeDemographicsForChannel } from '@/utilities/demographicsHelpers'
import { batchEncodeDemographicMap } from '@feedbackloop/demographics/src/tide/demographicsHelpers'

import type { SubmittedAnswer, TideRespondentSession, TideQuestion } from '@feedbackloop/shared'
import type { SamplechainThreatPotential } from '@feedbackloop/types/src/RespondentSession'
import { MOBILE_BREAKPOINT } from '@/utilities'
import type { QuestionType } from '@feedbackloop/types'
import api from '@/api'

export interface SessionState {
  respondentSessionClientId: string
  redFlags: RedFlag[]
  browserInfo: BrowserInfo | null
  decodedDemographics: TideQueryParamMap<string> | null // demographics are decoded afaict
  samplechainThreatPotential?: SamplechainThreatPotential | null
  previousTextResponses: Record<string, number>
  respondentIPs: string[] | null
  estimatedLOI: number
  startTime: Date | null
  source: PanelProvider | null
  url: string
  sentToChannel: boolean
  audienceId: string | null
  createdAt: Date
  isMobile: boolean | null
}

const createInitialState = (): SessionState => ({
  respondentSessionClientId: uuidv4(), // this would create a new Session ID when the user reloads too I think? Double check if this is intended functionality
  redFlags: [],
  previousTextResponses: {},
  respondentIPs: null,
  estimatedLOI: 0,
  startTime: null,
  browserInfo: null,
  decodedDemographics: null,
  url: window.location.toString(),
  sentToChannel: false,
  samplechainThreatPotential: null,
  audienceId: null,
  source: null,
  createdAt: new Date(),
  isMobile: null
})

export interface RedFlagArgs {
  audienceId: string
  source: PanelProvider
  tagsSet: string[]
  RID: string
  OID: string
}

export interface RedFlagWithQuestionIDArgs extends RedFlagArgs {
  questionId: string
}

const session = defineModule({
  namespaced: true,
  state: createInitialState,
  mutations: {
    pushRedFlag (state, redFlag: RedFlag) {
      const redFlags = state.redFlags
      redFlags.push(redFlag)
      state.redFlags = uniq(redFlags)
    },
    incrementTextResponse (state, textAnswer: string) {
      // Do some light cleaning
      const cleanedAnswer = cleanText(textAnswer)

      // Init to 0
      if (!state.previousTextResponses[cleanedAnswer]) {
        Vue.set(state.previousTextResponses, cleanedAnswer, 0)
      }

      // Inc
      state.previousTextResponses[cleanedAnswer]++
    },
    setRespondentIPs (state, ips: string[]) {
      state.respondentIPs = ips
    },
    addQuestionLOI (state, questionLOI: number) {
      state.estimatedLOI += questionLOI
    },
    setStartTime (state, startTime: Date) {
      state.startTime = startTime
    },
    saveBrowserInfo (state, browserInfo: BrowserInfo) {
      state.browserInfo = browserInfo
    },
    // make sure these are decoded demographics used in channel, not the precodes used in QTest
    saveDemographics (state, demographics: TideQueryParamMap<string>) {
      state.decodedDemographics = Object.assign({}, state.decodedDemographics, demographics)
    },
    setAudienceId (state, audienceId: string) {
      state.audienceId = audienceId
    },
    setCreatedAt (state, createdAt: Date) {
      state.createdAt = createdAt
    },
    sentToChannel (state) {
      state.sentToChannel = true
    },
    setSampleChainThreat (state, score: SamplechainThreatPotential) {
      state.samplechainThreatPotential = score
    },
    reset (state) {
      Object.assign(state, createInitialState())
    },
    setSource (state, source: PanelProvider) {
      state.source = source
    },
    setIsMobile (state, isMobile: boolean) {
      state.isMobile = isMobile
    }
  },
  getters: {
    encodedDemographics (state: SessionState) {
      return state.decodedDemographics ? batchEncodeDemographicMap(state.decodedDemographics) : {}
    },
    sessionPayload (state: SessionState) {
      if (!state.browserInfo || !state.samplechainThreatPotential || !state.decodedDemographics) {
        return
      }
      const session: TideRespondentSession = { // add in other session parts of payload
        clientId: state.respondentSessionClientId!,
        url: state.url,
        samplechainThreatPotential: state.samplechainThreatPotential,
        clientIpAddress: state.browserInfo.clientIpAddress[0],
        clientBrowserIsModern: state.browserInfo.clientBrowserIsModern,
        clientBrowserName: state.browserInfo.clientBrowserName,
        clientBrowserVersion: state.browserInfo.clientBrowserVersion,
        clientOS: state.browserInfo.clientOS,
        clientOSFullname: state.browserInfo.clientOSFullname,
        clientOSVersion: state.browserInfo.clientOSVersion,
        clientBotName: `${state.browserInfo.clientBotName}` || '',
        clientIsBot: state.browserInfo.clientIsBot,
        audienceId: state.audienceId,
        createdAt: state.createdAt,
        vendorDemographics: state.decodedDemographics,
        // Following haven't been added to Channel yet
        // clientAndroidApp: state.browserInfo.clientAndroidApp,
        // clientIOS: state.browserInfo.clientIOS,
        // clientIOSApp: state.browserInfo.clientIOSApp,
        // clientIOSWebView: state.browserInfo.clientIOSWebView,
        ...decodeDemographicsForChannel(state.decodedDemographics)
      }
      return session
    },
    isValidSession (state: SessionState, getters): boolean {
      // typescript only checks types on compile, not during run time
      // This is an extra check to ensure that we never send up a session unless it has all of the required fields filled in
      // OID, PID, RID, and provider are always required, and always sent up on every automated lucid/cint order
      const requiredOrderConfigs = [
        'OID',
        'PID',
        'RID',
        'provider'
      ]

      if (!getters.encodedDemographics) return false
      return requiredOrderConfigs.length === intersection(requiredOrderConfigs, Object.keys(getters.encodedDemographics)).length
    }
  },
  actions: {
    checkForDuplicate (context, { answer, question }: { answer: SubmittedAnswer, question: TideQuestion }) {
      const { state, commit } = sessionActionContext(context)
      const freeResponse = getFreeResponseFromSubmittedAnswer(answer, question)
      if (!freeResponse) return

      // Add answer to text response map
      const cleanedResponse = cleanText(freeResponse)
      commit.incrementTextResponse(cleanedResponse)

      // Get count response count (dupes)
      const cleanedAnswerCount = state.previousTextResponses[cleanedResponse]

      // Check if there are MAX or more dupes, if so register it
      const MAX_DUPE_COUNT = 3
      if (cleanedAnswerCount >= MAX_DUPE_COUNT) {
        commit.pushRedFlag(RedFlag.Spam)
      }
    },
    checkForSpam (context, { answer, question }: { answer: SubmittedAnswer, question: TideQuestion }) {
      const { commit } = sessionActionContext(context)
      const freeResponse = getFreeResponseFromSubmittedAnswer(answer, question)
      if (!freeResponse) return

      if (isSpam(freeResponse)) {
        commit.pushRedFlag(RedFlag.Spam)
      }
    },
    async checkForPlagiarism (context, { answer, question }: { answer: SubmittedAnswer, question: TideQuestion }) {
      const { commit } = sessionActionContext(context)
      const freeResponse = getFreeResponseFromSubmittedAnswer(answer, question)
      if (!freeResponse) return

      if (freeResponse.split(' ').length >= 30) {
        // remove try/catch when kraken /plagiarism-check failure root cause is fixed
        try {
          const res = await api.plagcheck(freeResponse)
          if (res.plagiarized === true) {
            commit.pushRedFlag(RedFlag.Plagiarized)
          }
        } catch (e) {
          console.error('plagiarism check failed', e)
          console.error('payload', { answer: freeResponse })
        }
      }
    },
    addQuestionLOI (context, { question }: { question: TideQuestion }) {
      const { commit } = sessionActionContext(context)
      const questionLOI = questionTypeLOI(question.type as QuestionType)
      commit.addQuestionLOI(questionLOI)
    },
    startTimer (context) {
      const { commit } = sessionActionContext(context)
      commit.setStartTime(new Date())
    },
    checkSpeeder (context) {
      const { state, commit } = sessionActionContext(context)
      if (!state.startTime) {
        console.error('Missing startTime for session')
        return
      }
      const endTime = new Date()
      const sessionDurationMS = endTime.getTime() - state.startTime.getTime()
      const sessionDurationSec = Math.ceil(sessionDurationMS / 1000) // Remember Date.getTime() is in miliseconds
      if (sessionDurationSec < state.estimatedLOI) {
        commit.pushRedFlag(RedFlag.Speeder)
      }
    },
    pushRedFlag (context, redFlag: RedFlag) {
      const { commit } = sessionActionContext(context)
      commit.pushRedFlag(redFlag)
    },
    addUpFrontRedFlagDataToSession (context, { ips, sampleChainScore }: { ips: string[], sampleChainScore: SamplechainThreatPotential }) {
      const { commit } = sessionActionContext(context)
      commit.setRespondentIPs(ips)
      commit.setSampleChainThreat(sampleChainScore)
    },
    updateBrowserInfo (context, browserInfo: BrowserInfo) {
      const { commit } = sessionActionContext(context)
      commit.saveBrowserInfo(browserInfo)
    },
    updateDemographics (context, demographics: TideQueryParamMap<string>) {
      const { commit } = sessionActionContext(context)
      commit.saveDemographics(demographics)
    },
    markSessionAsSentToChannel (context, audienceId: string) {
      const { commit, state, getters } = sessionActionContext(context)
      // Dev/Preview Mode needs some exceptions here
      if (state.sentToChannel || !getters.sessionPayload) return
      commit.setAudienceId(audienceId)
      commit.setCreatedAt(new Date())
      commit.sentToChannel()
    },
    reset (context) {
      const { commit } = sessionActionContext(context)
      commit.reset()
    },
    saveSource (context, source: PanelProvider) {
      const { commit } = sessionActionContext(context)
      commit.setSource(source)
    },
    initializeIsMobileChecking (context) {
      const { commit } = sessionActionContext(context)
      const getIsMobileValue = () => window.innerWidth < MOBILE_BREAKPOINT
      const setIsMobileValue = () => commit.setIsMobile(getIsMobileValue())
      setIsMobileValue()
      window.addEventListener('resize', setIsMobileValue)
    }
  }
})

export default session
export const sessionActionContext = (context: any) => moduleActionContext(context, session)
