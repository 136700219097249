import { pubSubGenerator } from '@feedbackloop/shared'

export enum AnimationEventType {
  WELCOME = 'welcome',
  SURVEY_OUTRO = 'survey_outro',
  SCREENER_INTRO = 'screener_intro',
  SURVEY_INTRO = 'survey_intro',
  DEMO_INTRO = 'demo_intro',
  QUALIFICATION = 'qualification',
  DISQUALIFICATION = 'disqualification',
  SURVEY_UNAVAILABLE = 'survey_unavailable'
}

export interface AnimationEvent {
  type: AnimationEventType
  direction: 'mount' | 'unmount' | 'hidden'
}

export const AnimationEventPubSub = pubSubGenerator<AnimationEvent>()
