import {
  DecodedDemographicsAndContext,
  DemographicQuestion,
  LucidDemographicFilter,
  TideQueryFieldName,
  TideQueryFieldNames,
  TideQueryParamMap,
  demographicToQuestionType,
  lucidToFblDemographicFilter
} from './demographics'
import { Education, LucidEducationV2, LucidEducationV2Mapper } from '../filters/education'
import { HHIUS, LucidHHI, LucidHHIMapper } from '../filters/hhius'
import {
  ILog,
  RespondentEthnicity,
  RespondentGender,
  RespondentHispanic,
  RespondentStandardEducation,
  RespondentStandardEmployment,
  RespondentStandardHHIUS,
  RespondentStandardIndustryPersonal,
  RespondentStandardNumberOfEmployees,
  RespondentState
} from '@feedbackloop/types'
import { ReefQuestionType } from '@feedbackloop/types-graphql'
import { SessionStoreDemographicV0, decodePanelProvider } from './toSessionStore'
import { intersection, map } from 'lodash'
import { CompanyDepartment } from '../filters/companyDepartment'
import { DemographicFilter } from '../demographicFilter'
import { DemographicFilterValues } from '../filterValues'
import { EmployeeCount } from '../filters/employeeCount'
import { Employment } from '../filters/employment'
import { Ethnicity } from '../filters/ethnicity'
import { Gender } from '../filters/gender'
import { Hispanic } from '../filters/hispanic'
import { HouseholdType } from '../filters/householdType'
import { Industry } from '../filters/industry'
import { JobTitle } from '../filters/jobTitle'
import { LoanType } from '../filters/loanType'
import { InvestableAssets } from '../filters/investableAssets'
import { MobileDevice } from '../filters/mobileDevice'
import { Region } from '../filters/region'
import { State } from '../filters/state'
import { demographicQuestions } from './demographicQuestions'
import { InsuranceType } from '../filters/insuranceType'

const demosThatNeedLabels: DemographicFilter[] = [
  DemographicFilter.AGE,
  DemographicFilter.BIG_TICKET_PURCHASES,
  DemographicFilter.ETHNICITY,
  DemographicFilter.GENDER,
  DemographicFilter.HISPANICS,
  DemographicFilter.MOBILE_DEVICE,
  DemographicFilter.PARENTAL_STATUS,
  DemographicFilter.B2B_DECISION_MAKER,
  DemographicFilter.HHIUS,
  DemographicFilter.STATE,
  DemographicFilter.EMPLOYMENT,
  DemographicFilter.EDUCATION,
  DemographicFilter.INDUSTRY,
  DemographicFilter.JOB_TITLE,
  DemographicFilter.RELATIONSHIP_STATUS,
  DemographicFilter.HOUSEHOLD_TYPE,
  DemographicFilter.COMPANY_DEPARTMENT,
  DemographicFilter.EMPLOYEE_COUNT
]

const demographicsChannelRequires = [
  DemographicFilter.GENDER,
  DemographicFilter.EMPLOYMENT,
  DemographicFilter.EMPLOYEE_COUNT,
  DemographicFilter.HHIUS,
  DemographicFilter.INDUSTRY,
  DemographicFilter.EDUCATION,
  DemographicFilter.STATE,
  DemographicFilter.ETHNICITY,
  DemographicFilter.HISPANICS,
  DemographicFilter.AGE,
  DemographicFilter.MOBILE_DEVICE
] as const

type MultiSelectFilter =
    typeof TideQueryFieldNames.PARENTAL_STATUS_STANDARD |
    typeof TideQueryFieldNames.STANDARD_B2B_DECISION_MAKER |
    typeof TideQueryFieldNames.BIG_TICKET_PURCHASES
type SourcingQueryFields =
    typeof TideQueryFieldNames.PID |
    typeof TideQueryFieldNames.RID |
    typeof TideQueryFieldNames.OID |
    typeof TideQueryFieldNames.SUPPLIER_ID |
    typeof TideQueryFieldNames.SUPPLIER_NAME

export function isEnum<T> (enumObj: {[key: string]: T}, keyToMatch: any): boolean {
  return Object.values(enumObj).some(key => key === keyToMatch)
}

// This is the bare minimum for demographic validation (it's what Channel uses to determine INVALID_VENDOR_DEMOGRAPHICS). It would be nice, though, if we supported validating all demographics possible.
const demoEnums : Record<string, {[key: string]: string}> = {
  [DemographicFilter.GENDER]: RespondentGender,
  [DemographicFilter.EMPLOYMENT]: RespondentStandardEmployment,
  [DemographicFilter.EMPLOYEE_COUNT]: RespondentStandardNumberOfEmployees,
  [DemographicFilter.HHIUS]: RespondentStandardHHIUS,
  [DemographicFilter.INDUSTRY]: RespondentStandardIndustryPersonal,
  [DemographicFilter.EDUCATION]: RespondentStandardEducation,
  [DemographicFilter.STATE]: RespondentState,
  [DemographicFilter.ETHNICITY]: RespondentEthnicity,
  [DemographicFilter.HISPANICS]: RespondentHispanic
}
/**
 * WHAT: Given the current demographics we are sourcing for and the participant's validated panel-provided demographics, returns an array of demographic questions that we need to ask (just their names)
 * @param activePrequals Object whose keys are DemographicNames and values are arrays of encoded values we are actively sourcing
 * @param passedInValidatedDemographics Object whose keys are DemographicNames and values are the participant's panel-provided value (string)
 */
const OnlyUSDemographics = new Set<DemographicFilter>([DemographicFilter.STATE, DemographicFilter.ETHNICITY, DemographicFilter.HISPANICS])
export function getDemographicsRequiringParticipantAnswer (activePrequals: Partial<Record<TideQueryFieldName, string[]>>, passedInValidatedDemographics: TideQueryParamMap<string>): DemographicQuestion[] {
  const activePrequalKeys:DemographicFilter[] = Object.values(DemographicFilter).filter(a => {
    return activePrequals[a]
  })

  const validActivePrequals = intersection(activePrequalKeys, demosThatNeedLabels)
  const demosThatCouldNeedUserInput:DemographicFilter[] = Array.from(new Set([...validActivePrequals, ...demographicsChannelRequires]))

  const demosThatDoNeedUserInput = demosThatCouldNeedUserInput.filter(demo => {
    const panelProvidedValue = passedInValidatedDemographics[demo]
    if (panelProvidedValue !== undefined) { return demo === 'AGE' && isNaN(parseInt(panelProvidedValue)) }
    if (!OnlyUSDemographics.has(demo)) { return true }
    return passedInValidatedDemographics.INTERNATIONAL !== 'true'
  })
  return demosThatDoNeedUserInput.map(demo => demographicQuestions[demo]!)
}

/**
 * WHAT: Human readable value -> Lucid demographic code
 * @param queryFieldName
 * @param text
 */
export const NONE_OF_THE_ABOVE = 'None of the above'
export const encodeDemographic = (queryFieldName: TideQueryFieldName, text: string) => {
  const demographic: DemographicFilter | undefined = lucidToFblDemographicFilter[queryFieldName as LucidDemographicFilter]
  if (!demographic) { return text }
  const demographicQuestion: DemographicQuestion = demographicQuestions[demographic]

  const questionType = demographicToQuestionType[demographic]
  // decoded string "Answer 1||Answer 4||Answer 7" from session store --> encoded string "1001001"
  if (questionType === ReefQuestionType.MultiSelect) {
    // TODO: revisit how none of the above works here
    if (text.includes(NONE_OF_THE_ABOVE)) return demographicQuestion.answers!.map(() => 0).join('')
    const decodedAnswers = text.split('||')
    return demographicQuestion.answers!.map((answer: any) => decodedAnswers.includes(answer.text) ? 1 : 0).join('')
  }
  if (questionType === ReefQuestionType.SingleSelect) {
    return demographicQuestion.answers!.find((answer: any) => answer.text === text)?.value || text
  }

  return text
}

export const batchEncodeDemographicMap = (demographicsMap: TideQueryParamMap<string>) => {
  const encodedMap: TideQueryParamMap<string> = {}
  for (const castKey of Object.values(TideQueryFieldNames)) {
    const decodedValue = demographicsMap[castKey]!
    if (decodedValue) encodedMap[castKey] = encodeDemographic(castKey, decodedValue)
  }
  return encodedMap
}

function getByValue (demographicQuestion: DemographicQuestion, value?: string, defaultValue?: DemographicFilterValues): string | undefined {
  return demographicQuestion.answers?.find(answer => answer.value === value)?.text || value || defaultValue
}

export function decodeDemographic (queryField: typeof TideQueryFieldNames.ETHNICITY, value?: string): Ethnicity
export function decodeDemographic (queryField: typeof TideQueryFieldNames.GENDER, value?: string): Gender
export function decodeDemographic (queryField: typeof TideQueryFieldNames.HISPANIC, value?: string): Hispanic
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_HHI, value?: string): HHIUS
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_HHI_US, value?: string): HHIUS
export function decodeDemographic (queryField: typeof TideQueryFieldNames.REGION, value?: string): Region
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STATE, value?: string): State
export function decodeDemographic (queryField: typeof TideQueryFieldNames.INSURANCE_TYPE, value?: string): InsuranceType
export function decodeDemographic (queryField: typeof TideQueryFieldNames.INVESTABLE_ASSETS, value?: string): InvestableAssets
export function decodeDemographic (queryField: typeof TideQueryFieldNames.LOAN_TYPE, value?: string): LoanType
export function decodeDemographic (queryField: typeof TideQueryFieldNames.MS_IS_MOBILE, value?: string): MobileDevice
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_EMPLOYMENT, value?: string): Employment
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_EDUCATION, value?: string): Education
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_EDUCATION_V2, value?: string): Education
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_INDUSTRY_PERSONAL, value?: string): Industry
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_JOB_TITLE, value?: string): JobTitle
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_HOUSEHOLD_TYPE, value?: string): HouseholdType
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_COMPANY_DEPARTMENT, value?: string): CompanyDepartment
export function decodeDemographic (queryField: typeof TideQueryFieldNames.STANDARD_NO_OF_EMPLOYEES, value?: string): EmployeeCount
export function decodeDemographic (queryField: MultiSelectFilter, value: string): string
export function decodeDemographic (queryField: SourcingQueryFields, value?: string): string
export function decodeDemographic (queryField: TideQueryFieldName, value?: string): string | undefined
export function decodeDemographic (queryField: TideQueryFieldName, value?: string): string | undefined {
  if (!value) return undefined
  switch (queryField) {
    case TideQueryFieldNames.RID:
    case TideQueryFieldNames.PID:
    case TideQueryFieldNames.OID:
    case TideQueryFieldNames.SUPPLIER_ID:
    case TideQueryFieldNames.SUPPLIER_NAME:
    case TideQueryFieldNames.COUNTRY:
    case TideQueryFieldNames.INTERNATIONAL:
    case TideQueryFieldNames.QUANTITY:
    case TideQueryFieldNames.CPI:
    case TideQueryFieldNames.SRC:
    case TideQueryFieldNames.QUICKSOURCE:
    case TideQueryFieldNames.FULCRUM_UID:
      return value
    case TideQueryFieldNames.PROVIDER:
      return decodePanelProvider(value?.toString() || '')
    case TideQueryFieldNames.STANDARD_HHI:
      return LucidHHIMapper[value as LucidHHI] || value
    case TideQueryFieldNames.STANDARD_EDUCATION_V2:
      return LucidEducationV2Mapper[value as LucidEducationV2] || value
  }
  const demographicFilter: DemographicFilter | undefined = lucidToFblDemographicFilter[queryField as LucidDemographicFilter]
  if (!demographicFilter) { return undefined }
  const demographicQuestion: DemographicQuestion = demographicQuestions[demographicFilter]
  switch (demographicFilter) {
    case DemographicFilter.PARENTAL_STATUS:
    case DemographicFilter.B2B_DECISION_MAKER:
    case DemographicFilter.BIG_TICKET_PURCHASES: {
      // multi selects
      const multiSelectIndexes = value.split('').map((x, idx) => parseInt(x) === 1 ? String(idx + 1) : null)
      // TODO stop passing the the answers as a string with join ||, just use arrays
      return demographicQuestion.answers!
        .filter((answer) => multiSelectIndexes.includes(answer.value))
        .map((answer) => answer.text)
        .join('||')
    }
    case DemographicFilter.HHIUS: {
      return getByValue(demographicQuestion, value, HHIUS.PREFER_NOT_TO_ANSWER)
    }
    case DemographicFilter.ZIP_CODE:
      return value
    default:
      return getByValue(demographicQuestion, value)
  }
}

export function decodeDemographicsForSessionStore (validEncodedDemographics: TideQueryParamMap<string>, decodedDemographics: TideQueryParamMap<DecodedDemographicsAndContext>) : SessionStoreDemographicV0 {
  const gatheredDecodedDemographics: TideQueryParamMap<string> = {}

  for (const key of Object.values(TideQueryFieldNames)) {
    const value = validEncodedDemographics[key]
    if (value === undefined) continue
    gatheredDecodedDemographics[key] = decodeDemographic(key, String(value))
  }

  let hasUnhandledDecodes = false
  // take only the participantSelection, and gather the human readable versions of these responses
  for (const key of Object.values(TideQueryFieldNames)) {
    const demographicsWithContext = decodedDemographics[key]
    if (!demographicsWithContext) continue
    const { demographicContext, participantSelection } = demographicsWithContext

    if (demographicToQuestionType[demographicContext.id] === ReefQuestionType.MultiSelect && Array.isArray(participantSelection?.value)) {
      gatheredDecodedDemographics[demographicContext.id] = participantSelection.value.join('||')
    } else if (['string', 'number'].includes(typeof participantSelection?.value)) {
      gatheredDecodedDemographics[demographicContext.id] = participantSelection.value
    } else { // there will be an array case for the future
      hasUnhandledDecodes = true
    }
  }

  return {
    result: gatheredDecodedDemographics,
    hasUnhandledDecodes
  }
}

/**
 * WHY: Channel expects certain human-readable demographic values and throws out users without those values. To eliminate those wasted respondents, we validate passed in demographics so that we can re-ask any demographics with invalid values.
 * @param type One of the 7 demographics channel validates for
 * @param value
 * @param valueIsDecoded
 */
export function isValidDemographic (type: TideQueryFieldName, value: any, valueIsDecoded: boolean, validator = isEnum): boolean {
  if (value === '') return false
  const mappedEnum = demoEnums[type]
  if (!mappedEnum) return true
  const decodedValue = valueIsDecoded ? value : decodeDemographic(type, value)
  return validator(mappedEnum, decodedValue)
}

export function removeInvalidEncodedDemographics (demographicsMap: TideQueryParamMap<string>, logger: ILog, showRemoved = false) {
  logger.log('removing invalids', demographicsMap.SUPPLIER_NAME)
  const filteredMap: TideQueryParamMap<string> = {}
  for (const castKey of Object.values(TideQueryFieldNames)) {
    const value = demographicsMap[castKey]
    if (isValidDemographic(castKey, value, false)) filteredMap[castKey] = value
    else if (showRemoved) {
      console.error(`removed invalid value for ${castKey}: ${value}`)
    }
  }
  return filteredMap
}

export const batchDecodeDemographicMap = (demographicsMap: TideQueryParamMap<string>) => {
  const decodedMap: TideQueryParamMap<string> = {}
  for (const castKey of Object.values(TideQueryFieldNames)) {
    const encodedValue = demographicsMap[castKey]!
    const decodedValue = decodeDemographic(castKey, encodedValue)
    if (decodedValue) decodedMap[castKey] = decodedValue
  }
  return decodedMap
}

// Demographic data that is preloaded for testing. Used in getDevDemographicInformation.
const stubbedEncodedDemographics = {
  AGE: '20',
  Big_Ticket_Purchases: '1001001',
  ETHNICITY: '1',
  GENDER: '1',
  HISPANIC: '5',
  MS_is_mobile: 'false',
  OID: 'fake-oid',
  PID: 'fake-pid',
  Parental_Status_Standard: '0110',
  RID: 'fake-rid',
  STANDARD_B2B_DECISION_MAKER: '10010000100100100',
  STANDARD_COMPANY_DEPARTMENT: '1',
  STANDARD_EDUCATION: '4',
  STANDARD_EMPLOYMENT: '1',
  STANDARD_HHI_US: '16',
  STANDARD_HOUSEHOLD_TYPE: '1',
  STANDARD_INDUSTRY_PERSONAL: '9',
  STANDARD_JOB_TITLE: '4',
  STANDARD_NO_OF_EMPLOYEES: '8',
  STANDARD_RELATIONSHIP: '1',
  STATE: '5',
  SUPPLIER_NAME: 'lucid',
  provider: '1',
  supplierRid: 'fake-rid'
}

// Returns mock demographic query data for devMode unless displayDemographicQuestions is active.
export function getDevDemographicInformation (displayDemographicQuestions: boolean, windowSearch: string): string {
  if (displayDemographicQuestions) return windowSearch
  return '?' + map(stubbedEncodedDemographics, (val: string, key: string) => `${key}=${val}`).join('&') + '&' + windowSearch.slice(1, windowSearch.length)
}

export function encodeDemographicsForQtest (validEncodedDemographics: TideQueryParamMap<string | number>, decodedDemographics?: TideQueryParamMap<DecodedDemographicsAndContext>) {
  const encodedDemographics = { ...validEncodedDemographics }

  // encode passed-in demos like AGE, ZIP from strings to numbers
  Object.entries(encodedDemographics).forEach(([key, value]) => {
    const castKey = key as TideQueryFieldName
    const valueAsString = value as string
    const demosThatShouldBeNumbers = ['AGE', 'ZIP']
    if (demosThatShouldBeNumbers.includes(key)) encodedDemographics[castKey] = Number.parseInt(valueAsString)
  })

  if (decodedDemographics) {
    const demographicsWithContext = Object.values(decodedDemographics).filter(item => item !== undefined) as DecodedDemographicsAndContext[]

    demographicsWithContext.forEach(({ demographicContext, participantSelection }) => {
      const questionType = demographicToQuestionType[demographicContext.id]
      if (questionType === ReefQuestionType.MultiSelect && participantSelection && Array.isArray(participantSelection.value)) {
        let binaryEncodedAnswers = ''
        demographicContext.answers!.forEach(({ text }) => {
          if (participantSelection.value.includes(text)) binaryEncodedAnswers += '1'
          else binaryEncodedAnswers += '0'
        })
        encodedDemographics[demographicContext.id] = binaryEncodedAnswers
      } else if (questionType === ReefQuestionType.Number) {
        encodedDemographics[demographicContext.id] = participantSelection.value
      } else {
        encodedDemographics[demographicContext.id] = demographicContext.answers!.find(answer => answer.text === participantSelection.value)?.value
      }
    })
  }
  return encodedDemographics
}
