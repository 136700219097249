import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum B2BDecisionMaker {
  AUTO_LEASING = 'Auto leasing / purchasing',
  CORPORATE_TRAVEL = 'Corporate travel',
  FINANCES = 'Financial Department',
  FOOD_SERVICES = 'Food services',
  HUMAN_RESOURCES = 'Human Resources',
  IT_HARDWARE = 'IT Hardware',
  IT_SOFTWARE = 'IT Software',
  LEGAL_SERVICES = 'Legal services',
  MARKETING_ADVERTISING = 'Marketing / Advertising',
  OFFICE_SUPPLIES = 'Office supplies',
  OPERATIONS = 'Operations',
  PRINTERS_AND_COPIERS = 'Printers and copiers',
  PROCUREMENT = 'Procurement',
  SALES = 'Sales',
  SECURITY = 'Security',
  SHIPPING = 'Shipping',
  TELECOMMUNICATIONS = 'Telecommunications',
  OTHER = 'Other',
  NONE = 'I don\'t have influence or decision making authority'
}

export const B2BDecisionMakerMetadata: MultiselectDemographicFilterMetadata<B2BDecisionMaker> = {
  title: 'B2B Decision Maker',
  question: 'Please choose which departments/products you have influence or decision making authority over regarding spending/purchasing.',
  options: Object.values(B2BDecisionMaker)
}

// lucid question ID is 3546
export enum LucidB2BDecisionMaker {
  AUTO_LEASING = '16',
  CORPORATE_TRAVEL = '7',
  FINANCES = '4',
  FOOD_SERVICES = '15',
  HUMAN_RESOURCES = '5',
  IT_HARDWARE = '1',
  IT_SOFTWARE = '2',
  LEGAL_SERVICES = '12',
  MARKETING_ADVERTISING = '13',
  OFFICE_SUPPLIES = '6',
  OPERATIONS = '11',
  PRINTERS_AND_COPIERS = '3',
  PROCUREMENT = '19',
  SALES = '9',
  SECURITY = '14',
  SHIPPING = '10',
  TELECOMMUNICATIONS = '8',
  OTHER = '17',
  NONE = '18'
}

export const B2BDecisionMakerMapper: Record<B2BDecisionMaker, LucidB2BDecisionMaker> = {
  [B2BDecisionMaker.AUTO_LEASING]: LucidB2BDecisionMaker.AUTO_LEASING,
  [B2BDecisionMaker.CORPORATE_TRAVEL]: LucidB2BDecisionMaker.CORPORATE_TRAVEL,
  [B2BDecisionMaker.FINANCES]: LucidB2BDecisionMaker.FINANCES,
  [B2BDecisionMaker.FOOD_SERVICES]: LucidB2BDecisionMaker.FOOD_SERVICES,
  [B2BDecisionMaker.HUMAN_RESOURCES]: LucidB2BDecisionMaker.HUMAN_RESOURCES,
  [B2BDecisionMaker.IT_HARDWARE]: LucidB2BDecisionMaker.IT_HARDWARE,
  [B2BDecisionMaker.IT_SOFTWARE]: LucidB2BDecisionMaker.IT_SOFTWARE,
  [B2BDecisionMaker.LEGAL_SERVICES]: LucidB2BDecisionMaker.LEGAL_SERVICES,
  [B2BDecisionMaker.MARKETING_ADVERTISING]: LucidB2BDecisionMaker.MARKETING_ADVERTISING,
  [B2BDecisionMaker.OFFICE_SUPPLIES]: LucidB2BDecisionMaker.OFFICE_SUPPLIES,
  [B2BDecisionMaker.OPERATIONS]: LucidB2BDecisionMaker.OPERATIONS,
  [B2BDecisionMaker.PRINTERS_AND_COPIERS]: LucidB2BDecisionMaker.PRINTERS_AND_COPIERS,
  [B2BDecisionMaker.PROCUREMENT]: LucidB2BDecisionMaker.PROCUREMENT,
  [B2BDecisionMaker.SALES]: LucidB2BDecisionMaker.SALES,
  [B2BDecisionMaker.SECURITY]: LucidB2BDecisionMaker.SECURITY,
  [B2BDecisionMaker.SHIPPING]: LucidB2BDecisionMaker.SHIPPING,
  [B2BDecisionMaker.TELECOMMUNICATIONS]: LucidB2BDecisionMaker.TELECOMMUNICATIONS,
  [B2BDecisionMaker.OTHER]: LucidB2BDecisionMaker.OTHER,
  [B2BDecisionMaker.NONE]: LucidB2BDecisionMaker.NONE
}
