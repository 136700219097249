import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum InsuranceType {
  AUTO_INSURANCE = 'Auto Insurance',
  HOME_INSURANCE = 'Home Insurance',
  RENTERS_INSURANCE = 'Renters Insurance',
  HEALTH_INSURANCE = 'Health Insurance',
  LIFE_INSURANCE = 'Life Insurance Policies Not Provided By Employer',
  LIFE_INSURANCE_EMPLOYER = 'Life Insurance Policies Provided By Employer',
  EMERGENCY_ROADSIDE_ASSISTANCE = 'Emergency Roadside Assistance',
  MOTORCYCLE_INSURANCE = 'Motorcycle Insurance',
  PET_INSURANCE = 'Pet Insurance',
  NONE = 'None of the Above'
}

export enum InsuranceTypePrecodes {
  AUTO_INSURANCE = '1',
  HOME_INSURANCE = '2',
  RENTERS_INSURANCE = '3',
  HEALTH_INSURANCE = '4',
  LIFE_INSURANCE = '5',
  LIFE_INSURANCE_EMPLOYER = '6',
  EMERGENCY_ROADSIDE_ASSISTANCE = '7',
  MOTORCYCLE_INSURANCE = '8',
  PET_INSURANCE = '9',
  NONE = '10',
}

export const InsuranceTypeMetadata: MultiselectDemographicFilterMetadata<InsuranceType> = {
  title: 'Insurance Type',
  question: 'Which insurance do you currently have? Select all that apply.',
  options: Object.values(InsuranceType)
}

export const InsuranceTypeMapper: Record<InsuranceType, InsuranceTypePrecodes> = {
  [InsuranceType.AUTO_INSURANCE]: InsuranceTypePrecodes.AUTO_INSURANCE,
  [InsuranceType.HOME_INSURANCE]: InsuranceTypePrecodes.HOME_INSURANCE,
  [InsuranceType.RENTERS_INSURANCE]: InsuranceTypePrecodes.RENTERS_INSURANCE,
  [InsuranceType.HEALTH_INSURANCE]: InsuranceTypePrecodes.HEALTH_INSURANCE,
  [InsuranceType.LIFE_INSURANCE]: InsuranceTypePrecodes.LIFE_INSURANCE,
  [InsuranceType.LIFE_INSURANCE_EMPLOYER]: InsuranceTypePrecodes.LIFE_INSURANCE_EMPLOYER,
  [InsuranceType.EMERGENCY_ROADSIDE_ASSISTANCE]: InsuranceTypePrecodes.EMERGENCY_ROADSIDE_ASSISTANCE,
  [InsuranceType.MOTORCYCLE_INSURANCE]: InsuranceTypePrecodes.MOTORCYCLE_INSURANCE,
  [InsuranceType.PET_INSURANCE]: InsuranceTypePrecodes.PET_INSURANCE,
  [InsuranceType.NONE]: InsuranceTypePrecodes.NONE
}
