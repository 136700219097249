<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ContainerHorizontalPadding extends Vue {}
</script>

<style scoped lang="scss">

.container {
  padding-left: $content-padding-horizontal;
  padding-right: $content-padding-horizontal;
  background: transparent;

  @media (min-width: $max-content-width-desktop + $content-padding-horizontal) {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
