export enum SurveySection {
  Welcome = 'Welcome',
  Demographics = 'Demographics',
  Loading = 'Loading',
  AudienceGroup = 'AudienceGroup',
  EndSession = 'EndSession',
  Disqual = 'Disqual',
  AttemptingConnect = 'AttemptingConnect',
  OfflineDisqual = 'OfflineDisqual',
  InternalError = 'InternalError',
  SurveyUnavailable = 'SurveyUnavailable'
}
