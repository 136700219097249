/* eslint-disable import/first */
import Vue from 'vue'
Vue.config.devtools = true
import App from './App.vue'
import router from './router'
import store from './store'
import youTubeIframeApi from '@/utilities/youtubeIframeApi'
import { initializeAnalytics } from '@/utilities/analytics'
import { testDirective } from '@/utilities/vTestDirective'
import VueDragScroll from 'vue-dragscroll'
import { logger } from '@/utilities'

Vue.use(VueDragScroll)

Vue.directive('test', testDirective)

Vue.config.productionTip = false

initializeAnalytics()
youTubeIframeApi()

store.dispatch.session.initializeIsMobileChecking().catch(logger.error)

new Vue({
  router,
  store: store.original,
  render: h => h(App)
}).$mount('#app')
