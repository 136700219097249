import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum LoanType {
  AUTO = 'Auto Loan',
  STUDENT = 'Student Loan',
  MORTGAGE = 'Mortage',
  CREDIT_CARD = 'Credit Card Loan',
  PERSONAL = 'Personal Loans (Non Credit Card)',
  NONE = 'None of the Above'
}

// copied from disqo
export enum LoanTypePrecodes {
  AUTO = '1',
  STUDENT = '2',
  MORTGAGE = '3',
  CREDIT_CARD = '4',
  PERSONAL = '5',
  NONE = '6'
}

export const LoanTypeMetadata: MultiselectDemographicFilterMetadata<LoanType> = {
  title: 'Loan Types',
  question: 'Which of these loans do you have? Select all that apply.',
  options: Object.values(LoanType)
}

export const LoanTypeMapper: Record<LoanType, LoanTypePrecodes> = {
  [LoanType.AUTO]: LoanTypePrecodes.AUTO,
  [LoanType.STUDENT]: LoanTypePrecodes.STUDENT,
  [LoanType.MORTGAGE]: LoanTypePrecodes.MORTGAGE,
  [LoanType.CREDIT_CARD]: LoanTypePrecodes.CREDIT_CARD,
  [LoanType.PERSONAL]: LoanTypePrecodes.PERSONAL,
  [LoanType.NONE]: LoanTypePrecodes.NONE
}
