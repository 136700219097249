import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum Education {
  ASSOCIATE_DEGREE = 'Associate Degree',
  COLLEGE_DEGREE = 'College Degree (such as B.A., B.S.)',
  DOCTORATE_DEGREE = 'Doctorate degree',
  HIGH_SCHOOL_GRADUATE = 'High school graduate',
  MASTERS_DEGREE = 'Masters degree',
  MIDDLE_SCHOOL = 'Middle School - Grades 4 - 8',
  OTHER = 'None of the above',
  SOME_COLLEGE = 'Completed some college, but no degree',
  SOME_GRADUATE = 'Completed some graduate, but no degree',
  SOME_HIGH_SCHOOL = 'Completed some high school',
  THIRD_GRADE_OR_LESS = '3rd Grade or less',
  VOCATIONAL_TRAINING = 'Other post high school vocational training'
}

export const EducationMetadata: MultiselectDemographicFilterMetadata<Education> = {
  title: 'Education',
  question: 'What is the highest level of education you have completed?',
  options: Object.values(Education)
}

// lucid question ID is 633
export enum LucidEducation {
  THIRD_GRADE_OR_LESS = '1',
  MIDDLE_SCHOOL = '2',
  SOME_HIGH_SCHOOL = '3',
  HIGH_SCHOOL_GRADUATE = '4',
  VOCATIONAL_TRAINING = '5',
  SOME_COLLEGE = '6',
  ASSOCIATE_DEGREE = '7',
  COLLEGE_DEGREE = '8',
  SOME_GRADUATE = '9',
  MASTERS_DEGREE = '10',
  DOCTORATE_DEGREE = '11',
  OTHER = '12'
}

export const EducationMapper: Record<Education, LucidEducation> = {
  [Education.ASSOCIATE_DEGREE]: LucidEducation.ASSOCIATE_DEGREE,
  [Education.COLLEGE_DEGREE]: LucidEducation.COLLEGE_DEGREE,
  [Education.DOCTORATE_DEGREE]: LucidEducation.DOCTORATE_DEGREE,
  [Education.HIGH_SCHOOL_GRADUATE]: LucidEducation.HIGH_SCHOOL_GRADUATE,
  [Education.MASTERS_DEGREE]: LucidEducation.MASTERS_DEGREE,
  [Education.MIDDLE_SCHOOL]: LucidEducation.MIDDLE_SCHOOL,
  [Education.SOME_COLLEGE]: LucidEducation.SOME_COLLEGE,
  [Education.SOME_GRADUATE]: LucidEducation.SOME_GRADUATE,
  [Education.SOME_HIGH_SCHOOL]: LucidEducation.SOME_HIGH_SCHOOL,
  [Education.THIRD_GRADE_OR_LESS]: LucidEducation.THIRD_GRADE_OR_LESS,
  [Education.VOCATIONAL_TRAINING]: LucidEducation.VOCATIONAL_TRAINING,
  [Education.OTHER]: LucidEducation.OTHER
}

export enum LucidEducationV2 {
  SOME_HIGH_SCHOOL = '1',
  HIGH_SCHOOL_GRADUATE = '2',
  VOCATIONAL_TRAINING = '3',
  SOME_COLLEGE = '4',
  ASSOCIATE_DEGREE = '5',
  COLLEGE_DEGREE = '6',
  MASTERS_DEGREE = '7',
  DOCTORATE_DEGREE = '8',
  NONE_OF_THE_ABOVE = '-3105'
}

export const LucidEducationV2Mapper : Record<LucidEducationV2, Education> = {
  [LucidEducationV2.ASSOCIATE_DEGREE]: Education.ASSOCIATE_DEGREE,
  [LucidEducationV2.COLLEGE_DEGREE]: Education.COLLEGE_DEGREE,
  [LucidEducationV2.DOCTORATE_DEGREE]: Education.DOCTORATE_DEGREE,
  [LucidEducationV2.HIGH_SCHOOL_GRADUATE]: Education.HIGH_SCHOOL_GRADUATE,
  [LucidEducationV2.MASTERS_DEGREE]: Education.MASTERS_DEGREE,
  [LucidEducationV2.SOME_COLLEGE]: Education.SOME_COLLEGE,
  [LucidEducationV2.SOME_HIGH_SCHOOL]: Education.SOME_HIGH_SCHOOL,
  [LucidEducationV2.VOCATIONAL_TRAINING]: Education.VOCATIONAL_TRAINING,
  [LucidEducationV2.NONE_OF_THE_ABOVE]: Education.OTHER
}
