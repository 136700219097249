import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum State {
  ALABAMA = 'ALABAMA',
  ALASKA = 'ALASKA',
  AMERICAN_SAMOA = 'American Samoa',
  ARIZONA = 'ARIZONA',
  ARKANSAS = 'ARKANSAS',
  CALIFORNIA = 'CALIFORNIA',
  COLORADO = 'COLORADO',
  CONNECTICUT = 'CONNECTICUT',
  DELAWARE = 'DELAWARE',
  DISTRICT_OF_COLUMBIA = 'DISTRICT OF COLUMBIA',
  FEDERATED_STATES_OF_MICRONESIA = 'Federated States of Micronesia',
  FLORIDA = 'FLORIDA',
  GEORGIA = 'GEORGIA',
  GUAM = 'Guam',
  HAWAII = 'HAWAII',
  IDAHO = 'IDAHO',
  ILLINOIS = 'ILLINOIS',
  INDIANA = 'INDIANA',
  IOWA = 'IOWA',
  KANSAS = 'KANSAS',
  KENTUCKY = 'KENTUCKY',
  LOUISIANA = 'LOUISIANA',
  MAINE = 'MAINE',
  MARSHALL_ISLANDS = 'Marshall Islands',
  MARYLAND = 'MARYLAND',
  MASSACHUSETTS = 'MASSACHUSETTS',
  MICHIGAN = 'MICHIGAN',
  MINNESOTA = 'MINNESOTA',
  MISSISSIPPI = 'MISSISSIPPI',
  MISSOURI = 'MISSOURI',
  MONTANA = 'MONTANA',
  NEBRASKA = 'NEBRASKA',
  NEVADA = 'NEVADA',
  NEW_HAMPSHIRE = 'NEW HAMPSHIRE',
  NEW_JERSEY = 'NEW JERSEY',
  NEW_MEXICO = 'NEW MEXICO',
  NEW_YORK = 'NEW YORK',
  NORTH_CAROLINA = 'NORTH CAROLINA',
  NORTH_DAKOTA = 'NORTH DAKOTA',
  NORTHERN_MARIANA_ISLANDS = 'Northern Mariana Islands',
  OHIO = 'OHIO',
  OKLAHOMA = 'OKLAHOMA',
  OREGON = 'OREGON',
  PALAU = 'Palau',
  PENNSYLVANIA = 'PENNSYLVANIA',
  PUERTO_RICO = 'Puerto Rico',
  RHODE_ISLAND = 'RHODE ISLAND',
  SOUTH_CAROLINA = 'SOUTH CAROLINA',
  SOUTH_DAKOTA = 'SOUTH DAKOTA',
  TENNESSEE = 'TENNESSEE',
  TEXAS = 'TEXAS',
  UTAH = 'UTAH',
  VERMONT = 'VERMONT',
  VIRGIN_ISLANDS = 'Virgin Islands',
  VIRGINIA = 'VIRGINIA',
  WASHINGTON = 'WASHINGTON',
  WEST_VIRGINIA = 'WEST VIRGINIA',
  WISCONSIN = 'WISCONSIN',
  WYOMING = 'WYOMING',
  NOT_APPLICABLE = 'NOT APPLICABLE',
}

export const StateMetadata: MultiselectDemographicFilterMetadata<State> = {
  title: 'State',
  question: 'What state do you live in?',
  options: Object.values(State)
}

// lucid question ID is 96
export enum LucidState {
  NOT_APPLICABLE = '0',
  ALABAMA = '1',
  ALASKA = '2',
  ARIZONA = '3',
  ARKANSAS = '4',
  CALIFORNIA = '5',
  COLORADO = '6',
  CONNECTICUT = '7',
  DELAWARE = '8',
  DISTRICT_OF_COLUMBIA = '9',
  FLORIDA = '10',
  GEORGIA = '11',
  HAWAII = '12',
  IDAHO = '13',
  ILLINOIS = '14',
  INDIANA = '15',
  IOWA = '16',
  KANSAS = '17',
  KENTUCKY = '18',
  LOUISIANA = '19',
  MAINE = '20',
  MARYLAND = '21',
  MASSACHUSETTS = '22',
  MICHIGAN = '23',
  MINNESOTA = '24',
  MISSISSIPPI = '25',
  MISSOURI = '26',
  MONTANA = '27',
  NEBRASKA = '28',
  NEVADA = '29',
  NEW_HAMPSHIRE = '30',
  NEW_JERSEY = '31',
  NEW_MEXICO = '32',
  NEW_YORK = '33',
  NORTH_CAROLINA = '34',
  NORTH_DAKOTA = '35',
  OHIO = '36',
  OKLAHOMA = '37',
  OREGON = '38',
  PENNSYLVANIA = '39',
  RHODE_ISLAND = '40',
  SOUTH_CAROLINA = '41',
  SOUTH_DAKOTA = '42',
  TENNESSEE = '43',
  TEXAS = '44',
  UTAH = '45',
  VERMONT = '46',
  VIRGINIA = '47',
  WASHINGTON = '48',
  WEST_VIRGINIA = '49',
  WISCONSIN = '50',
  WYOMING = '51',
  // there was no 52 in the original code
  AMERICAN_SAMOA = '53',
  FEDERATED_STATES_OF_MICRONESIA = '54',
  GUAM = '55',
  MARSHALL_ISLANDS = '56',
  NORTHERN_MARIANA_ISLANDS = '57',
  PALAU = '58',
  PUERTO_RICO = '59',
  VIRGIN_ISLANDS = '60',
}

export const StateMapper: Record<State, LucidState> = {
  [State.ALABAMA]: LucidState.ALABAMA,
  [State.ALASKA]: LucidState.ALASKA,
  [State.AMERICAN_SAMOA]: LucidState.AMERICAN_SAMOA,
  [State.ARIZONA]: LucidState.ARIZONA,
  [State.ARKANSAS]: LucidState.ARKANSAS,
  [State.CALIFORNIA]: LucidState.CALIFORNIA,
  [State.COLORADO]: LucidState.COLORADO,
  [State.CONNECTICUT]: LucidState.CONNECTICUT,
  [State.DELAWARE]: LucidState.DELAWARE,
  [State.DISTRICT_OF_COLUMBIA]: LucidState.DISTRICT_OF_COLUMBIA,
  [State.FEDERATED_STATES_OF_MICRONESIA]: LucidState.FEDERATED_STATES_OF_MICRONESIA,
  [State.FLORIDA]: LucidState.FLORIDA,
  [State.GEORGIA]: LucidState.GEORGIA,
  [State.GUAM]: LucidState.GUAM,
  [State.HAWAII]: LucidState.HAWAII,
  [State.IDAHO]: LucidState.IDAHO,
  [State.ILLINOIS]: LucidState.ILLINOIS,
  [State.INDIANA]: LucidState.INDIANA,
  [State.IOWA]: LucidState.IOWA,
  [State.KANSAS]: LucidState.KANSAS,
  [State.KENTUCKY]: LucidState.KENTUCKY,
  [State.LOUISIANA]: LucidState.LOUISIANA,
  [State.MAINE]: LucidState.MAINE,
  [State.MARSHALL_ISLANDS]: LucidState.MARSHALL_ISLANDS,
  [State.MARYLAND]: LucidState.MARYLAND,
  [State.MASSACHUSETTS]: LucidState.MASSACHUSETTS,
  [State.MICHIGAN]: LucidState.MICHIGAN,
  [State.MINNESOTA]: LucidState.MINNESOTA,
  [State.MISSISSIPPI]: LucidState.MISSISSIPPI,
  [State.MISSOURI]: LucidState.MISSOURI,
  [State.MONTANA]: LucidState.MONTANA,
  [State.NEBRASKA]: LucidState.NEBRASKA,
  [State.NEVADA]: LucidState.NEVADA,
  [State.NEW_HAMPSHIRE]: LucidState.NEW_HAMPSHIRE,
  [State.NEW_JERSEY]: LucidState.NEW_JERSEY,
  [State.NEW_MEXICO]: LucidState.NEW_MEXICO,
  [State.NEW_YORK]: LucidState.NEW_YORK,
  [State.NORTH_CAROLINA]: LucidState.NORTH_CAROLINA,
  [State.NORTH_DAKOTA]: LucidState.NORTH_DAKOTA,
  [State.NORTHERN_MARIANA_ISLANDS]: LucidState.NORTHERN_MARIANA_ISLANDS,
  [State.OHIO]: LucidState.OHIO,
  [State.OKLAHOMA]: LucidState.OKLAHOMA,
  [State.OREGON]: LucidState.OREGON,
  [State.PALAU]: LucidState.PALAU,
  [State.PENNSYLVANIA]: LucidState.PENNSYLVANIA,
  [State.PUERTO_RICO]: LucidState.PUERTO_RICO,
  [State.RHODE_ISLAND]: LucidState.RHODE_ISLAND,
  [State.SOUTH_CAROLINA]: LucidState.SOUTH_CAROLINA,
  [State.SOUTH_DAKOTA]: LucidState.SOUTH_DAKOTA,
  [State.TENNESSEE]: LucidState.TENNESSEE,
  [State.TEXAS]: LucidState.TEXAS,
  [State.UTAH]: LucidState.UTAH,
  [State.VERMONT]: LucidState.VERMONT,
  [State.VIRGIN_ISLANDS]: LucidState.VIRGIN_ISLANDS,
  [State.VIRGINIA]: LucidState.VIRGINIA,
  [State.WASHINGTON]: LucidState.WASHINGTON,
  [State.WEST_VIRGINIA]: LucidState.WEST_VIRGINIA,
  [State.WISCONSIN]: LucidState.WISCONSIN,
  [State.WYOMING]: LucidState.WYOMING,
  [State.NOT_APPLICABLE]: LucidState.NOT_APPLICABLE
}
