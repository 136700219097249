import { render, staticRenderFns } from "./DisqualificationAnimation.vue?vue&type=template&id=c894dc84&scoped=true&"
import script from "./DisqualificationAnimation.vue?vue&type=script&lang=ts&"
export * from "./DisqualificationAnimation.vue?vue&type=script&lang=ts&"
import style0 from "./DisqualificationAnimation.vue?vue&type=style&index=0&id=c894dc84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c894dc84",
  null
  
)

export default component.exports