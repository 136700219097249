import { render, staticRenderFns } from "./Matrix.vue?vue&type=template&id=d52e6e68&scoped=true&"
import script from "./Matrix.vue?vue&type=script&lang=ts&"
export * from "./Matrix.vue?vue&type=script&lang=ts&"
import style0 from "./Matrix.vue?vue&type=style&index=0&id=d52e6e68&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d52e6e68",
  null
  
)

export default component.exports