import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum Employment {
  ACTIVE_MILITARY = 'Active military',
  DISABLED = 'Disabled',
  EMPLOYED_FULL_TIME = 'Employed full-time',
  EMPLOYED_PART_TIME = 'Employed part-time',
  FULL_TIME_HOMEMAKER = 'Full-time homemaker',
  INACTIVE_MILITARY_OR_VETERAN = 'Inactive military/Veteran',
  RETIRED = 'Retired',
  SELF_EMPLOYED_FULL_TIME = 'Self-employed full-time',
  SELF_EMPLOYED_PART_TIME = 'Self-employed part-time',
  STUDENT = 'Student',
  TEMPORARILY_UNEMPLOYED = 'Temporarily unemployed',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer'
}

export const EmploymentMetadata: MultiselectDemographicFilterMetadata<Employment> = {
  title: 'Employment',
  question: 'What is your current employment status?',
  options: Object.values(Employment)
}

// lucid question ID is 2189
export enum LucidEmployment {
  EMPLOYED_FULL_TIME = '1',
  EMPLOYED_PART_TIME = '2',
  SELF_EMPLOYED_FULL_TIME = '3',
  SELF_EMPLOYED_PART_TIME = '4',
  ACTIVE_MILITARY = '5',
  INACTIVE_MILITARY_OR_VETERAN = '6',
  TEMPORARILY_UNEMPLOYED = '7',
  FULL_TIME_HOMEMAKER = '8',
  RETIRED = '9',
  STUDENT = '10',
  DISABLED = '11',
  PREFER_NOT_TO_ANSWER = '12'
}

export const EmploymentMapper: Record<Employment, LucidEmployment> = {
  [Employment.ACTIVE_MILITARY]: LucidEmployment.ACTIVE_MILITARY,
  [Employment.DISABLED]: LucidEmployment.DISABLED,
  [Employment.EMPLOYED_FULL_TIME]: LucidEmployment.EMPLOYED_FULL_TIME,
  [Employment.EMPLOYED_PART_TIME]: LucidEmployment.EMPLOYED_PART_TIME,
  [Employment.FULL_TIME_HOMEMAKER]: LucidEmployment.FULL_TIME_HOMEMAKER,
  [Employment.INACTIVE_MILITARY_OR_VETERAN]: LucidEmployment.INACTIVE_MILITARY_OR_VETERAN,
  [Employment.RETIRED]: LucidEmployment.RETIRED,
  [Employment.SELF_EMPLOYED_FULL_TIME]: LucidEmployment.SELF_EMPLOYED_FULL_TIME,
  [Employment.SELF_EMPLOYED_PART_TIME]: LucidEmployment.SELF_EMPLOYED_PART_TIME,
  [Employment.STUDENT]: LucidEmployment.STUDENT,
  [Employment.TEMPORARILY_UNEMPLOYED]: LucidEmployment.TEMPORARILY_UNEMPLOYED,
  [Employment.PREFER_NOT_TO_ANSWER]: LucidEmployment.PREFER_NOT_TO_ANSWER
}
