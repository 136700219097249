import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum HealthInsurance {
  NO_HEALTH_INSURANCE = 'No health insurance',
  EMPLOYER_HEALTH_INSURANCE = 'Employer health insurance',
  SPOUSE_EMPLOYER_HEALTH_INSURANCE = 'Spouse’s employer health insurance',
  PRIVATE_INDV_FAMILY_HEALTH_INSURANCE = 'Private individual/family plan health insurance',
  COVERED_BY_SCHOOL = 'Covered by school',
  COVERED_BY_PARENTS = 'Covered by parents',
  MEDICAID = 'Medicaid',
  MEDICARE = 'Medicare',
  PREFER_NOT_TO_ANSWER = 'Prefer not to say'
}

export const HealthInsurancetMetadata: MultiselectDemographicFilterMetadata<HealthInsurance> = {
  title: 'Health Insurance ',
  question: 'What best describes your current health insurance?',
  options: Object.values(HealthInsurance)
}

// question ID is 105016
export enum LucidHealthInsurance {
  NO_HEALTH_INSURANCE = '1',
  EMPLOYER_HEALTH_INSURANCE = '2',
  SPOUSE_EMPLOYER_HEALTH_INSURANCE = '3',
  PRIVATE_INDV_FAMILY_HEALTH_INSURANCE = '4',
  COVERED_BY_SCHOOL = '5',
  COVERED_BY_PARENTS = '6',
  MEDICAID = '7',
  MEDICARE = '8',
  PREFER_NOT_TO_ANSWER = '9'
}

export const HealthInsuranceMapper: Record<HealthInsurance, LucidHealthInsurance> = {
  [HealthInsurance.NO_HEALTH_INSURANCE]: LucidHealthInsurance.NO_HEALTH_INSURANCE,
  [HealthInsurance.EMPLOYER_HEALTH_INSURANCE]: LucidHealthInsurance.EMPLOYER_HEALTH_INSURANCE,
  [HealthInsurance.SPOUSE_EMPLOYER_HEALTH_INSURANCE]: LucidHealthInsurance.SPOUSE_EMPLOYER_HEALTH_INSURANCE,
  [HealthInsurance.PRIVATE_INDV_FAMILY_HEALTH_INSURANCE]: LucidHealthInsurance.PRIVATE_INDV_FAMILY_HEALTH_INSURANCE,
  [HealthInsurance.COVERED_BY_SCHOOL]: LucidHealthInsurance.COVERED_BY_SCHOOL,
  [HealthInsurance.COVERED_BY_PARENTS]: LucidHealthInsurance.COVERED_BY_PARENTS,
  [HealthInsurance.MEDICAID]: LucidHealthInsurance.MEDICAID,
  [HealthInsurance.MEDICARE]: LucidHealthInsurance.MEDICARE,
  [HealthInsurance.PREFER_NOT_TO_ANSWER]: LucidHealthInsurance.PREFER_NOT_TO_ANSWER
}
