import { TideQueryParamMap } from './demographics'

export enum PanelProvider {
  LUCID = 'lucid',
  CINT = 'cint',
  DISQO = 'disqo',
  BYOA = 'byoa',
  ACTIVE_MEASURE = 'active-measure'
}

export enum ProviderEncoding {
  LUCID = '1',
  CINT = '2'
}

export interface SessionStoreDemographicV0 {
  result: TideQueryParamMap<string>
  hasUnhandledDecodes: boolean
}

export function decodePanelProvider (encodedProvider: string): PanelProvider | undefined {
  switch (encodedProvider) {
    case ProviderEncoding.LUCID:
      return PanelProvider.LUCID
    case ProviderEncoding.CINT:
      return PanelProvider.CINT
    // Disqo let's us set the provider, so let's just use one Enum
    case PanelProvider.DISQO:
      return PanelProvider.DISQO
    case PanelProvider.BYOA:
      return PanelProvider.BYOA
    case PanelProvider.ACTIVE_MEASURE:
      return PanelProvider.ACTIVE_MEASURE
    default:
      return undefined
  }
}
