import { MultiselectSubset } from '../demographicFilterSubset'
import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum RelationshipStatus {
  SINGLE_UNMARRIED = 'Single, never married',
  MARRIED = 'Married',
  PREVIOUSLY_MARRIED = 'Separated, divorced, or widowed',
  DOMESTIC_PARTNERSHIP = 'Domestic partnership/living with someone',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer'
}

export const RelationshipStatusMetadata: MultiselectDemographicFilterMetadata<RelationshipStatus> = {
  title: 'Relationship Status',
  question: 'What is your relationship status?',
  options: Object.values(RelationshipStatus)
}

// lucid question ID is 632
export enum LucidRelationshipStatus {
  SINGLE_UNMARRIED = '1',
  MARRIED = '2',
  PREVIOUSLY_MARRIED = '3',
  DOMESTIC_PARTNERSHIP = '4',
  PREFER_NOT_TO_ANSWER = '5'
}

export const RelationshipStatusMapper: Record<RelationshipStatus, LucidRelationshipStatus> = {
  [RelationshipStatus.SINGLE_UNMARRIED]: LucidRelationshipStatus.SINGLE_UNMARRIED,
  [RelationshipStatus.MARRIED]: LucidRelationshipStatus.MARRIED,
  [RelationshipStatus.PREVIOUSLY_MARRIED]: LucidRelationshipStatus.PREVIOUSLY_MARRIED,
  [RelationshipStatus.DOMESTIC_PARTNERSHIP]: LucidRelationshipStatus.DOMESTIC_PARTNERSHIP,
  [RelationshipStatus.PREFER_NOT_TO_ANSWER]: LucidRelationshipStatus.PREFER_NOT_TO_ANSWER
}

export type Subset = MultiselectSubset<RelationshipStatus>
