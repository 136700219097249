import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum Region {
  MIDWEST = 'Midwest',
  NORTHEAST = 'Northeast',
  SOUTH = 'South',
  WEST = 'West'
}

export const RegionMetadata: MultiselectDemographicFilterMetadata<Region> = {
  title: 'Region',
  question: 'Which of the following best describes the region of the United States that you currently live in?',
  options: Object.values(Region)
}

// lucid question ID is 122
export enum LucidRegion {
  NORTHEAST = '1',
  MIDWEST = '2',
  SOUTH = '3',
  WEST = '4'
}

export const RegionMapper: Record<Region, LucidRegion> = {
  [Region.MIDWEST]: LucidRegion.MIDWEST,
  [Region.NORTHEAST]: LucidRegion.NORTHEAST,
  [Region.SOUTH]: LucidRegion.SOUTH,
  [Region.WEST]: LucidRegion.WEST
}
