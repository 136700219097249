import { cloneDeep } from 'lodash-es'
import type { AnswerOptionFromQuestion, TideQuestion } from '@feedbackloop/shared'

/**
 * Shuffle answers that are not locked, pin "none of the above" to the bottom, and pin "other"
 * either to the bottom or directly above "none of the above"
 */
export function shuffleAndSortAnswers (answers: AnswerOptionFromQuestion[], naOptText: string | undefined) {
  let finalAnswers = cloneDeep(answers)

  finalAnswers = shuffleAnswers(finalAnswers)

  moveNoneAndOtherToEnd(finalAnswers, naOptText)

  return finalAnswers
}

export function getBalancedAnswerOrder (question: TideQuestion, permutation: number = 0, devMode = false) {
  const answers: AnswerOptionFromQuestion[] = question.answers

  // Don't use permutator() if there are any locked options (answer.shuffle === false), it does not
  // take that flag into account, must use shuffleAndSortAnswers()
  const anyLockedPositions = answers.some(answer => answer.shuffle === false)

  let finalAnswers = cloneDeep(answers)
  // Add a failsafe when answer options are > 5, as that's the largest reasonable number we can circulate in this manner. Primarily this prevents an app crash when there are higher numbers of possible permutations
  if (answers.length <= 5 && !devMode && !anyLockedPositions) {
    const permutations = permutator(finalAnswers)
    finalAnswers = permutations[permutation % permutations.length]
  } else {
    finalAnswers = shuffleAndSortAnswers(finalAnswers, question.naOptText)
  }

  moveNoneAndOtherToEnd(finalAnswers, question.naOptText)
  return finalAnswers
}

function shuffleAnswers (answers: AnswerOptionFromQuestion[]) {
  const shuffledAnswers = new Array(answers.length)
  answers.forEach((answer, index) => {
    if (!answer.shuffle) {
      shuffledAnswers[index] = answer
    }
  })
  answers.forEach(answer => {
    if (answer.shuffle) {
      let randomIdx = getRandomInt(0, answers.length - 1)
      while (shuffledAnswers[randomIdx]) {
        randomIdx = getRandomInt(0, answers.length - 1)
      }
      shuffledAnswers[randomIdx] = answer
    }
  })
  return shuffledAnswers
}

function moveNoneAndOtherToEnd (possibleAnswers: AnswerOptionFromQuestion[], naOptText: string | undefined) {
  if (!possibleAnswers) return

  const exclusiveAnswerIndex = possibleAnswers.findIndex(answer => answer.text === naOptText || answer.exclusive)
  if (exclusiveAnswerIndex > -1) {
    moveItemInArray(possibleAnswers, exclusiveAnswerIndex, possibleAnswers.length - 1)
  }

  const otherAnswerIndex = possibleAnswers.findIndex(answer => answer.text === 'Other')
  if (otherAnswerIndex > -1) {
    const indexToMoveTo = exclusiveAnswerIndex > -1 ? possibleAnswers.length - 2 : possibleAnswers.length - 1
    moveItemInArray(possibleAnswers, otherAnswerIndex, indexToMoveTo)
  }
}

function getRandomInt (min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function moveItemInArray (arr: any[], fromIndex: number, toIndex: number) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

/**
 * Used for direct-comparison balanced randomization (right now). Takes in an arry of answers, returns all possible display order permutations as a nested array.
 * Recursive - (thanks leetcode, I knew all that practice I did for FAANG interviews would come in handy one day!)
 * @param possibleAnswers the vanilla collection of possible answers to a direct-comparison question
 */
function permutator (possibleAnswers: AnswerOptionFromQuestion[]) : Array<AnswerOptionFromQuestion[]> {
  const result: Array<AnswerOptionFromQuestion[]> = []

  // 'answers' is always whatever we haven't yet permuted, 'm' is the mutation we're making
  const permute = (answers: AnswerOptionFromQuestion[], m: AnswerOptionFromQuestion[] = []) => {
    if (answers.length === 0) {
      // base case (we can start reversing the stack because we permuted everything in our current loop) ie: m = 0, 1, 2, 3
      result.push(m)
    } else {
      for (let i = 0; i < answers.length; i++) {
        // copy what was passed in (ie: 0, 1, 2, 3)
        const currentPermutation = answers.slice()
        // split our copy at i (ie: next = 0, currentPermutation = 1, 2, 3)
        const next = currentPermutation.splice(i, 1)
        // recurse!
        permute(currentPermutation.slice(), m.concat(next))
      }
    }
  }

  permute(possibleAnswers)

  return result
}
