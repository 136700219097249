import type { IRule, IValueToCompare } from '@feedbackloop/types'
import type { EqualityOperator } from '@feedbackloop/types-graphql'

export class RuleEngineError extends Error {
  constructor (msg: string) {
    super(`RULE ENGINE: ${msg}`)

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RuleEngineError.prototype)
  }
}

export const areSameTypeValidator = (answer: any, ruleValue: IValueToCompare | undefined | null, equalityOperator: EqualityOperator): void => {
  // if the answer is null / empty / '' then we will let it through for the equality check to turn down
  // for right now we do not handle the ruleValue as null / undefined / '' should be handled as an equality operator
  if (answer === null || answer === undefined || answer === '') return

  const val1Type = typeof answer
  const val2Type = typeof ruleValue

  if (val1Type !== val2Type) {
    throw new RuleEngineError(`Dissimilar value types "${val1Type} ${equalityOperator} ${val2Type}"`)
  }
}

export const ruleValueToCompareValidator = (rule: IRule): void => {
  const isArray = Array.isArray(rule?.valueToCompare)

  if (
    !isArray && (
      (rule?.valueToCompare as IValueToCompare)?.value === null ||
      (rule?.valueToCompare as IValueToCompare)?.value === undefined ||
      (rule?.valueToCompare as IValueToCompare)?.value === ''
    )
  ) throw new RuleEngineError(`rule.valueToCompare Missing "${rule}"`)

  if (
    isArray && (
      rule?.valueToCompare === null ||
      rule?.valueToCompare === undefined
    )
  ) throw new RuleEngineError(`rule.valueToCompare Missing "${rule}"`)
}
