import { type IRuleSet } from '@feedbackloop/types'
import { RuleSetAction } from '@feedbackloop/types-graphql'
import { RuleEngineError } from './rule-engine-validators'

export const actionHandler = (ruleSet: IRuleSet) => {
  switch (ruleSet?.action) {
    case RuleSetAction.SKIP_TO:
      if (!ruleSet?.questionIndex) throw new RuleEngineError('RuleSetAction.SKIP_TO specified without question index')
      return ruleSet?.questionIndex

    default:
      throw new RuleEngineError(`RuleSetAction Not Supported "${ruleSet?.action}"`)
  }
}
