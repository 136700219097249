import store from '@/store'

declare global {
  interface Window { onYouTubeIframeAPIReady: any }
}

export default function () {
  window.onYouTubeIframeAPIReady = function () {
    store.commit.setYouTubeApiIframeReady(true)
  }

  const youtubeScriptTag = document.createElement('script')
  youtubeScriptTag.src = 'https://www.youtube.com/iframe_api'
  document.body.appendChild(youtubeScriptTag)
}
