import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum EmployeeCount {
  N_1 = '1',
  N_2_TO_10 = '2-10',
  N_11_TO_50 = '11-50',
  N_51_TO_100 = '51-100',
  N_101_TO_500 = '101-500',
  N_501_TO_1000 = '501-1000',
  N_1001_TO_5000 = '1001-5000',
  N_MORE_THAN_5000 = 'Greater than 5000',
  UNKNOWN = "I don't work/I don't know"
}

export const EmployeeCountMetadata: MultiselectDemographicFilterMetadata<EmployeeCount> = {
  title: '# of Employees',
  question: 'Approximately how many employees work at your organization (all locations)?',
  options: Object.values(EmployeeCount)
}

// question ID is 22467
export enum LucidEmployeeCount {
  N_1 = '1',
  N_2_TO_10 = '2',
  N_11_TO_50 = '3',
  N_51_TO_100 = '4',
  N_101_TO_500 = '5',
  N_501_TO_1000 = '6',
  N_1001_TO_5000 = '7',
  N_MORE_THAN_5000 = '8',
  UNKNOWN = '9'
}

export const EmployeeCountMapper: Record<EmployeeCount, LucidEmployeeCount> = {
  [EmployeeCount.N_1]: LucidEmployeeCount.N_1,
  [EmployeeCount.N_2_TO_10]: LucidEmployeeCount.N_2_TO_10,
  [EmployeeCount.N_11_TO_50]: LucidEmployeeCount.N_11_TO_50,
  [EmployeeCount.N_51_TO_100]: LucidEmployeeCount.N_51_TO_100,
  [EmployeeCount.N_101_TO_500]: LucidEmployeeCount.N_101_TO_500,
  [EmployeeCount.N_501_TO_1000]: LucidEmployeeCount.N_501_TO_1000,
  [EmployeeCount.N_1001_TO_5000]: LucidEmployeeCount.N_1001_TO_5000,
  [EmployeeCount.N_MORE_THAN_5000]: LucidEmployeeCount.N_MORE_THAN_5000,
  [EmployeeCount.UNKNOWN]: LucidEmployeeCount.UNKNOWN
}
