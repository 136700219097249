/* istanbul ignore file */
import type AttributionEvent from '../core/AttributionEvent'
import AttributionTarget from './AttributionTarget'
import { AttributionDataType } from '../core/enums'
import { AttributionEventType } from '@feedbackloop/shared'
import LogRocket from 'logrocket'
import type { ILog } from '@feedbackloop/types/src/ILog'

export default class LogRocketTarget extends AttributionTarget implements ILog {
  notableData: Array<AttributionDataType | string> = [
    AttributionDataType.QuestionType,
    AttributionDataType.TapId,
    AttributionDataType.AudienceId
  ]

  notableEvents: Array<AttributionEventType> = [
    AttributionEventType.QualifiedForAudience,
    AttributionEventType.SeenQuestion,
    AttributionEventType.FailedRedFlag,
    AttributionEventType.CompletedSession,
    AttributionEventType.CompletedTest,
    AttributionEventType.Land,
    AttributionEventType.NavigatedAway,
    AttributionEventType.Redirected
  ]

  logRocket: typeof LogRocket

  constructor () {
    super()
    this.logRocket = LogRocket
  }

  assign (key: string, value: any): void {
    if (this.notableData.includes(key)) {
      this.logRocket.track(`Key ${key} was set`)
    }

    this.logRocket.debug(`Key ${key} was set to ${value}`)
  }

  public inform (aEvent: AttributionEvent): void {
    this.logRocket.track(`Event ${aEvent.eventType} event was raised`)
    if (this.notableEvents.includes(aEvent.eventType)) {
      aEvent.eventContext.forEach((dataString) => {
        const dataPoint = dataString.split(':')
        if (this.notableData.includes(dataPoint[0])) {
          this.logRocket.track(`${dataPoint[0]} is ${dataPoint[1]}`)
        }
      })
    }

    this.logRocket.debug(`${aEvent.getHash()} event was raised`)
  }

  public error (key: string, ...data: any[]): void {
    this.logRocket.track('Error')
    this.logRocket.error('key', ...data)
  }

  public log (key: string, ...data: any[]): void { this.logRocket.log(key, ...data) }
  public warn (key: string, ...data: any[]): void { this.logRocket.warn(key, ...data) }
  public debug (key: string, ...data: any[]): void { this.logRocket.debug(key, ...data) }
  public info (key: string, ...data: any[]): void { this.logRocket.info(key, ...data) }

  cleanup (): void {
    this.logRocket.track('Cleanup')
  }
}
