import { MultiselectSubset } from '../demographicFilterSubset'
import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female'
}

export const GenderMetadata: MultiselectDemographicFilterMetadata<Gender> = {
  title: 'Gender',
  question: 'What is your gender?',
  options: Object.values(Gender)
}

// lucid question ID is 43
export enum LucidGender {
  MALE = '1',
  FEMALE = '2'
}

export const GenderMapper: Record<Gender, LucidGender> = {
  [Gender.MALE]: LucidGender.MALE,
  [Gender.FEMALE]: LucidGender.FEMALE
}

export type GenderSubset = MultiselectSubset<Gender>
