import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum FinancialProduct {
  CREDIT_CARD = 'Credit Card',
  CHECKING_ACCOUNT = 'Checking account',
  SAVINGS_ACCOUNT = 'Savings account',
  PERSONAL_LOAN = 'Personal loan',
  MORTGAGE = 'Mortgage',
  NA = 'None of the above'
}

export const FinancialProducttMetadata: MultiselectDemographicFilterMetadata<FinancialProduct> = {
  title: 'Financial Product',
  question: 'Which of the following financial products do you own?',
  options: Object.values(FinancialProduct)
}

// question ID is 25640
export enum LucidFinancialProduct {
  CREDIT_CARD = '1',
  CHECKING_ACCOUNT = '2',
  SAVINGS_ACCOUNT = '3',
  PERSONAL_LOAN = '4',
  MORTGAGE = '5',
  NA = '-3105'
}

export const FinancialProductMapper: Record<FinancialProduct, LucidFinancialProduct> = {
  [FinancialProduct.CREDIT_CARD]: LucidFinancialProduct.CREDIT_CARD,
  [FinancialProduct.CHECKING_ACCOUNT]: LucidFinancialProduct.CHECKING_ACCOUNT,
  [FinancialProduct.SAVINGS_ACCOUNT]: LucidFinancialProduct.SAVINGS_ACCOUNT,
  [FinancialProduct.PERSONAL_LOAN]: LucidFinancialProduct.PERSONAL_LOAN,
  [FinancialProduct.MORTGAGE]: LucidFinancialProduct.MORTGAGE,
  [FinancialProduct.NA]: LucidFinancialProduct.NA
}
