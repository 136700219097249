import type { IRule, IValueToCompare } from '@feedbackloop/types'
import { ReefQuestionType } from '@feedbackloop/types-graphql'
import { RuleEngineError } from './rule-engine-validators'
import { intersection } from 'lodash'
import type { RRQuestion, SubmittedAnswer } from '@feedbackloop/shared'

export interface IQuestionAnswerPair { question: RRQuestion, answer: SubmittedAnswer | undefined}

export const getSubmittedAnswerValueByQuestionType = (questionType: RRQuestion['type'], answer: { value: any } | undefined) => {
  switch (questionType) {
    case ReefQuestionType.SingleSelect:
      return answer?.value

    case ReefQuestionType.MultiSelect:
      return answer?.value

    default:
      throw new RuleEngineError(`Unsupported question type "${questionType}"`)
  }
}

export const valueToCompareProvider = (rule: IRule) => {
  const isArray = Array.isArray(rule?.valueToCompare)

  if (!isArray) return (rule?.valueToCompare as IValueToCompare)?.value

  const mappedValues = (rule.valueToCompare as IValueToCompare[]).map(x => x.value)
  const results = toArrayString(mappedValues)
  if (!results?.length) throw new RuleEngineError(`No Options provided: ${results.toString()}`)
  return results
}

/**
 * NOTE: For this part to work with overlapping values of different types this converts
 * all values in the array to a string. In the case of null / undefined it wraps the name in
 * quotes.
 *
 * NOTE: Due to the previous note the same thing holds true for booleans! So there is less
 * concern with overlap with 1/0 => true/false
 */
export const getIntersection = (answer: any, ruleValue: any): any[] => {
  const answerArray = toArrayString(answer)
  const ruleValueArray = toArrayString(ruleValue)

  const result = intersection(answerArray, ruleValueArray)
  return result?.filter((x: any) => x !== '')
}

export const toArrayString = (value: any): any[] => {
  const array = Array.isArray(value) ? value : [value]
  return array
    ?.map(x => toCompareString(x))
    ?.filter(x => x !== '')
}

export const toCompareString = (x: any) => String(x).toLocaleLowerCase().trim()

export const toNumber = (value: any): number => {
  /**
   * NOTE: The Rule engine will catch the rule.valueToCompare being null/undefined/''
   * so we should be able to allow that through here for the answer value
   */

  if (
    value === null ||
    value === undefined ||
    typeof value === 'number'
  ) return value
  const converted = Number(value)

  if (
    typeof value === 'boolean' ||
    Number.isNaN(converted) ||
    !Number.isFinite(converted)
  ) {
    throw new RuleEngineError(`value was NaN: ${value}`)
  }

  return converted
}

export const isNullOrEmpty = (val: any) => {
  return (val === null || val === undefined || val === '')
}
