export enum InvalidAnswerReason {
  NoAnswer = 'No answer',
  IncompleteAnswer = 'Incomplete answer',
  MissingOther = 'Missing other',
  MissingExplain = 'Missing explain',
  OutOfRange = 'Out of range',
  TooFewSelections = 'Too few selections',
  TooManySelections = 'Too many selections',
  UnviewedAsset = 'Unviewed asset'
}
