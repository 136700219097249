import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum Hispanic {
  ARGENTINA = 'Yes, Argentina',
  COLOMBIA = 'Yes, Colombia',
  CUBAN = 'Yes, Cuban',
  ECUADOR = 'Yes, Ecuador',
  EL_SALVADORE = 'Yes, El Salvadora',
  GUATEMALA = 'Yes, Guatemala',
  MEXICAN_OR_CHICANO = 'Yes, Mexican, Mexican American, Chicano',
  NICARAGUA = 'Yes, Nicaragua',
  NO = 'No, not of Hispanic, Latino, or Spanish origin',
  OTHER = 'Yes, Other Country',
  PANAMA = 'Yes, Panama',
  PERU = 'Yes, Peru',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer',
  PUERTO_RICO = 'Yes, Puerto Rican',
  SPAIN = 'Yes, Spain',
  VENEZUELA = 'Yes, Venezuela'
}

export const HispanicMetadata: MultiselectDemographicFilterMetadata<Hispanic> = {
  title: 'Hispanic',
  question: 'Are you of Hispanic, Latino, or Spanish origin?',
  options: Object.values(Hispanic)
}

// lucid question ID is 47
export enum LucidHispanic {
  NO = '1',
  MEXICAN_OR_CHICANO = '2',
  CUBAN = '3',
  ARGENTINA = '4',
  COLOMBIA = '5',
  ECUADOR = '6',
  EL_SALVADORE = '7',
  GUATEMALA = '8',
  NICARAGUA = '9',
  PANAMA = '10',
  PERU = '11',
  SPAIN = '12',
  VENEZUELA = '13',
  OTHER = '14',
  PREFER_NOT_TO_ANSWER = '15',
  PUERTO_RICO = '16'
}

export const HispanicMapper: Record<Hispanic, LucidHispanic> = {
  [Hispanic.ARGENTINA]: LucidHispanic.ARGENTINA,
  [Hispanic.COLOMBIA]: LucidHispanic.COLOMBIA,
  [Hispanic.CUBAN]: LucidHispanic.CUBAN,
  [Hispanic.ECUADOR]: LucidHispanic.ECUADOR,
  [Hispanic.EL_SALVADORE]: LucidHispanic.EL_SALVADORE,
  [Hispanic.GUATEMALA]: LucidHispanic.GUATEMALA,
  [Hispanic.MEXICAN_OR_CHICANO]: LucidHispanic.MEXICAN_OR_CHICANO,
  [Hispanic.NICARAGUA]: LucidHispanic.NICARAGUA,
  [Hispanic.PANAMA]: LucidHispanic.PANAMA,
  [Hispanic.PERU]: LucidHispanic.PERU,
  [Hispanic.PUERTO_RICO]: LucidHispanic.PUERTO_RICO,
  [Hispanic.SPAIN]: LucidHispanic.SPAIN,
  [Hispanic.VENEZUELA]: LucidHispanic.VENEZUELA,
  [Hispanic.NO]: LucidHispanic.NO,
  [Hispanic.OTHER]: LucidHispanic.OTHER,
  [Hispanic.PREFER_NOT_TO_ANSWER]: LucidHispanic.PREFER_NOT_TO_ANSWER
}
