/* istanbul ignore file */
import LogRocket from 'logrocket'
import {
  VUE_APP_LOGROCKET_PROJECT,
  VUE_APP_ATTRIBUTION_ORIGIN,
  VUE_APP_GOOGLE_ANALYTICS_PROJECT,
  NODE_ENV
} from '@/config'
import LogHandler from '@/utilities/LogHandler'
import store from '@/store'

declare global {
  interface Window {
    dataLayer: any
  }
}

const logrocketEnvironments = ['staging', 'production']
const gaEnvironments = ['production']

function initializeLogRocket (logrocketProject: string) {
  LogRocket.init(logrocketProject,
    {
      release: VUE_APP_ATTRIBUTION_ORIGIN,
      console: {
        shouldAggregateConsoleErrors: true
      },
      network: {
        requestSanitizer: (request) => {
          if (request.headers.Authorization) {
            request.headers.Authorization = 'redacted'
          }

          if (request.headers.authorization) {
            request.headers.authorization = 'redacted'
          }

          return request
        }
      }
    })

  LogRocketLogHandler.init()
}

function injectGoogleAnalytics () {
  const gtmScript = document.createElement('script') as HTMLScriptElement
  gtmScript.async = true
  gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${VUE_APP_GOOGLE_ANALYTICS_PROJECT}`
  document.getElementsByTagName('head')[0].appendChild(gtmScript)
  window.dataLayer = window.dataLayer || []

  function gtag () {
    window.dataLayer.push(arguments)
  }
  // @ts-ignore
  gtag('js', new Date())
  // @ts-ignore
  gtag('config', VUE_APP_GOOGLE_ANALYTICS_PROJECT)
}

export function identifyLogRocketUser (RID: string, PID?: string, OID?: string) {
  const traits: Record<string, string> = {}
  if (PID) traits[PID] = PID
  if (OID) traits[OID] = OID
  LogHandler.logger.log('User identified', RID, PID, OID)
  LogRocket.identify(RID, traits)
}

export function initializeAnalytics () {
  // Turn off all analytics in dev mode
  if (store.state.devMode) return

  if (VUE_APP_LOGROCKET_PROJECT !== 'false' && VUE_APP_LOGROCKET_PROJECT && logrocketEnvironments.includes(NODE_ENV)) {
    initializeLogRocket(VUE_APP_LOGROCKET_PROJECT)
  } else {
    LogHandler.init()
  }

  if (VUE_APP_GOOGLE_ANALYTICS_PROJECT !== 'false' && VUE_APP_GOOGLE_ANALYTICS_PROJECT && gaEnvironments.includes(NODE_ENV)) {
    injectGoogleAnalytics()
  }
}

export function getLogrocketSessionUrl (wantsUrl: Function) {
  if (wantsUrl) {
    if (VUE_APP_LOGROCKET_PROJECT !== 'false' && logrocketEnvironments.includes(NODE_ENV)) {
      LogRocket.getSessionURL((sessionUrl) => wantsUrl(sessionUrl))
    } else {
      wantsUrl(false)
    }
  }
}

export function analyticsTrack (tracked: string) {
  LogRocket.track(tracked)
}
/**
 * ## Loghandler for logrocket
 * loghandlers should always set {@link LogHandler._logger} otherwise they won't be used in the static context */
class LogRocketLogHandler extends LogHandler {
  public static init () {
    LogHandler._logger = new LogRocketLogHandler()
  }

  error (message: string, ...args: any[]): void { LogRocket.error(message, args) }
  warn (message: string, ...args: any[]): void { LogRocket.warn(message, args) }
  log (message: string, ...args: any[]): void { LogRocket.log(message, args) }
  info (message: string, ...args: any[]): void { LogRocket.info(message, args) }
  debug (message: string, ...args: any[]): void { LogRocket.debug(message, args) }
}
