import { AttributionEventType } from '@feedbackloop/shared'

export enum UserInfo {
  Failure = 'failure',
  HerringFail = 'herring_fail',
  QualityTermination = 'quality_termination',
  NotQualify = 'not_qualify',
  Success = 'success',
  RedirectedToFF = 'redirected_to_FF',
  GP = 'gp',
  Speeder = 'speeder',
  Paster = 'paster',
  BlacklistedIP = 'blacklist_ip',
  HighThreat = 'sc_high_threat_potential',
  Spam = 'spam',
  Plagiarized = 'plagiarized',
  NotEnoughAnswers = 'not_enough_answers',
  OptOutMobile = 'opt_out_mobile'
}

export enum AttributionDataType {
  ActiveBrowser = 'active_browser',
  Answer = 'answer',
  Asset = 'asset',
  AudienceId = 'audience_id',
  BrowserEngine = 'browser_engine',
  BrowserVersion = 'browser_version',
  CompletedTests = 'completed_tests',
  CustomScreenerQuestion = 'custom_screener_question',
  Demographic = 'demographic',
  Exception = 'exception',
  FailureAudience = 'failure_audience',
  FailureReason = 'failure_reason',
  HostSystem = 'host_system',
  ImageStimuli = 'image_stimuli',
  InTestHerring = 'in_test_herring',
  IsInApp = 'is_in_app',
  IsMobile = 'is_mobile',
  LandingUrl = 'landing_uri',
  LoadedQuestions = 'loaded_questions',
  LogRocketSessionUrl = 'logrocket_session_url',
  Marketplace = 'marketplace',
  OrderId = 'order_id',
  OrderQuota = 'order_quota',
  OSVersion = 'os_version',
  QuestionId = 'question_id',
  QuestionType = 'question_type',
  RedirectStatus = 'redirect_status',
  RedirectTarget = 'redirect_target',
  RespondentId = 'respondent_id',
  ExpectedResponses = 'expected_responses',
  ExpectedSurveys = 'expected_surveys',
  RetryEvent = 'retry_event',
  SessionId = 'session_id',
  SystemCPU = 'system_cpu',
  SystemDevice = 'system_device',
  TagsApplied = 'tags_applied',
  TapId = 'tap_id',
  UrlStimuli = 'url_stimuli',
  UserAgent = 'user_agent',
  RespondentSurvey = 'respondent_survey',
  RespondentSession = 'respondent_session',
  SurveyClientId = 'survey_client_id',
  SessionClientId = 'session_client_id',
  Origin = 'origin'
}

export enum AttributionUrlStimuliType {
  YoutubeStimuli = 'youtube'
}

export const UserRedFlags: UserInfo[] = [
  UserInfo.OptOutMobile,
  UserInfo.NotEnoughAnswers,
  UserInfo.Spam,
  UserInfo.HighThreat,
  UserInfo.BlacklistedIP,
  UserInfo.HerringFail,
  UserInfo.Speeder,
  UserInfo.Paster,
  UserInfo.Plagiarized
]

/**
 * ## SafeData
 * Determines which values are "sticky" and last longer than a single event.
 */
export const SafeData = [
  AttributionDataType.AudienceId,
  AttributionDataType.Exception,
  AttributionDataType.Marketplace,
  AttributionDataType.OrderId,
  AttributionDataType.RespondentId,
  AttributionDataType.SessionId,
  AttributionDataType.TapId,
  AttributionDataType.LandingUrl,
  AttributionDataType.IsMobile,
  AttributionDataType.RespondentSession,
  AttributionDataType.RespondentSurvey,
  AttributionDataType.SurveyClientId,
  AttributionDataType.SessionClientId,
  AttributionDataType.LogRocketSessionUrl,
  AttributionDataType.Origin,
  AttributionDataType.QuestionId,
  AttributionDataType.ExpectedResponses,
  AttributionDataType.ExpectedSurveys
]

export const SafeEvents = [
  AttributionEventType.StimuliLoaded,
  AttributionEventType.FailedInTestHerring
]
