import type { IAttributionEvent } from '@feedbackloop/shared'
import { AttributionEventType } from '@feedbackloop/shared'
import type { IAttributionTarget } from './targets/AttributionTarget'
import AttributionEvent from './core/AttributionEvent'
import type { AttributionUrlStimuliType } from './core/enums'
import { AttributionDataType, SafeData, SafeEvents } from './core/enums'
import type LogRocket from 'logrocket'
import LogRocketTarget from './targets/LogRocketTarget'
import type MessageBuffer from '@/utilities/MessageBuffer'
import AttributionBufferTarget from '@/api/attribution/targets/AttributionBufferTarget'
import AttributionLogTarget from '@/api/attribution/targets/AttributionLogTarget'
import type { ILog } from '@feedbackloop/types/src/ILog'
import { UAParser } from 'ua-parser-js'

export interface IAttribute {
  raiseAttributionType (aEventType: AttributionEventType): void

  raiseAttributionEvent (aEvent: AttributionEvent): void

  assign (...data: [AttributionDataType | AttributionUrlStimuliType | string, any][]): this

  withBuffer: (buffer: MessageBuffer<IAttributionEvent>) => this

  withLogRocket: (logrocket: typeof LogRocket) => this

  assignFromUserAgent(userAgent: string): void
}

class Attribution implements IAttribute {
  userData: Map<AttributionDataType|AttributionUrlStimuliType|string, string> = new Map<AttributionDataType, string>()
  safeData: Map<AttributionDataType|AttributionUrlStimuliType|string, string> = new Map<AttributionDataType|AttributionUrlStimuliType|string, string>()
  private logger: ILog & IAttributionTarget
  private targets: Array<IAttributionTarget> = []
  private eventSet: Set<string> = new Set<string>()

  constructor () {
    const consoleTarget = new AttributionLogTarget()
    this.assign([AttributionDataType.ExpectedResponses, 0])
    this.assign([AttributionDataType.ExpectedSurveys, 0])
    this.addTarget(consoleTarget)
    this.logger = consoleTarget
  }

  assignFromUserAgent (userAgent: string): void {
    const parser = new UAParser(userAgent).getResult()

    this.assign([AttributionDataType.UserAgent, parser.ua])
    this.assign([AttributionDataType.ActiveBrowser, JSON.stringify(parser.browser)])
    this.assign([AttributionDataType.SystemCPU, JSON.stringify(parser.cpu)])
    this.assign([AttributionDataType.SystemDevice, JSON.stringify(parser.device)])
    this.assign([AttributionDataType.BrowserEngine, JSON.stringify(parser.engine)])
    this.assign([AttributionDataType.HostSystem, JSON.stringify(parser.os)])
    this.assign([AttributionDataType.BrowserVersion, JSON.stringify(parser.browser.version)])
  }

  raiseAttributionType (aEventType: AttributionEventType) {
    this.raiseAttributionEvent(new AttributionEvent(aEventType))
  }

  raiseAttributionEvent (aEvent: AttributionEvent) {
    this.mapAttributionEvent(aEvent)

    if (!SafeEvents.includes(aEvent.eventType)) {
      this.expireUserData()
    }

    const eventHash: string = aEvent.getHash()
    if (this.eventSet.has(eventHash)) {
      this.logger.info(`prevented duplicate message ${aEvent.getHash()}`)
      return
    }

    // This is pretty much all the logic tide needs to start recording survey & response counts
    if (aEvent.eventType === AttributionEventType.StartNewTest) {
      this.assign([AttributionDataType.ExpectedResponses, 0])
    } else if (aEvent.eventType === AttributionEventType.CompletedTest) {
      const currentSurveys = parseInt(this.safeData.get(AttributionDataType.ExpectedSurveys) || '0') + 1
      this.assign([AttributionDataType.ExpectedSurveys, currentSurveys])
    } else if (aEvent.eventType === AttributionEventType.AnsweredQuestion) {
      const currentResponses = parseInt(this.safeData.get(AttributionDataType.ExpectedResponses) || '0') + 1
      this.assign([AttributionDataType.ExpectedResponses, currentResponses])
    }

    this.eventSet.add(eventHash)

    this.targets.forEach(target => {
      target.inform(aEvent)
    })
  }

  public mapAttributionEvent (aEvent: AttributionEvent) {
    if (this.safeData.get(AttributionDataType.RespondentId) == null) {
      this.safeData.set(AttributionDataType.RespondentId, '')
    }
    aEvent.map(this.safeData)
    aEvent.map(this.userData)
    return aEvent
  }

  assign (...data: [AttributionDataType|AttributionUrlStimuliType|string, any][]) {
    for (let i = 0; i < data.length; i++) {
      const item: [AttributionDataType | AttributionUrlStimuliType|string, any] = data[i]
      if (typeof item[1] !== 'undefined' && item[1] !== '') {
        if (SafeData.includes(<AttributionDataType>item[0])) {
          this.safeData.set(item[0], item[1] == null ? '' : item[1])
        } else {
          this.userData.set(item[0], item[1] == null ? '' : item[1])
        }

        this.targets.forEach(target => target.assign(item[0], item[1]))
      }
    }

    return this
  }

  public addTarget (target: IAttributionTarget) {
    if (!this.targets.includes(target)) {
      this.targets.push(target)
    }
    return this
  }

  public withBuffer = (buffer: MessageBuffer<IAttributionEvent>) => {
    const bufferTarget: AttributionBufferTarget = new AttributionBufferTarget(buffer)
    this.addTarget(bufferTarget)
    return this
  }

  public withLogRocket = () => {
    const logRocketTarget = new LogRocketTarget()
    this.addTarget(logRocketTarget)
    this.logger = logRocketTarget
    return this
  }

  private expireUserData () {
    this.userData.clear()
  }
}

export const Attributor = new Attribution()
