import type { BalancedSurveyPermutation, RespondentSurvey, QuestionLocation, ClientSurvey } from '@/types'
import LogHandler from '@/utilities/LogHandler'
import { ReefQuestionType } from '@feedbackloop/types-graphql'
import store from '@/store'
import api from '@/api'
import type { TideQuestion } from '@feedbackloop/shared'

export const MOBILE_BREAKPOINT = 768

export const questionsUsingPermutationBalancing = [
  ReefQuestionType.DirectComparison.toString(),
  ReefQuestionType.SingleSelect.toString(),
  ReefQuestionType.Matrix.toString(),
  ReefQuestionType.Rank.toString(),
  ReefQuestionType.MultiSelect.toString()
]

export function fixLink (url: string | undefined) {
  if (url) return url.indexOf('http') === -1 ? 'https://' + url.trim() : url.trim()
}

export function getQuestionLocationInRespondentSurvey (questionId: string, survey: RespondentSurvey): QuestionLocation | null {
  // Iterate audience groups
  for (let i = 0; i < survey.audiences.length; i++) {
    const audience = survey.audiences[i]

    if (audience.screeners) {
      // Iterate screener questions
      for (let j = 0; j < audience.screeners.questions.length; j++) {
        const question = audience.screeners.questions[j]

        // NOTE: Tried to resolve this type, nothing seems to work... they are all slightly off.... =/
        if ((question as any).id === questionId) {
          return {
            audienceGroupIndex: i,
            isScreener: true,
            questionIndex: j
          }
        }
      }
    }

    // Iterate audience group surveys
    for (let k = 0; k < audience.surveys.length; k++) {
      const survey = audience.surveys[k]

      // Iterate survey questions
      for (let l = 0; l < survey.questions.length; l++) {
        const question = survey.questions[l]

        if (question.id === questionId) {
          return {
            audienceGroupIndex: i,
            isScreener: false,
            mainSurveyIndex: k,
            questionIndex: l
          }
        }
      }
    }
  }

  return null
}

export function getSuccessTag (audienceId: string) {
  return `SUCCESS_${audienceId}`
}

export const DESKTOP_BREAKPOINT = 1024

export async function doBalancedRandomization (survey: ClientSurvey, gotPermutationCallback: (payload: BalancedSurveyPermutation) => Promise<void>) {
  const balancedRotationQuestions = survey.questions.filter((q) => questionsUsingPermutationBalancing.includes(q.type))
  for (const question of balancedRotationQuestions) {
    const questionId = (question.type === ReefQuestionType.DirectComparison ? '' : question.id)
    const identifier = `${survey.id}${questionId}`

    // always show current user same permutations they've already seen.
    if (store.state.survey.permutations.has(identifier)) continue

    const permutationResult = await api.getPermutationCounter(survey.id, questionId)
      .then((permuteResult: number) => {
        LogHandler.logger.log(`permutation result for ${identifier}: ${permuteResult}`)
        return ({ identifier, permutation: permuteResult })
      },
      () => {
        const permuteResult: number = Math.random() * (question?.answers?.length || 0)
        LogHandler.logger.error(`There was an issue communicating with kraken for permutations, falling back to javascript random for ${survey.id}: ${permuteResult}}`)
        return { identifier, permutation: permuteResult }
      })
    await gotPermutationCallback(permutationResult)
  }
}

/**
 * Get the exclusive answers for the question. This includes questions with "None of the above" in text
 * or questions that have been marked as exclusive.
 */
export function findExclusiveOptions (question: TideQuestion, naOptText: string): string[] {
  return question.answers.filter(answer => {
    // Filter out exclusive / None of the above
    const label = answer.label ? answer.label : answer.text
    return label === naOptText || answer.exclusive
  })
    .map(answer => answer.label ? answer.label : answer.text) // Return the answer text
}
