declare global {
  const config: any
}

function getEnvVariableOrDefault (name: string, fallback: string): string {
  // eslint-disable-next-line no-undef
  return ((config as any)[name] || process.env[name]) ?? fallback
}

export const NODE_ENV = getEnvVariableOrDefault('NODE_ENV', 'test')

// TODO: Once the shared folder is available for use, move requireEnvVariable and getEnvVariable there. https://app.clubhouse.io/alpha-hq/story/958/support-shared-typescript-packages-in-ocean-monorepo
function requireEnvVariable (name: string): string {
  // eslint-disable-next-line no-undef
  const value = (config as any)[name] || process.env[name]
  if (!value) {
    if (NODE_ENV === 'test') return 'testUrl'
    throw new Error(`Required environment variable ${name} was not provided.`)
  }

  return value
}
export const VUE_APP_CHANNEL_URL = requireEnvVariable('VUE_APP_CHANNEL_URL')
export const VUE_APP_SAMPLECHAIN_KEY = requireEnvVariable('VUE_APP_SAMPLECHAIN_KEY')
export const VUE_APP_LOGROCKET_PROJECT = requireEnvVariable('VUE_APP_LOGROCKET_PROJECT')
export const VUE_APP_GOOGLE_ANALYTICS_PROJECT = requireEnvVariable('VUE_APP_GOOGLE_ANALYTICS_PROJECT')
export const VUE_APP_REEF_APP_URL = requireEnvVariable('VUE_APP_REEF_APP_URL')
export const VUE_APP_ATTRIBUTION_ORIGIN = getEnvVariableOrDefault('VUE_APP_ATTRIBUTION_ORIGIN', 'tide0.1')
export const VUE_APP_ACTIVE_MEASURE_URL = getEnvVariableOrDefault('VUE_APP_ACTIVE_MEASURE_URL', 'https://go.activemeasure.com')
// TODO: ADD THIS EVERYWHERE
export const VUE_APP_KRAKEN_URL = getEnvVariableOrDefault('VUE_APP_KRAKEN_URL', 'https://kraken-prod.herokuapp.com/')
export const VUE_APP_MUX_ENV_KEY = requireEnvVariable('VUE_APP_MUX_ENV_KEY')
