import type { TideResponse } from '@feedbackloop/shared'
import { hash } from '@feedbackloop/shared'
import { FFQuestionType } from '@feedbackloop/types'
import { ReefQuestionType } from '@feedbackloop/types-graphql'
import { v4 as uuidv4 } from 'uuid'

const ResponseBase = {
  testId: '128',
  workspaceId: '5bb414507beaa900047a134a',
  questionId: '5efb8a0d60b8890004318931_1',
  respondentSessionId: uuidv4(),
  createdAt: new Date()
}

export const Description: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.Description
}

export const SingleSelect: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.SingleSelect,
  textAnswer: 'option 2'
}

export const SingleSelectOther: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.SingleSelect,
  otherAnswer: 'my own answer'
}

export const MultiSelect: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.MultiSelect,
  textArrayAnswer: ['option 1', 'option 3', 'option 4']
}

export const Rate: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.RateOnly,
  textAnswer: '4'
}

export const RateAndExplain: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.Rate,
  textAnswer: '3',
  explainAnswer: 'Fake explain answer'
}

export const Matrix: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.Matrix,
  textMapAnswer: {
    [hash('answer choice 1')]: 1,
    [hash('answer choice 2')]: 2,
    [hash('answer choice 3')]: 3
  }
}

export const Textarea: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.TextArea,
  textAnswer: 'Fake response'
}

export const Rank: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.Rank,
  textArrayAnswer: ['answer choice 1', 'answer choice 2', 'answer choice 3']
}

export const Numeric: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.Number,
  textAnswer: '3'
}

export const Variant: TideResponse = {
  ...ResponseBase,
  questionType: ReefQuestionType.Variant,
  textAnswer: 'q_1_var_3'
}

export const Task: TideResponse = {
  ...ResponseBase,
  questionType: FFQuestionType.Task
}
