import { B2BDecisionMaker, B2BDecisionMakerMapper } from '../filters/b2bDecisionMaker'
import { BigPurchase, BigPurchaseMapper } from '../filters/bigPurchase'
import { CarUsage, CarUsageMapper } from '../filters/carUsage'
import { CompanyDepartment, CompanyDepartmentMapper } from '../filters/companyDepartment'
import { DemographicQuestion, DemographicQuestionAnswer } from './demographics'
import { Education, EducationMapper } from '../filters/education'
import { EmployeeCount, EmployeeCountMapper } from '../filters/employeeCount'
import { Employment, EmploymentMapper } from '../filters/employment'
import { Ethnicity, EthnicityMapper } from '../filters/ethnicity'
import { FinancialDecisionMaker, FinancialDecisionMakerMapper } from '../filters/financialDecisionMaker'
import { FinancialProduct, FinancialProductMapper } from '../filters/financialProduct'
import { Gender, GenderMapper } from '../filters/gender'
import { HHIUS, HHIUSMapper } from '../filters/hhius'
import { HealthInsurance, HealthInsuranceMapper } from '../filters/healthInsurance'
import { Hispanic, HispanicMapper } from '../filters/hispanic'
import { HouseholdType, HouseholdTypeMapper } from '../filters/householdType'
import { Industry, IndustryMapper } from '../filters/industry'
import { InvestableAssets, InvestableAssetsMapper } from '../filters/investableAssets'
import { JobTitle, JobTitleMapper } from '../filters/jobTitle'
import { LoanType, LoanTypeMapper } from '../filters/loanType'
import { MobileDevice, MobileDeviceMapper } from '../filters/mobileDevice'
import { ParentalStatus, ParentalStatusMapper } from '../filters/parentalStatus'
import { Region, RegionMapper } from '../filters/region'
import { RelationshipStatus, RelationshipStatusMapper } from '../filters/relationshipStatus'
import { State, StateMapper } from '../filters/state'
import { DemographicFilter } from '../demographicFilter'
import { DemographicFilterValues } from '../filterValues'
import { LucidPrecode } from '../lucid/types/precode'
import { DisqoPrecode } from '../disqo'
import { InsuranceType, InsuranceTypeMapper } from '../filters/insuranceType'

const ageDemographicQuestion: DemographicQuestion = {
  id: DemographicFilter.AGE
}

// there are casts in this function, but we timeboxed trying to figure this out
// there's some type safety in this function from the record's method signature
function getAnswers<Filter extends DemographicFilterValues> (record: Record<Filter, LucidPrecode | DisqoPrecode>, forcedLastAnswer?: Filter): Array<DemographicQuestionAnswer<Filter>> {
  // bonus points to the awesome developer that can figure out how to do this function without casts
  // but it should be safe because of the types that are the mappers from Demographic FBL to Lucid precodes
  const answers = Object.entries(record).map(([text, value]) => ({
    text: text as Filter,
    value: value as LucidPrecode,
    shuffle: false
  }))
  answers.sort((a1: DemographicQuestionAnswer<Filter>, a2: DemographicQuestionAnswer<Filter>): number => {
    if (a1.text === forcedLastAnswer) { return 1 }
    if (a2.text === forcedLastAnswer) { return -1 }
    return Number.parseInt(a1.value, 10) - Number.parseInt(a2.value, 10)
  })
  return answers
}

const genderDemographicQuestion: DemographicQuestion<Gender> = {
  id: DemographicFilter.GENDER,
  answers: getAnswers(GenderMapper)
}

const zipDemographicQuestion: DemographicQuestion = {
  id: DemographicFilter.ZIP_CODE
}

const ethnicityDemographicQuestion: DemographicQuestion<Ethnicity> = {
  id: DemographicFilter.ETHNICITY,
  answers: getAnswers(EthnicityMapper)
}

const hispanicDemographicQuestion: DemographicQuestion<Hispanic> = {
  id: DemographicFilter.HISPANICS,
  answers: getAnswers(HispanicMapper)
}

const standardHHIUSDemographicQuestion: DemographicQuestion<HHIUS> = {
  id: DemographicFilter.HHIUS,
  answers: getAnswers(HHIUSMapper)
}

// // @ts-ignore TODO: use this as an actual required question (eventually)
// const standardHHIDemographicQuestion: DemographicQuestion<RespondentStandardHHI> = {
//   id: DemographicsMappedForLucid.STANDARD_HHI,
//   content: 'How much total combined income do all members of your household earn before taxes?',
//   answers: [{
//     text: RespondentStandardHHI.N_0_TO_14999,
//     shuffle: false,
//     value: '1'
//   },
//   {
//     text: RespondentStandardHHI.N_15000_TO_19999,
//     shuffle: false,
//     value: '2'
//   },
//   {
//     text: RespondentStandardHHI.N_20000_TO_24999,
//     shuffle: false,
//     value: '3'
//   }, {
//     text: RespondentStandardHHI.N_25000_TO_29999,
//     shuffle: false,
//     value: '4'
//   }, {
//     text: RespondentStandardHHI.N_30000_TO_34999,
//     shuffle: false,
//     value: '5'
//   }, {
//     text: RespondentStandardHHI.N_35000_TO_39999,
//     shuffle: false,
//     value: '6'
//   }, {
//     text: RespondentStandardHHI.N_40000_TO_44999,
//     shuffle: false,
//     value: '7'
//   }, {
//     text: RespondentStandardHHI.N_45000_TO_49999,
//     shuffle: false,
//     value: '8'
//   }, {
//     text: RespondentStandardHHI.N_50000_TO_54999,
//     shuffle: false,
//     value: '9'
//   }, {
//     text: RespondentStandardHHI.N_55000_TO_59999,
//     shuffle: false,
//     value: '10'
//   }, {
//     text: RespondentStandardHHI.N_60000_TO_64999,
//     shuffle: false,
//     value: '11'
//   }, {
//     text: RespondentStandardHHI.N_65000_TO_69999,
//     shuffle: false,
//     value: '12'
//   }, {
//     text: RespondentStandardHHI.N_70000_TO_74999,
//     shuffle: false,
//     value: '13'
//   }, {
//     text: RespondentStandardHHI.N_75000_TO_79999,
//     shuffle: false,
//     value: '14'
//   }, {
//     text: RespondentStandardHHI.N_80000_TO_84999,
//     shuffle: false,
//     value: '15'
//   }, {
//     text: RespondentStandardHHI.N_85000_TO_89999,
//     shuffle: false,
//     value: '16'
//   }, {
//     text: RespondentStandardHHI.N_90000_TO_94999,
//     shuffle: false,
//     value: '17'
//   }, {
//     text: RespondentStandardHHI.N_95000_TO_99999,
//     shuffle: false,
//     value: '18'
//   }, {
//     text: RespondentStandardHHI.N_100000_TO_124999,
//     shuffle: false,
//     value: '19'
//   }, {
//     text: RespondentStandardHHI.N_125000_TO_149999,
//     shuffle: false,
//     value: '20'
//   }, {
//     text: RespondentStandardHHI.N_150000_TO_174999,
//     shuffle: false,
//     value: '21'
//   }, {
//     text: RespondentStandardHHI.N_175000_TO_199999,
//     shuffle: false,
//     value: '22'
//   }, {
//     text: RespondentStandardHHI.N_200000_TO_249999,
//     shuffle: false,
//     value: '23'
//   }, {
//     text: RespondentStandardHHI.PREFER_NOT_TO_ANSWER,
//     shuffle: false,
//     value: '-3401'
//   }]
// }
const regionDemographicQuestion: DemographicQuestion<Region> = {
  id: DemographicFilter.REGION,
  answers: getAnswers(RegionMapper)
}

const stateDemographicQuestion: DemographicQuestion<State> = {
  id: DemographicFilter.STATE,
  answers: getAnswers(StateMapper, State.NOT_APPLICABLE)
}

const msIsMobileDemographicQuestion: DemographicQuestion<MobileDevice> = {
  id: DemographicFilter.MOBILE_DEVICE,
  answers: getAnswers(MobileDeviceMapper, MobileDevice.NO)
}

const standardEmploymentDemographicQuestion: DemographicQuestion<Employment> = {
  id: DemographicFilter.EMPLOYMENT,
  answers: getAnswers(EmploymentMapper)
}

const standardEducationDemographicQuestion: DemographicQuestion<Education> = {
  id: DemographicFilter.EDUCATION,
  answers: getAnswers(EducationMapper)
}

// @ts-ignore TODO: use this as a required demographic question eventually
// const standardEducationV2DemographicQuestion: DemographicQuestion<RespondentStandardEducationV2> = {
//   id: DemographicsMappedForLucid.STANDARD_EDUCATION_v2,
//   content: 'What is the highest level of education you have completed?',
//   answers: [{
//     text: RespondentStandardEducationV2.SOME_HIGH_SCHOOL,
//     shuffle: false,
//     value: '1'
//   }, {
//     text: RespondentStandardEducationV2.HIGH_SCHOOL_GRADUATE,
//     shuffle: false,
//     value: '2'
//   }, {
//     text: RespondentStandardEducationV2.VOCATIONAL_TRAINING,
//     shuffle: false,
//     value: '3'
//   }, {
//     text: RespondentStandardEducationV2.SOME_COLLEGE,
//     shuffle: false,
//     value: '4'
//   }, {
//     text: RespondentStandardEducationV2.ASSOCIATE_DEGREE,
//     shuffle: false,
//     value: '5'
//   }, {
//     text: RespondentStandardEducationV2.COLLEGE_DEGREE,
//     shuffle: false,
//     value: '6'
//   }, {
//     text: RespondentStandardEducationV2.MASTERS_DEGREE,
//     shuffle: false,
//     value: '7'
//   }, {
//     text: RespondentStandardEducationV2.DOCTORATE_DEGREE,
//     shuffle: false,
//     value: '8'
//   }, {
//     text: RespondentStandardEducationV2.OTHER,
//     shuffle: false,
//     value: '-3405'
//   }]
// }

const standardIndustryPersonalDemographicQuestion: DemographicQuestion<Industry> = {
  id: DemographicFilter.INDUSTRY,
  answers: getAnswers(IndustryMapper).filter(({ text }) => text !== Industry.MARKETING_SALES)
}

const standardInsuranceTypeDemographicQuestion: DemographicQuestion<InsuranceType> = {
  id: DemographicFilter.INSURANCE_TYPE,
  answers: getAnswers(InsuranceTypeMapper)
}

const standardInvestableAssetsDemographicQuestion: DemographicQuestion<InvestableAssets> = {
  id: DemographicFilter.INVESTABLE_ASSETS,
  answers: getAnswers(InvestableAssetsMapper)
}

const standardJobTitleDemographicQuestion: DemographicQuestion<JobTitle> = {
  id: DemographicFilter.JOB_TITLE,
  answers: getAnswers(JobTitleMapper)
}

const standardLoanTypeDemographicQuestion: DemographicQuestion<LoanType> = {
  id: DemographicFilter.LOAN_TYPE,
  answers: getAnswers(LoanTypeMapper)
}

const parentalStatusStandardDemographicQuestion: DemographicQuestion<ParentalStatus> = {
  id: DemographicFilter.PARENTAL_STATUS,
  answers: getAnswers(ParentalStatusMapper)
}

const standardRelationshipDemographicQuestion: DemographicQuestion<RelationshipStatus> = {
  id: DemographicFilter.RELATIONSHIP_STATUS,
  answers: getAnswers(RelationshipStatusMapper)
}

const standardHouseholdTypeDemographicQuestion: DemographicQuestion<HouseholdType> = {
  id: DemographicFilter.HOUSEHOLD_TYPE,
  answers: getAnswers(HouseholdTypeMapper)
}

const standardCompanyDepartmentDemographicQuestion: DemographicQuestion<CompanyDepartment> = {
  id: DemographicFilter.COMPANY_DEPARTMENT,
  answers: getAnswers(CompanyDepartmentMapper)
}

const standardB2BDecisionMakerDemographicQuestion: DemographicQuestion<B2BDecisionMaker> = {
  id: DemographicFilter.B2B_DECISION_MAKER,
  answers: getAnswers(B2BDecisionMakerMapper)
}

const standardNumberOfEmployeesDemographicQuestion: DemographicQuestion<EmployeeCount> = {
  id: DemographicFilter.EMPLOYEE_COUNT,
  answers: getAnswers(EmployeeCountMapper)
}

const bigPurchaseDemographicQuestion: DemographicQuestion<BigPurchase> = {
  id: DemographicFilter.BIG_TICKET_PURCHASES,
  answers: getAnswers(BigPurchaseMapper, BigPurchase.NONE_OF_THE_ABOVE)
}

const standardHealthInsuranceUSQuestion: DemographicQuestion<HealthInsurance> = {
  id: DemographicFilter.HEALTH_INSURANCE,
  answers: getAnswers(HealthInsuranceMapper)
}

const carUsageQuestion: DemographicQuestion<CarUsage> = {
  id: DemographicFilter.CAR,
  answers: getAnswers(CarUsageMapper)
}

const financialDecisionMakerQuestion: DemographicQuestion<FinancialDecisionMaker> = {
  id: DemographicFilter.FINANCIAL_DECISION_MAKER,
  answers: getAnswers(FinancialDecisionMakerMapper)
}

const financialProductQuestion: DemographicQuestion<FinancialProduct> = {
  id: DemographicFilter.FINANCIAL_PRODUCT,
  answers: getAnswers(FinancialProductMapper)
}

export const demographicQuestions: Record<DemographicFilter, DemographicQuestion> = {
  [DemographicFilter.AGE]: ageDemographicQuestion,
  [DemographicFilter.B2B_DECISION_MAKER]: standardB2BDecisionMakerDemographicQuestion,
  [DemographicFilter.BIG_TICKET_PURCHASES]: bigPurchaseDemographicQuestion,
  [DemographicFilter.CAR]: carUsageQuestion,
  [DemographicFilter.COMPANY_DEPARTMENT]: standardCompanyDepartmentDemographicQuestion,
  [DemographicFilter.EDUCATION]: standardEducationDemographicQuestion,
  [DemographicFilter.EMPLOYEE_COUNT]: standardNumberOfEmployeesDemographicQuestion,
  [DemographicFilter.EMPLOYMENT]: standardEmploymentDemographicQuestion,
  [DemographicFilter.ETHNICITY]: ethnicityDemographicQuestion,
  [DemographicFilter.FINANCIAL_DECISION_MAKER]: financialDecisionMakerQuestion,
  [DemographicFilter.FINANCIAL_PRODUCT]: financialProductQuestion,
  [DemographicFilter.GENDER]: genderDemographicQuestion,
  [DemographicFilter.HEALTH_INSURANCE]: standardHealthInsuranceUSQuestion,
  [DemographicFilter.HISPANICS]: hispanicDemographicQuestion,
  [DemographicFilter.HHIUS]: standardHHIUSDemographicQuestion,
  [DemographicFilter.HOUSEHOLD_TYPE]: standardHouseholdTypeDemographicQuestion,
  [DemographicFilter.INDUSTRY]: standardIndustryPersonalDemographicQuestion,
  [DemographicFilter.INSURANCE_TYPE]: standardInsuranceTypeDemographicQuestion,
  [DemographicFilter.INVESTABLE_ASSETS]: standardInvestableAssetsDemographicQuestion,
  [DemographicFilter.JOB_TITLE]: standardJobTitleDemographicQuestion,
  [DemographicFilter.LOAN_TYPE]: standardLoanTypeDemographicQuestion,
  [DemographicFilter.MOBILE_DEVICE]: msIsMobileDemographicQuestion,
  [DemographicFilter.PARENTAL_STATUS]: parentalStatusStandardDemographicQuestion,
  [DemographicFilter.REGION]: regionDemographicQuestion,
  [DemographicFilter.RELATIONSHIP_STATUS]: standardRelationshipDemographicQuestion,
  [DemographicFilter.STATE]: stateDemographicQuestion,
  [DemographicFilter.ZIP_CODE]: zipDemographicQuestion
} as const
