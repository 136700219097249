import { RedFlag } from '@/utilities/redFlags'

export enum ProviderCompensationStatus {
  QUALITY_TERMINATION = 'quality_termination',
  FAILURE = 'failure',
  SUCCESS = 'success',
  OVER_QUOTA = 'over_quota'
}

enum CustomNonRedFlag {
  NOT_QUALIFY = 'not_qualify',
  NOT_ENOUGH_ANSWERS = 'not_enough_answers',
  REDIRECTED_TO_FF = 'redirected_to_FF',
  SEEN_BEFORE = 'seen_before'
}

export const CustomCompensationStatus = {
  ...CustomNonRedFlag,
  ...RedFlag
}

export type CustomCompensationStatusType = RedFlag | CustomNonRedFlag

export interface CompensationStatuses {
  provider: ProviderCompensationStatus | null
  custom: CustomCompensationStatusType | null
}
