import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum Industry {
  ACCOUNTING = 'Accounting',
  ADVERTISING = 'Advertising',
  AGRICULTURE_FISHING = 'Agriculture/Fishing',
  ARCHITECTURE = 'Architecture',
  AUTOMOTIVE = 'Automotive',
  AVIATION = 'Aviation',
  BANKING_FINANCIAL = 'Banking/Financial',
  BIO_TECH = 'Bio-Tech',
  BROKERAGE = 'Brokerage',
  CARPENTING_ELECTRICAL_INSTALLATIONS_VVS = 'Carpenting/Electrical installations/VVS',
  CHEMICALS_PLASTICS_RUBBER = 'Chemicals/Plastics/Rubber',
  COMMUNICATIONS_INFORMATION = 'Communications/Information',
  COMPUTER_HARDWARE = 'Computer Hardware',
  COMPUTER_RESELLER_SOFTWARE_HARDWARE = 'Computer Reseller (software/hardware)',
  COMPUTER_SOFTWARE = 'Computer Software',
  CONSTRUCTION = 'Construction',
  CONSULTING = 'Consulting',
  CONSUMER_ELECTRONICS = 'Consumer Electronics',
  CONSUMER_PACKAGED_GOODS = 'Consumer Packaged Goods',
  EDUCATION = 'Education',
  ENERGY_UTILITIES_OIL_AND_GAS = 'Energy/Utilities/Oil and Gas',
  ENGINEERING = 'Engineering',
  ENVIRONMENTAL_SERVICES = 'Environmental Services',
  FASHION_APPAREL = 'Fashion/Apparel',
  FOOD_BEVERAGE = 'Food/Beverage',
  GOVERNMENT_PUBLIC_SECTOR = 'Government/Public Sector',
  HEALTHCARE = 'Healthcare',
  HOSPITALITY_TOURISM = 'Hospitality/Tourism',
  HUMAN_RESOURCES = 'Human Resources',
  INFORMATION_TECHNOLOGY = 'Information Technology/IT',
  INSURANCE = 'Insurance',
  INTERNET = 'Internet',
  LEGAL_LAW = 'Legal/Law',
  MANUFACTURING = 'Manufacturing',
  MARKET_RESEARCH = 'Market Research',
  MARKETING = 'Marketing',
  MARKETING_SALES = 'Marketing/Sales',
  MEDIA_ENTERTAINMENT = 'Media/Entertainment',
  MILITARY = 'Military',
  NON_PROFIT_SOCIAL_SERVICES = 'Non Profit/Social services',
  PERSONAL_SERVICES = 'Personal Services',
  PHARMACEUTICALS = 'Pharmaceuticals',
  PRINTING_PUBLISHINNG = 'Printing Publishing',
  PUBLIC_RELATIONS = 'Public Relations',
  REAL_ESTATE_PROPERTY = 'Real Estate/Property',
  RETAIL_WHOLESALE_TRADE = 'Retail/Wholesale trade',
  SALES = 'Sales',
  SECURITY = 'Security',
  SHIPPING_DISTRIBUTION = 'Shipping/Distribution',
  TELECOMMUNICATIONS = 'Telecommunications',
  TRANSPORTATION = 'Transportation',
  OTHER = 'Other',
  I_DO_NOT_WORK = "I don't work"
}

export const IndustryMetadata: MultiselectDemographicFilterMetadata<Industry> = {
  title: 'Industry',
  question: 'Which of the following best describes the industry that you, personally, work in?',
  options: Object.values(Industry)
}

// lucid question ID is 5729
export enum LucidIndustry {
  ACCOUNTING = '1',
  ADVERTISING = '2',
  AGRICULTURE_FISHING = '3',
  ARCHITECTURE = '4',
  AUTOMOTIVE = '5',
  AVIATION = '6',
  BANKING_FINANCIAL = '7',
  BIO_TECH = '8',
  BROKERAGE = '9',
  CARPENTING_ELECTRICAL_INSTALLATIONS_VVS = '10',
  CHEMICALS_PLASTICS_RUBBER = '11',
  COMMUNICATIONS_INFORMATION = '12',
  COMPUTER_HARDWARE = '13',
  COMPUTER_RESELLER_SOFTWARE_HARDWARE = '14',
  COMPUTER_SOFTWARE = '15',
  CONSTRUCTION = '16',
  CONSULTING = '17',
  CONSUMER_ELECTRONICS = '18',
  CONSUMER_PACKAGED_GOODS = '19',
  EDUCATION = '20',
  ENERGY_UTILITIES_OIL_AND_GAS = '21',
  ENGINEERING = '22',
  ENVIRONMENTAL_SERVICES = '23',
  FASHION_APPAREL = '24',
  FOOD_BEVERAGE = '25',
  GOVERNMENT_PUBLIC_SECTOR = '26',
  HEALTHCARE = '27',
  HOSPITALITY_TOURISM = '28',
  HUMAN_RESOURCES = '29',
  INFORMATION_TECHNOLOGY = '30',
  INSURANCE = '31',
  INTERNET = '32',
  LEGAL_LAW = '33',
  MANUFACTURING = '34',
  MARKET_RESEARCH = '35',
  MARKETING = '52',
  MARKETING_SALES = 'Marketing/Sales',
  MEDIA_ENTERTAINMENT = '37',
  MILITARY = '38',
  NON_PROFIT_SOCIAL_SERVICES = '39',
  PERSONAL_SERVICES = '40',
  PHARMACEUTICALS = '41',
  PRINTING_PUBLISHINNG = '42',
  PUBLIC_RELATIONS = '43',
  REAL_ESTATE_PROPERTY = '44',
  RETAIL_WHOLESALE_TRADE = '45',
  SECURITY = '46',
  SHIPPING_DISTRIBUTION = '47',
  TELECOMMUNICATIONS = '48',
  TRANSPORTATION = '49',
  OTHER = '50',
  I_DO_NOT_WORK = '51',
  SALES = '53'
}

export const IndustryMapper: Record<Industry, LucidIndustry> = {
  [Industry.ACCOUNTING]: LucidIndustry.ACCOUNTING,
  [Industry.ADVERTISING]: LucidIndustry.ADVERTISING,
  [Industry.AGRICULTURE_FISHING]: LucidIndustry.AGRICULTURE_FISHING,
  [Industry.ARCHITECTURE]: LucidIndustry.ARCHITECTURE,
  [Industry.AUTOMOTIVE]: LucidIndustry.AUTOMOTIVE,
  [Industry.AVIATION]: LucidIndustry.AVIATION,
  [Industry.BANKING_FINANCIAL]: LucidIndustry.BANKING_FINANCIAL,
  [Industry.BIO_TECH]: LucidIndustry.BIO_TECH,
  [Industry.BROKERAGE]: LucidIndustry.BROKERAGE,
  [Industry.CARPENTING_ELECTRICAL_INSTALLATIONS_VVS]: LucidIndustry.CARPENTING_ELECTRICAL_INSTALLATIONS_VVS,
  [Industry.CHEMICALS_PLASTICS_RUBBER]: LucidIndustry.CHEMICALS_PLASTICS_RUBBER,
  [Industry.COMMUNICATIONS_INFORMATION]: LucidIndustry.COMMUNICATIONS_INFORMATION,
  [Industry.COMPUTER_HARDWARE]: LucidIndustry.COMPUTER_HARDWARE,
  [Industry.COMPUTER_RESELLER_SOFTWARE_HARDWARE]: LucidIndustry.COMPUTER_RESELLER_SOFTWARE_HARDWARE,
  [Industry.COMPUTER_SOFTWARE]: LucidIndustry.COMPUTER_SOFTWARE,
  [Industry.CONSTRUCTION]: LucidIndustry.CONSTRUCTION,
  [Industry.CONSULTING]: LucidIndustry.CONSULTING,
  [Industry.CONSUMER_ELECTRONICS]: LucidIndustry.CONSUMER_ELECTRONICS,
  [Industry.CONSUMER_PACKAGED_GOODS]: LucidIndustry.CONSUMER_PACKAGED_GOODS,
  [Industry.EDUCATION]: LucidIndustry.EDUCATION,
  [Industry.ENERGY_UTILITIES_OIL_AND_GAS]: LucidIndustry.ENERGY_UTILITIES_OIL_AND_GAS,
  [Industry.ENGINEERING]: LucidIndustry.ENGINEERING,
  [Industry.ENVIRONMENTAL_SERVICES]: LucidIndustry.ENVIRONMENTAL_SERVICES,
  [Industry.FASHION_APPAREL]: LucidIndustry.FASHION_APPAREL,
  [Industry.FOOD_BEVERAGE]: LucidIndustry.FOOD_BEVERAGE,
  [Industry.GOVERNMENT_PUBLIC_SECTOR]: LucidIndustry.GOVERNMENT_PUBLIC_SECTOR,
  [Industry.HEALTHCARE]: LucidIndustry.HEALTHCARE,
  [Industry.HOSPITALITY_TOURISM]: LucidIndustry.HOSPITALITY_TOURISM,
  [Industry.HUMAN_RESOURCES]: LucidIndustry.HUMAN_RESOURCES,
  [Industry.INFORMATION_TECHNOLOGY]: LucidIndustry.INFORMATION_TECHNOLOGY,
  [Industry.INSURANCE]: LucidIndustry.INSURANCE,
  [Industry.INTERNET]: LucidIndustry.INTERNET,
  [Industry.LEGAL_LAW]: LucidIndustry.LEGAL_LAW,
  [Industry.MANUFACTURING]: LucidIndustry.MANUFACTURING,
  [Industry.MARKET_RESEARCH]: LucidIndustry.MARKET_RESEARCH,
  [Industry.MARKETING]: LucidIndustry.MARKETING,
  [Industry.MARKETING_SALES]: LucidIndustry.MARKETING_SALES,
  [Industry.MEDIA_ENTERTAINMENT]: LucidIndustry.MEDIA_ENTERTAINMENT,
  [Industry.MILITARY]: LucidIndustry.MILITARY,
  [Industry.NON_PROFIT_SOCIAL_SERVICES]: LucidIndustry.NON_PROFIT_SOCIAL_SERVICES,
  [Industry.PERSONAL_SERVICES]: LucidIndustry.PERSONAL_SERVICES,
  [Industry.PHARMACEUTICALS]: LucidIndustry.PHARMACEUTICALS,
  [Industry.PRINTING_PUBLISHINNG]: LucidIndustry.PRINTING_PUBLISHINNG,
  [Industry.PUBLIC_RELATIONS]: LucidIndustry.PUBLIC_RELATIONS,
  [Industry.REAL_ESTATE_PROPERTY]: LucidIndustry.REAL_ESTATE_PROPERTY,
  [Industry.RETAIL_WHOLESALE_TRADE]: LucidIndustry.RETAIL_WHOLESALE_TRADE,
  [Industry.SALES]: LucidIndustry.SALES,
  [Industry.SECURITY]: LucidIndustry.SECURITY,
  [Industry.SHIPPING_DISTRIBUTION]: LucidIndustry.SHIPPING_DISTRIBUTION,
  [Industry.TELECOMMUNICATIONS]: LucidIndustry.TELECOMMUNICATIONS,
  [Industry.TRANSPORTATION]: LucidIndustry.TRANSPORTATION,
  [Industry.OTHER]: LucidIndustry.OTHER,
  [Industry.I_DO_NOT_WORK]: LucidIndustry.I_DO_NOT_WORK
}
