import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum InvestableAssets {
  N_LESS_THAN_50000 = 'Less than $50,000',
  N_50000_TO_74999 = '$50,000 - $74,999',
  N_75000_TO_99999 = '$75,000 - $99,999',
  N_100000_TO_149999 = '$100,000 - $149,999',
  N_150000_TO_199999 = '$150,000 - $199,999',
  N_200000_TO_494999 = '$200,000 - $499,999',
  N_500000_TO_999999 = '$500,000 - $999,999',
  N_1000000_OR_MORE = '$1,000,000 or more',
  NONE = 'No investable assets',
  PREFER_NOT_TO_ANSWER = 'Prefer not to say'
}

export enum InvestableAssetsPrecodes {
  N_LESS_THAN_50000 = '1',
  N_50000_TO_74999 = '2',
  N_75000_TO_99999 = '3',
  N_100000_TO_149999 = '4',
  N_150000_TO_199999 = '5',
  N_200000_TO_494999 = '6',
  N_500000_TO_999999 = '7',
  N_1000000_OR_MORE = '8',
  NONE = '9',
  PREFER_NOT_TO_ANSWER = '10',
}

export const InvestableAssetsMetadata: MultiselectDemographicFilterMetadata<InvestableAssets> = {
  title: 'Investable Assets',
  question: 'What are your household investable assets (not including homeownership)?',
  options: Object.values(InvestableAssets)
}

export const InvestableAssetsMapper: Record<InvestableAssets, InvestableAssetsPrecodes> = {
  [InvestableAssets.N_LESS_THAN_50000]: InvestableAssetsPrecodes.N_LESS_THAN_50000,
  [InvestableAssets.N_50000_TO_74999]: InvestableAssetsPrecodes.N_50000_TO_74999,
  [InvestableAssets.N_75000_TO_99999]: InvestableAssetsPrecodes.N_75000_TO_99999,
  [InvestableAssets.N_100000_TO_149999]: InvestableAssetsPrecodes.N_100000_TO_149999,
  [InvestableAssets.N_150000_TO_199999]: InvestableAssetsPrecodes.N_150000_TO_199999,
  [InvestableAssets.N_200000_TO_494999]: InvestableAssetsPrecodes.N_200000_TO_494999,
  [InvestableAssets.N_500000_TO_999999]: InvestableAssetsPrecodes.N_500000_TO_999999,
  [InvestableAssets.N_1000000_OR_MORE]: InvestableAssetsPrecodes.N_1000000_OR_MORE,
  [InvestableAssets.NONE]: InvestableAssetsPrecodes.NONE,
  [InvestableAssets.PREFER_NOT_TO_ANSWER]: InvestableAssetsPrecodes.PREFER_NOT_TO_ANSWER
}
