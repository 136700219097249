import type { TideQueryParamMap } from '@feedbackloop/demographics'
import {
  TideQueryFieldNames,
  ProvidedDemographics,
  decodePanelProvider,
  DemographicFilter,
  LucidMobileDevice
} from '@feedbackloop/demographics'
import type { TideRespondentSession } from '@feedbackloop/shared'
import { getQueryParams } from '@feedbackloop/shared'
// @TODO: We need to move this import to above and use root
import {
  batchDecodeDemographicMap,
  decodeDemographic,
  removeInvalidEncodedDemographics
} from '@feedbackloop/demographics/src/tide/demographicsHelpers'
import { processProviderSpecificDemographics } from '@/utilities/providers'
import type { ILog } from '@feedbackloop/types'

/**
 * WHY: Abstracts provider-specific methods for providing in demographics.
 * @param queryString Raw window.location.search - keep on the ?
 * @param surveyId
 */
export function getPassedInDemographics (queryString: string, surveyId: string): Record<string, string | boolean | number> {
  const queryStringVars: Record<string, string> = getQueryParams(queryString)
  const panelProvider = decodePanelProvider(queryStringVars.provider)
  return processProviderSpecificDemographics(queryStringVars, panelProvider, surveyId)
}

// eslint-disable-next-line camelcase
type DemographicFilterExcluded = DemographicFilter.B2B_DECISION_MAKER
| DemographicFilter.BIG_TICKET_PURCHASES
| DemographicFilter.CAR
| DemographicFilter.COMPANY_DEPARTMENT
| DemographicFilter.EDUCATION
| DemographicFilter.FINANCIAL_DECISION_MAKER
| DemographicFilter.FINANCIAL_PRODUCT
| DemographicFilter.HEALTH_INSURANCE
| DemographicFilter.HHIUS
| DemographicFilter.HOUSEHOLD_TYPE
| DemographicFilter.INSURANCE_TYPE
| DemographicFilter.INVESTABLE_ASSETS
| DemographicFilter.JOB_TITLE
| DemographicFilter.LOAN_TYPE
| DemographicFilter.PARENTAL_STATUS
| DemographicFilter.RELATIONSHIP_STATUS
| DemographicFilter.ZIP_CODE
| ProvidedDemographics.COUNTRY
| ProvidedDemographics.INTERNATIONAL
| ProvidedDemographics.FULCRUM_UID

export const DemographicFilterChanneFieldMap: Record<Exclude<DemographicFilter | ProvidedDemographics, DemographicFilterExcluded>, (keyof TideRespondentSession)> = {
  [DemographicFilter.AGE]: 'age',
  [DemographicFilter.STATE]: 'state',
  // [DemographicFilter.ZIP]: 'zip', // not a column in channel yet
  [DemographicFilter.ETHNICITY]: 'ethnicity',
  [DemographicFilter.HISPANICS]: 'hispanic',
  [DemographicFilter.GENDER]: 'gender',
  // [DemographicFilter.HHIUS]: 'standardHHIUS',
  // [DemographicFilter.STANDARD_HHI]: 'standardHHI',
  [DemographicFilter.MOBILE_DEVICE]: 'msIsMobile',
  [DemographicFilter.EMPLOYMENT]: 'standardEmployment',
  // [DemographicFilter.EDUCATION]: 'standardEducation',
  // [DemographicFilter.STANDARD_EDUCATION_v2]: 'standardEducationv2',
  [DemographicFilter.INDUSTRY]: 'standardIndustryPersonal',
  [DemographicFilter.EMPLOYEE_COUNT]: 'standardNumberOfEmployees',
  // [DemographicFilter.STANDARD_JOB_TITLE]: 'standardJobTitle', // not a column in channel yet
  // [DemographicFilter.Parental_Status_Standard]: 'parentalStatusStandard', // not a column in channel yet
  // [DemographicFilter.STANDARD_RELATIONSHIP]: 'standardRelationship', // not a column in channel yet
  // [DemographicFilter.STANDARD_HOUSEHOLD_TYPE]: 'standardHouseholdType', // not a column in channel yet
  // [DemographicFilter.STANDARD_COMPANY_DEPARTMENT]: 'standardCompanyDepartment', // not a column in channel yet
  // [DemographicFilter.STANDARD_B2B_DECISION_MAKER]: 'standardBusinessDecisionMaker', // not a column in channel yet
  // [DemographicFilter.Big_Ticket_Purchases]: 'bigTicketPurchases', // not a column in channel yet
  // [ProvidedDemographics.COUNTRY]: 'country', // not a column in channel yet
  // [ProvidedDemographics.INTERNATIONAL]: 'international', // not a column in channel yet
  [ProvidedDemographics.RID]: 'supplierRid',
  [ProvidedDemographics.PID]: 'supplierPid',
  [ProvidedDemographics.OID]: 'orderId',
  [ProvidedDemographics.SUPPLIER_ID]: 'supplierId',
  [ProvidedDemographics.SUPPLIER_NAME]: 'supplierName',
  [DemographicFilter.REGION]: 'region',
  [ProvidedDemographics.PROVIDER]: 'respondentSource'
}

export type ChannelDemographics = Pick<TideRespondentSession, 'age' | 'state' | 'gender' | 'standardHHIUS' | 'msIsMobile' | 'standardEmployment' | 'standardEducation' | 'standardIndustryPersonal' | 'standardNumberOfEmployees' | 'supplierPid' | 'supplierRid' | 'orderId' | 'supplierId' | 'supplierName' | 'region' | 'ethnicity' | 'hispanic'>

// /**
//  * WHAT: Lucid demographic code -> human readable value
//  * @param demographic
//  */
// const isMultiSelectDemographic = (demographic: DemographicsRequiringAnswerType) => {
//   return [
//     DemographicsRequiringAnswers.Parental_Status_Standard,
//     DemographicsRequiringAnswers.STANDARD_B2B_DECISION_MAKER,
//     DemographicsRequiringAnswers.Big_Ticket_Purchases
//   ].includes(demographic)
// }

export function decodeDemographicsForChannel (decodedDemographics: TideQueryParamMap<string>): ChannelDemographics {
  // HHI_US needs to be deprecated multiple places
  const channelDemographics: ChannelDemographics = {
    age: parseInt(decodedDemographics.AGE!),
    gender: decodeDemographic(TideQueryFieldNames.GENDER, decodedDemographics.GENDER),
    standardHHIUS: decodeDemographic(TideQueryFieldNames.STANDARD_HHI, decodedDemographics.STANDARD_HHI_US),
    msIsMobile: (decodedDemographics.MS_is_mobile === LucidMobileDevice.YES),
    standardEmployment: decodeDemographic(TideQueryFieldNames.STANDARD_EMPLOYMENT, decodedDemographics.STANDARD_EMPLOYMENT),
    standardEducation: decodeDemographic(TideQueryFieldNames.STANDARD_EDUCATION, decodedDemographics.STANDARD_EDUCATION),
    standardIndustryPersonal: decodeDemographic(TideQueryFieldNames.STANDARD_INDUSTRY_PERSONAL, decodedDemographics.STANDARD_INDUSTRY_PERSONAL),
    standardNumberOfEmployees: decodeDemographic(TideQueryFieldNames.STANDARD_NO_OF_EMPLOYEES, decodedDemographics.STANDARD_NO_OF_EMPLOYEES),
    supplierPid: decodeDemographic(TideQueryFieldNames.PID, decodedDemographics.PID),
    supplierRid: decodeDemographic(TideQueryFieldNames.RID, decodedDemographics.RID),
    orderId: decodeDemographic(TideQueryFieldNames.OID, decodedDemographics.OID),
    supplierId: decodeDemographic(TideQueryFieldNames.SUPPLIER_ID, decodedDemographics.SUPPLIER_ID),
    supplierName: decodeDemographic(TideQueryFieldNames.SUPPLIER_NAME, decodedDemographics.SUPPLIER_NAME),
    region: decodedDemographics.REGION === 'Not Applicable' ? undefined : decodeDemographic(TideQueryFieldNames.REGION, decodedDemographics.REGION),
    state: decodedDemographics.STATE === 'Not Applicable' ? undefined : decodeDemographic(TideQueryFieldNames.STATE, decodedDemographics.STATE),
    ethnicity: decodeDemographic(TideQueryFieldNames.ETHNICITY, decodedDemographics.ETHNICITY),
    hispanic: decodeDemographic(TideQueryFieldNames.HISPANIC, decodedDemographics.HISPANIC)
  } // not sure if I'm a fan of having to use any here...

  if (decodedDemographics.STANDARD_HHI) {
    channelDemographics.standardHHIUS = decodeDemographic(TideQueryFieldNames.STANDARD_HHI, decodedDemographics.STANDARD_HHI)
  }

  if (decodedDemographics.STANDARD_EDUCATION_v2) {
    channelDemographics.standardEducation = decodeDemographic(TideQueryFieldNames.STANDARD_EDUCATION_V2, decodedDemographics.STANDARD_EDUCATION_v2)
  }
  // for (const key of Object.values(TideQueryFieldNames)) {
  //   // const castKey = key as TideQueryFieldName
  //   // need to translate the key over to channel keys
  //   const channelKey = DemographicFilterChanneFieldMap[key]
  //   const value = decodedDemographics[key]
  //   if (!value) continue
  //   if (key === DemographicsRequiringAnswers.AGE) {
  //     channelDemographics[channelKey] = parseInt(value)
  //   } else if (key === DemographicsRequiringAnswers.MS_is_mobile) {
  //     channelDemographics[channelKey] = (value.toLowerCase() === 'true')
  //   } else if (isMultiSelectDemographic(key)) {
  //     // channel does not store multi select demographics yet
  //   } else if (key === DemographicsRequiringAnswers.REGION || key === DemographicsRequiringAnswers.STATE) {
  //     // If we don't have an applicable state, assume we are not in US and we don't want a region
  //     const stateDemographic = channelDemographics[DemographicsRequiringAnswers.STATE]
  //     if (stateDemographic && stateDemographic === 'Not Applicable') {
  //       channelDemographics[DemographicsRequiringAnswers.REGION] = ''
  //     } else {
  //       channelDemographics[channelKey] = getDecodedKey(key, value)
  //     }
  //   } else if (channelKey) {
  //     channelDemographics[channelKey] = getDecodedKey(key, value)
  //   }
  // }
  return channelDemographics
}

export function decodeDemographicsFromQueryString (
  locationSearchParams: string,
  id: string,
  logger: ILog,
  devMode: boolean
) {
  const passedInDemographics = getPassedInDemographics(locationSearchParams, id)
  const validEncodedPassedInDemographics = removeInvalidEncodedDemographics(passedInDemographics, logger, devMode)
  const decodedDemographics = batchDecodeDemographicMap(validEncodedPassedInDemographics)
  return {
    decodedDemographics,
    validEncodedPassedInDemographics
  }
}
