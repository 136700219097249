import type { TideAsset } from '@/types'

export function tideAssetsHaveDifferentContent (assetA: TideAsset, assetB: TideAsset) {
  if (assetA.type !== assetB.type) return true
  else if (assetA.type === 'image' && assetB.type === 'image') {
    if (assetA.url === assetB.url) return false
  } else if (assetA.type === 'video' && assetB.type === 'video') {
    if (assetA.url === assetB.url) return false
  } else if (assetA.type === 'text' && assetB.type === 'text') {
    if (assetA.text === assetB.text) return false
  }
  return true
}
