import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum CarUsage {
  CAR_OWNER = 'Yes, I own a car/cars',
  CAR_ACCESS = 'Yes, I have access to a car/cars',
  CAR_LEASE_COMPANY = 'Yes, I lease/have a company car',
  NO_CAR = 'No, I don\'t have access to a car',
}

export const CarUsageMetadata: MultiselectDemographicFilterMetadata<CarUsage> = {
  title: 'Car Usage',
  question: 'Do you drive a car regularly?',
  options: Object.values(CarUsage)
}

// question ID is 105016
export enum LucidCarUsage {
  CAR_OWNER = '1',
  CAR_ACCESS = '2',
  CAR_LEASE_COMPANY = '3',
  NO_CAR = '4'
}

export const CarUsageMapper: Record<CarUsage, LucidCarUsage> = {
  [CarUsage.CAR_OWNER]: LucidCarUsage.CAR_OWNER,
  [CarUsage.CAR_ACCESS]: LucidCarUsage.CAR_ACCESS,
  [CarUsage.CAR_LEASE_COMPANY]: LucidCarUsage.CAR_LEASE_COMPANY,
  [CarUsage.NO_CAR]: LucidCarUsage.NO_CAR
}
