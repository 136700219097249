// alpha order please

// ultimate goal is to be able to change these keys,
// and lock in the external dependent strings elsewhere
// parental status apparently needs to be that value bc of some hidden dependency somewhere
export enum DemographicFilter {
  AGE = 'AGE',
  B2B_DECISION_MAKER = 'STANDARD_B2B_DECISION_MAKER',
  BIG_TICKET_PURCHASES = 'Big_Ticket_Purchases',
  CAR = 'STANDARD_CAR_USE',
  COMPANY_DEPARTMENT = 'STANDARD_COMPANY_DEPARTMENT',
  EDUCATION = 'STANDARD_EDUCATION',
  EMPLOYEE_COUNT = 'STANDARD_NO_OF_EMPLOYEES',
  EMPLOYMENT = 'STANDARD_EMPLOYMENT',
  ETHNICITY = 'ETHNICITY',
  FINANCIAL_PRODUCT = 'Financial_Product',
  FINANCIAL_DECISION_MAKER = 'FINANCIAL_DECISION_MAKER',
  GENDER = 'GENDER',
  HEALTH_INSURANCE = 'STANDARD_HEALTH_INSURANCE_US',
  HHIUS = 'STANDARD_HHI_US',
  HISPANICS = 'HISPANIC',
  HOUSEHOLD_TYPE = 'STANDARD_HOUSEHOLD_TYPE',
  INDUSTRY = 'STANDARD_INDUSTRY_PERSONAL',
  INSURANCE_TYPE = 'INSURANCE_TYPE',
  INVESTABLE_ASSETS = 'INVESTABLE_ASSETS',
  JOB_TITLE = 'STANDARD_JOB_TITLE',
  LOAN_TYPE = 'STANDARD_LOAN_TYPE',
  MOBILE_DEVICE = 'MS_is_mobile',
  PARENTAL_STATUS = 'Parental_Status_Standard',
  REGION = 'REGION',
  RELATIONSHIP_STATUS = 'STANDARD_RELATIONSHIP',
  STATE = 'STATE',
  ZIP_CODE = 'ZIP'
}

export type AudienceDemographic = {
  key: DemographicFilter
  codes: string[]
}

export type Audience = {
  demographics: AudienceDemographic[]
}

export type BalancedDemographicQualification = {
  demographicQualifications: AudienceDemographic[]
  samplePercentage: number
  description: string
}
