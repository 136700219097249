import type { TideAsset } from '@/types'
import { AssetType } from '@/types'
import type { RRQuestion } from '@feedbackloop/shared'
import type { ILog } from '@feedbackloop/types/src/ILog'

export function parseTideAssetFromRRQuestion (question: RRQuestion, logger: ILog, variants?: Record<string, string>): TideAsset | null {
  let imageAssetUrl: string | undefined
  let videoAssetUrl: string | undefined
  let videoAssetTitle: string | undefined
  let textAssetContent: string | undefined

  if (question.type === 'variant') {
    if (!variants) {
      logger.error(`Cannot find asset url for variant question ${question.id} because rrSurvey meta variants data was not provided`)
      return null
    }

    const variantTag = variants[question.id]
    const applicableVariant = question.answers!.find(answer => answer.var_tag === variantTag)

    if (typeof applicableVariant?.image === 'string' && applicableVariant.image.length > 0) {
      imageAssetUrl = applicableVariant.image
    } else if (typeof applicableVariant?.url?.url === 'string' && applicableVariant.url.url.length > 0) {
      videoAssetUrl = applicableVariant.url.url
      videoAssetTitle = applicableVariant.url.title
    } else {
      textAssetContent = applicableVariant?.text
    }
  } else if (question.type !== 'task') {
    const urlAsset = question.assets?.urls[0]

    if (typeof urlAsset?.url === 'string' && urlAsset.url.length > 0) {
      videoAssetUrl = urlAsset.url
      videoAssetTitle = urlAsset.title
    } else if (typeof question.assets?.images[0] === 'string' && question.assets.images[0].length > 0) {
      imageAssetUrl = question.assets.images[0]
    }
  }

  if (imageAssetUrl) {
    return ({
      type: AssetType.IMAGE,
      url: imageAssetUrl,
      viewed: false,
      forceOpen: true
    })
  } else if (videoAssetUrl) {
    return ({
      forceOpen: true,
      title: videoAssetTitle,
      type: AssetType.VIDEO,
      url: videoAssetUrl,
      viewed: false
    })
  } else if (textAssetContent) {
    return ({
      forceOpen: false,
      text: textAssetContent,
      type: AssetType.TEXT,
      viewed: false
    })
  } else {
    return null
  }
}
