import { pubSubGenerator } from '@feedbackloop/shared'
import log from '@/utilities/LogHandler'

export const MAX_FAIL_COUNT = 3
export const START_SENDING_TIMEOUT = 1000

export interface SendMessage<T> {
  (message: T): Promise<void>
}

enum FailReason {
  OFFLINE,
  HTTP
}

export interface MessageQueueItem<T> {
  message: T
  failCount: number
  failReason: FailReason | null
}

export interface SubscribeCallback<T> {
  (message: MessageQueueItem<T>): any
}

interface IMessageBuffer<T> {
  unsentMessageQueue: MessageQueueItem<T>[]

  pushMessage (queueItem: MessageQueueItem<T>): void
}

/** # Message Buffer
 * ## Pubsubs
 * ### {@link subscribeOffline}
 * ### {@link subscribeOnline}
 * ### {@link subscribeHttpFailure}
 * ### {@link subscribeEmptyQueue}
 * fires when the queue is empty just after the last message is sent. Used by {@link redirectToUrl}
 * **/
export default class MessageBuffer <T> implements IMessageBuffer<T> {
  sendMessage: SendMessage<T>
  interval?: number
  unsentMessageQueue: MessageQueueItem<T>[] = []
  subscribeOffline: (callback: SubscribeCallback<T>) => void
  subscribeOnline: (callback: SubscribeCallback<T>) => void
  subscribeHttpFailure: (callback: SubscribeCallback<T>) => void
  subscribeEmptyQueue: (callback: SubscribeCallback<T | null>) => void

  private readonly handleFailureFromHTTPError: (failedMessage: MessageQueueItem<T>) => void
  private readonly handleFailureFromConnectionLoss: (failedMessage: MessageQueueItem<T>) => void
  private readonly handleSuccessFromConnectionGain: (failedMessage: MessageQueueItem<T>) => void
  private readonly handleEmptyQueue: (lastItem: MessageQueueItem<T>) => void
  private errorCount: number = 0
  private abort: boolean = false
  private isSending: boolean = false
  private messageSet = new Set()
  private messageHashingMethod?: undefined | ((message: T) => (string | number))

  constructor (sendMessage: SendMessage<T>, startSendingQueue: boolean = true, messageHashingMethod?: (message: T) => (string | number)) {
    this.sendMessage = sendMessage

    const offlinePubSub = pubSubGenerator<MessageQueueItem<T>>()
    const httpFailurePubSub = pubSubGenerator<MessageQueueItem<T>>()
    const onlinePubSub = pubSubGenerator<MessageQueueItem<T>>()
    const emptyQueuePubSub = pubSubGenerator<MessageQueueItem<T | null>>()

    this.subscribeOffline = (callback: SubscribeCallback<T>) => {
      offlinePubSub.subscribe(callback)
    }
    this.subscribeHttpFailure = (callback: SubscribeCallback<T>) => {
      httpFailurePubSub.subscribe(callback)
    }
    this.subscribeOnline = (callback: SubscribeCallback<T>) => {
      onlinePubSub.subscribe(callback)
    }
    this.subscribeEmptyQueue = (callback: SubscribeCallback<T|null>) => {
      emptyQueuePubSub.subscribe(callback)
      if (this.unsentMessageQueue.length === 0 && !this.isSending) {
        emptyQueuePubSub.publish({ message: null, failCount: 0, failReason: null })
      }
    }
    this.handleFailureFromHTTPError = (failedMessage: MessageQueueItem<T>) => {
      // Publish event when a message fails a non-trivial amount of times
      failedMessage.failCount++
      failedMessage.failReason = FailReason.HTTP
      if (failedMessage.failCount >= MAX_FAIL_COUNT) {
        httpFailurePubSub.publish(failedMessage)
        this.stopSendingQueue()
      }
    }

    this.handleFailureFromConnectionLoss = (failedMessage: MessageQueueItem<T>) => {
      offlinePubSub.publish(failedMessage)
      failedMessage.failReason = FailReason.OFFLINE
    }

    this.handleSuccessFromConnectionGain = (successfulMessage: MessageQueueItem<T>) => {
      onlinePubSub.publish(successfulMessage)
    }

    this.handleEmptyQueue = (lastMessage: MessageQueueItem<T>) => {
      log.logger.log('Message buffer queue is empty')
      emptyQueuePubSub.publish(lastMessage)
    }

    this.messageHashingMethod = messageHashingMethod

    if (startSendingQueue) {
      this.startSendingQueue().catch(log.logger.error)
    }
  }

  /** ## startSendingQueue
  * This method will pull from the queue in sequence and call {@link sendNextMessage} one-at-a-time until the queue is empty or {@link abort}
   * is true. When it hits an error it increases the time it will pause before attempting to restart. */
  async startSendingQueue () {
    try {
      while (this.unsentMessageQueue.length > 0 && !(this.abort)) {
        await this.sendNextMessage()
      }
      this.errorCount = 0
    } catch (e: any) {
      this.errorCount++
      log.logger.error(e)
    } finally {
      window.setTimeout(() => {
        this.startSendingQueue().catch(log.logger.error)
      }, START_SENDING_TIMEOUT * (this.errorCount + 1))
    }
  }

  stopSendingQueue () {
    this.abort = true
  }

  add (message: T) {
    if (this.messageHashingMethod) {
      const hash = this.messageHashingMethod(message)
      if (this.messageSet.has(hash)) return
      this.messageSet.add(hash)
    }
    this.pushMessage({
      message,
      failCount: 0,
      failReason: null
    })
  }

  async sendNextMessage (): Promise<void> {
    if (this.unsentMessageQueue.length === 0 || this.abort) return
    this.isSending = true
    const nextItem = this.unsentMessageQueue.shift()
    if (!nextItem) return
    const leastRecentMessage = nextItem.message
    try {
      await this.sendMessage(leastRecentMessage)
      if (nextItem.failReason === FailReason.OFFLINE) {
        this.handleSuccessFromConnectionGain(nextItem)
      }

      this.errorCount = 0
      if (this.unsentMessageQueue.length === 0) this.handleEmptyQueue(nextItem)
    } catch (err) {
      this.unsentMessageQueue.unshift(nextItem)
      log.logger.error(`Error ${++this.errorCount} sending message queue item`, nextItem, err)

      if (!navigator.onLine) this.handleFailureFromConnectionLoss(nextItem)
      else this.handleFailureFromHTTPError(nextItem)
    } finally {
      this.isSending = false
    }
  }

  pushMessage (queueItem: MessageQueueItem<T>) {
    this.unsentMessageQueue.push(queueItem)
  }
}
