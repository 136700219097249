import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum HouseholdType {
  FARM = 'Farm',
  OTHER = 'Other',
  OWNED_APARTMENT = 'Owned apartment',
  OWNED_HOUSE = 'Owned house',
  PARENTS = 'Living with parents',
  RENTED_APARTMENT = 'Rented apartment',
  RENTED_HOUSE = 'Rented house',
  UNIVERSITY_RESIDENCE = 'University Residence'
}

export const HouseholdTypeMetadata: MultiselectDemographicFilterMetadata<HouseholdType> = {
  title: 'Household Type',
  question: 'What best describes your current household?',
  options: Object.values(HouseholdType)
}

// lucid question ID is 642
export enum LucidHouseholdType {
  FARM = '5',
  OWNED_APARTMENT = '2',
  OWNED_HOUSE = '4',
  PARENTS = '7',
  RENTED_APARTMENT = '1',
  RENTED_HOUSE = '3',
  UNIVERSITY_RESIDENCE = '6',
  OTHER = '8'
}

export const HouseholdTypeMapper: Record<HouseholdType, LucidHouseholdType> = {
  [HouseholdType.FARM]: LucidHouseholdType.FARM,
  [HouseholdType.OWNED_APARTMENT]: LucidHouseholdType.OWNED_APARTMENT,
  [HouseholdType.OWNED_HOUSE]: LucidHouseholdType.OWNED_HOUSE,
  [HouseholdType.PARENTS]: LucidHouseholdType.PARENTS,
  [HouseholdType.RENTED_APARTMENT]: LucidHouseholdType.RENTED_APARTMENT,
  [HouseholdType.RENTED_HOUSE]: LucidHouseholdType.RENTED_HOUSE,
  [HouseholdType.UNIVERSITY_RESIDENCE]: LucidHouseholdType.UNIVERSITY_RESIDENCE,
  [HouseholdType.OTHER]: LucidHouseholdType.OTHER
}
