/**
 *
 ## LogHandler
 Replacing console.log with LogHandler.logger.log will output to console unless overriden as in the {@link LogRocketLogHandler}.
 This pattern abstracts away devmode handling and logger instantiation, but is a little non-intuitive.
 **/
import { NODE_ENV, VUE_APP_LOGROCKET_PROJECT } from '@/config'
import type { ILog } from '@feedbackloop/types'

export default abstract class LogHandler implements ILog {
  // eslint-disable-next-line no-use-before-define
  protected static _logger : LogHandler

  private static autoConsoleEnvironments = ['development']
  /**
   * if you don't see logs, you might need to {@link init} the handler
   */
  public static get logger (): LogHandler {
    if (!this._logger) {
      this.init()
    }

    return this._logger
  }

  /**
   * initializes the handler with a console logger.
   */
  static init () {
    if (this.autoConsoleEnvironments.includes(NODE_ENV) || VUE_APP_LOGROCKET_PROJECT === 'false') {
      this._logger = new ConsoleLogHandler()
    } else {
      this._logger = { log () { }, error () { }, warn () { }, info () {}, debug () {} }
    }
  }

  abstract log(message: string, ...args: any[]): void
  abstract info(message: string, ...args: any[]): void
  abstract debug(message: string, ...args: any[]): void
  abstract error(message: string, ...args: any[]): void
  abstract warn(message: string, ...args: any[]): void
}

/** TODO: Feels like this is redundant and there should be a cleaner way to pass these methods down. **/
class ConsoleLogHandler extends LogHandler {
  warn (message: string, ...args: any[]): void { console.warn(message, ...args) }
  error (message: string, ...args: any[]): void { console.error(message, ...args) }
  log (message: string, ...args: any[]): void { console.log(message, ...args) }
  info (message: string, ...args: any[]): void { console.info(message, ...args) }
  debug (message: string, ...args: any[]): void { console.debug(message, ...args) }
}

export const logger = LogHandler.logger
