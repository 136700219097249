import type { IAttributionEvent } from '@feedbackloop/shared'
import { AttributionEventType } from '@feedbackloop/shared'

export const testEvent: IAttributionEvent = {
  respondentId: '1',
  orderId: '2',
  eventTime: new Date(),
  eventType: AttributionEventType.AnsweredDemographic,
  audienceId: '3',
  sessionId: '4',
  origin: 'tide',
  eventContext: []
}
