import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum Ethnicity {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'American Indian or Alaska Native',
  ASIAN_INDIAN = 'Asian *** Asian Indian',
  BLACK_OR_AFRICAN_AMERICAN = 'Black or African American',
  CHINESE = 'Asian *** Chinese',
  FILIPINO = 'Asian *** Filipino',
  GUAMANIAN = 'Pacific Islander *** Guamanian',
  JAPANESE = 'Asian *** Japanese',
  KOREAN = 'Asian *** Korean',
  NATIVE_HAWAIIAN = 'Pacific Islander *** Native Hawaiian',
  OTHER = 'Some other race',
  OTHER_ASIAN = 'Asian *** Other Asian',
  OTHER_PACIFIC_ISLANDER = 'Pacific Islander *** Other Pacific Islander',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer',
  SAMOAN = 'Pacific Islander *** Samoan',
  VIETNAMESE = 'Asian *** Vietnamese',
  WHITE = 'White'
}

export const EthnicityMetadata: MultiselectDemographicFilterMetadata<Ethnicity> = {
  title: 'Ethnicity',
  question: 'What is your race?',
  options: Object.values(Ethnicity)
}

// lucid question ID is 113
export enum LucidEthnicity {
  WHITE = '1',
  BLACK_OR_AFRICAN_AMERICAN = '2',
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = '3',
  ASIAN_INDIAN = '4',
  CHINESE = '5',
  FILIPINO = '6',
  JAPANESE = '7',
  KOREAN = '8',
  VIETNAMESE = '9',
  OTHER_ASIAN = '10',
  NATIVE_HAWAIIAN = '11',
  GUAMANIAN = '12',
  SAMOAN = '13',
  OTHER_PACIFIC_ISLANDER = '14',
  OTHER = '15',
  PREFER_NOT_TO_ANSWER = '16'
}

export const EthnicityMapper: Record<Ethnicity, LucidEthnicity> = {
  [Ethnicity.AMERICAN_INDIAN_OR_ALASKA_NATIVE]: LucidEthnicity.AMERICAN_INDIAN_OR_ALASKA_NATIVE,
  [Ethnicity.ASIAN_INDIAN]: LucidEthnicity.ASIAN_INDIAN,
  [Ethnicity.BLACK_OR_AFRICAN_AMERICAN]: LucidEthnicity.BLACK_OR_AFRICAN_AMERICAN,
  [Ethnicity.CHINESE]: LucidEthnicity.CHINESE,
  [Ethnicity.FILIPINO]: LucidEthnicity.FILIPINO,
  [Ethnicity.GUAMANIAN]: LucidEthnicity.GUAMANIAN,
  [Ethnicity.JAPANESE]: LucidEthnicity.JAPANESE,
  [Ethnicity.KOREAN]: LucidEthnicity.KOREAN,
  [Ethnicity.NATIVE_HAWAIIAN]: LucidEthnicity.NATIVE_HAWAIIAN,
  [Ethnicity.OTHER_ASIAN]: LucidEthnicity.OTHER_ASIAN,
  [Ethnicity.OTHER_PACIFIC_ISLANDER]: LucidEthnicity.OTHER_PACIFIC_ISLANDER,
  [Ethnicity.SAMOAN]: LucidEthnicity.SAMOAN,
  [Ethnicity.VIETNAMESE]: LucidEthnicity.VIETNAMESE,
  [Ethnicity.WHITE]: LucidEthnicity.WHITE,
  [Ethnicity.OTHER]: LucidEthnicity.OTHER,
  [Ethnicity.PREFER_NOT_TO_ANSWER]: LucidEthnicity.PREFER_NOT_TO_ANSWER
}
