import type { IScale, TideQuestion } from '@feedbackloop/shared'
import type { IRateScale, IRating } from '@/types/IRateScale'
import type { NonemptyArray } from '@feedbackloop/types'

function getLabelForRateScale (value: number, iScale: IScale): string | undefined {
  const { minLabel, maxLabel, minRating, maxRating } = iScale
  if (value === minRating) { return minLabel }
  if (value === maxRating) { return maxLabel }
}

function getRateScaleFromLabels (labels: string[], minRating: number): IRating[] {
  return labels.map((label, index) => ({
    label,
    value: index + minRating
  }))
}

function getRateScaleFromMinMax (iScale: IScale) {
  const ratings = []
  for (let i = iScale.minRating; i <= iScale.maxRating; i++) {
    ratings.push({
      label: getLabelForRateScale(i, iScale),
      value: i
    })
  }
  return ratings
}

export function getRateScale (question: Pick<TideQuestion, 'scale'>): IRateScale | undefined {
  const iScale = question.scale?.properties
  if (!iScale) return undefined
  const { labels } = iScale
  const ratings = labels && labels.length > 0
    ? getRateScaleFromLabels(labels, iScale.minRating)
    : getRateScaleFromMinMax(iScale)

  if (!ratings[0]) { return undefined } // this is a bad scale.  max rating was less than min rating!!
  return {
    ratings: ratings as NonemptyArray<IRating>
  }
}
