import type { IQuestionAnswerPair } from '@/utilities/engines'
import { evaluateRuleSets } from '@/utilities/engines'
import type { RRQuestion, SubmittedAnswer } from '@feedbackloop/shared'
import { RuleSetAction } from '@feedbackloop/types-graphql'
import type { IRuleSet } from '@feedbackloop/types'

export type AudienceGroupStateOutput = {
  answers: SubmittedAnswer[]
  currentQuestionIndex: number
}

export type AudienceGroupStateInput = AudienceGroupStateOutput & {
  questions: RRQuestion[]
}

export function getNextQuestionIndex (input: AudienceGroupStateInput, allTagsSet: string[]): number | null {
  let nextQuestionIndex: string | number | null | undefined = callRuleEngine(input.questions, input.answers, input.currentQuestionIndex, RuleSetAction.SKIP_TO)?.questionIndex
  if (typeof nextQuestionIndex !== 'number') nextQuestionIndex = null

  if (nextQuestionIndex === null) {
    nextQuestionIndex = getNextQuestionIndexInSurvey(
      input.currentQuestionIndex,
      input.answers.length - 1,
      input.answers,
      input.questions,
      allTagsSet
    )
  }
  if (nextQuestionIndex === null) return null
  return handleDisplayIfLogic(input.questions, input.answers, nextQuestionIndex)
}

function getNextQuestionIndexInSurvey (currentIndex: number, answerIndex: number, submittedAnswers: SubmittedAnswer[], questions: RRQuestion[], allTagsSet: string[]): number | null {
  const startingIndex = currentIndex + 1
  const indexLimit = questions.length

  for (let nextQuestionIndex = startingIndex; nextQuestionIndex < indexLimit; nextQuestionIndex++) {
    const possibleNextQuestion = questions[nextQuestionIndex]
    const requiredTags = possibleNextQuestion.prequal.tags_set

    if (requiredTags === undefined || requiredTags.every((tag: string) => allTagsSet.includes(tag))) {
      return nextQuestionIndex
    }
  }

  return null
}

function handleDisplayIfLogic (questions: RRQuestion[], answers: SubmittedAnswer[], questionIndex: number): number | null {
  const ruleEngineQuestionResult: IRuleSet | null = callRuleEngine(questions, answers, questionIndex, RuleSetAction.DISPLAY_IF)
  const question = questions[questionIndex]

  const hasDisplayIfLogic = question.ruleSets
    ?.some((x: unknown) => {
      if (typeof x !== 'object' || x === null) return false
      if ('action' in x) return x.action === RuleSetAction.DISPLAY_IF
    })

  const newQuestionIndex = hasDisplayIfLogic && ruleEngineQuestionResult === null
    ? questionIndex + 1
    : questionIndex

  // if the result index is greater than the questions the survey is over return null
  const isBeyondSurveyLength = newQuestionIndex >= questions.length
  if (isBeyondSurveyLength) return null

  // if the ruleEngineResult is falsy then the next question failed
  if (hasDisplayIfLogic && ruleEngineQuestionResult === null) return handleDisplayIfLogic(questions, answers, newQuestionIndex)

  return newQuestionIndex
}

function callRuleEngine (questions: RRQuestion[], answers: SubmittedAnswer[], questionIndex: number, actionToCheck: RuleSetAction): IRuleSet | null {
  try {
    const question = questions[questionIndex]
    return evaluateRuleSets(question, getQuestionAnswerPairs(questions, answers), actionToCheck) ?? null
  } catch (error) {
    console.warn(error)
  }
  return null
}

export function goToPreviousQuestion (answers: SubmittedAnswer[]): AudienceGroupStateOutput {
  const lastIndexOfAnswerArray = answers.length - 1
  const lastAnswer = answers[lastIndexOfAnswerArray]
  return {
    answers: answers.slice(0, lastIndexOfAnswerArray),
    currentQuestionIndex: lastAnswer?.questionIndex ?? 0
  }
}

export function goToFirstQuestion (): AudienceGroupStateOutput {
  return {
    answers: [],
    currentQuestionIndex: 1
  }
}

function getQuestionAnswerPairs (questions: RRQuestion[], answers: SubmittedAnswer[]): IQuestionAnswerPair[] {
  return questions.map((q, i) => ({
    question: q,
    answer: answers.find(x => x?.questionIndex === i)
  }))
}
