import { MultiselectDemographicFilterMetadata } from '../demographicFilterMetadata'

export enum BigPurchase {
  CAR = 'Car',
  FLIGHT = 'Flight ticket',
  HOUSE = 'House',
  HOUSEHOLD_APPLIANCES = 'Household Appliances',
  LAPTOP = 'Laptop',
  MOBILE_PHONE = 'Mobile phone',
  NONE_OF_THE_ABOVE = 'None of the above',
  TELEVISION = 'TV'
}

export const BigPurchaseMetadata: MultiselectDemographicFilterMetadata<BigPurchase> = {
  title: 'Big Purchases',
  question: 'Which of the following products have you purchased in the last 2 years, or intend to buy in the next 12 months?',
  options: Object.values(BigPurchase)
}

// lucid question ID is 28288
export enum LucidBigPurchase {
  CAR = '5',
  FLIGHT = '6',
  HOUSE = '7',
  HOUSEHOLD_APPLIANCES = '1',
  LAPTOP = '4',
  MOBILE_PHONE = '3',
  TELEVISION = '2',
  NONE_OF_THE_ABOVE = '-3105',
}

export const BigPurchaseMapper: Record<BigPurchase, LucidBigPurchase> = {
  [BigPurchase.CAR]: LucidBigPurchase.CAR,
  [BigPurchase.FLIGHT]: LucidBigPurchase.FLIGHT,
  [BigPurchase.HOUSE]: LucidBigPurchase.HOUSE,
  [BigPurchase.HOUSEHOLD_APPLIANCES]: LucidBigPurchase.HOUSEHOLD_APPLIANCES,
  [BigPurchase.LAPTOP]: LucidBigPurchase.LAPTOP,
  [BigPurchase.MOBILE_PHONE]: LucidBigPurchase.MOBILE_PHONE,
  [BigPurchase.TELEVISION]: LucidBigPurchase.TELEVISION,
  [BigPurchase.NONE_OF_THE_ABOVE]: LucidBigPurchase.NONE_OF_THE_ABOVE
}
