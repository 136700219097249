/* eslint-disable camelcase */
/* istanbul ignore file */
import type { Method } from 'axios'
import axios from 'axios'
import { SamplechainThreatPotential } from '@feedbackloop/types/src/RespondentSession'
import { VUE_APP_SAMPLECHAIN_KEY } from '@/config'
import type {
  BrowserInfo as ServerBrowserInfo,
  IAttributionEvent,
  RRSurvey,
  TideMessageAttributionEvent
} from '@feedbackloop/shared'
import { getQueryParams, QueueType, TideMessageType } from '@feedbackloop/shared'
import type { BrowserInfo as TideBrowserInfo } from '@/types/RespondentSession'
import type { TideQueryParamMap } from '@feedbackloop/demographics'
import { PanelProvider } from '@feedbackloop/demographics'
import { twoAudiencesAllQuestionTypes } from '@/api/mocks'
import type {
  CompensationStatuses,
  CustomCompensationStatusType,
  ProviderCompensationStatus
} from '@/types/CompensationStatus'
import TideRequester from '@/api/TideRequester'
import { logger } from '@/utilities/LogHandler'
import { checkQueryParamsForAnswerBot } from '@/utilities/devMode'
import { SurveyQueueTarget } from '@/types'
import { getSurveyQueueUrlForProvider } from '@/utilities/providers'

const queryParams = getQueryParams(window.location.search)
const mockApi = process.env.NODE_ENV !== 'production' ? queryParams.mockApi : null
const mockQueueId = process.env.NODE_ENV !== 'production' ? queryParams.mockQueueId : null
const queryKeys = Object.keys(queryParams).map(k => k.toLowerCase())
const isDevMode = queryKeys.includes('devmode')
const isAnswerBotQuery = queryKeys.includes('answerbot')
const isAnswerBot = checkQueryParamsForAnswerBot(queryParams)
const tideRequester = new TideRequester(isDevMode, isAnswerBot)

interface CheckIPResponse {
  hasBlacklistedIp: boolean
  ips: string[]
}

interface SampleChainKrakenResponse {
  threatPotential: SamplechainThreatPotential
}

interface SampleChainTokenResponseItem {
  token: string
  timestamp: string
  message: string
}

interface SampleChainTokenResponse {
  results: SampleChainTokenResponseItem[]
}

type orderQuotaResult = 'success' | 'overquota' | 'failure'

export interface OrderInfo {
  OID: string
  CPI: string | null
  prepaid: string | boolean
  order_quota: string | null
  order_source: string | null
  segment: string
  is_qual: string | boolean
  RID: string
  PID: string
  provider: string // encoded (i.e. '1', '2')
  time_entered: Date
}

export interface UserInfo {
  lucid_status_RR: ProviderCompensationStatus
  quality_term_status_RR: CustomCompensationStatusType
  time_exit: Date
  estimatedLOI: number
  totalTimeInQueue: number
  answersSubmitted: number
  isGP: boolean
  OID: string
  RID: string
  PID: string
  time_entered: Date
  total_questions: number
  audience_questions: number
  demo_questions: number
  RR_questions: number
  provider: string // encoded (i.e. '1', '2')
}

interface LucidHashPayload {
  status: number
  lucid_hash: string
}

async function getTokenFromSampleChain (isDevMode: boolean, respondentSessionClientId: string, audienceId: string, PID: string | undefined) {
  const baseUrl = 'https://prod.rtymgt.com/api/v3'
  let url = `${baseUrl}/respondents/get_token/${VUE_APP_SAMPLECHAIN_KEY}?sn_ud=${respondentSessionClientId}&sy_nr=${audienceId}`
  if (PID) {
    url += '&rt_sr_pd=' + PID
  }

  if (!isDevMode) {
    try {
      const res = await axios.get<SampleChainTokenResponse>(url, { withCredentials: true })
      return res.data.results[0].token || null
    } catch {
      return null
    }
  }

  return null
}

async function getThreatPotentialFromServer (
  isDevMode: boolean,
  token: string,
  respondentSessionClientId: string,
  audienceId: string,
  PID: string | undefined
) {
  if (isDevMode) return SamplechainThreatPotential.HIGH

  // withCredentials sends cookies, and allows the response to set cookies
  try {
    const payload = {
      token,
      respondentSessionClientId,
      audienceId,
      PID
    }
    const res = await tideRequester.sendKrakenRequest<SampleChainKrakenResponse>('POST', '/api/respondents/check-threat', payload)
    return res.threatPotential
  } catch {
    return SamplechainThreatPotential.HIGH
  }
}

export default {
  activePrequalifications (audienceId: string, queueType: QueueType = QueueType.Enterprise): Promise<TideQueryParamMap<string[]>> {
    const payload = { queueType }
    return tideRequester.sendKrakenRequest<TideQueryParamMap<string[]>>('post', `/api/survey-queue/active-prequals/${audienceId}`, payload, 'application/json', true)
  },
  beaconAttributionEvent (event: IAttributionEvent) {
    const message: TideMessageAttributionEvent = {
      payload: event,
      type: TideMessageType.AttributionEvent
    }
    return tideRequester.sendKrakenRequest('POST', '/api/events/tide', message)
  },
  checkIP (): Promise<CheckIPResponse> {
    const checkIpUrl = `/api/browser-info/checkIp${isAnswerBotQuery ? '?answerBot=true' : ''}`
    return tideRequester.sendKrakenRequest<CheckIPResponse>('get', checkIpUrl, null, 'text/plain', true)
  },

  async checkOrderQuota (panel: PanelProvider, OID: string, RID: string, ipAddress: string | undefined): Promise<orderQuotaResult> {
    let orderQuotaStatus: orderQuotaResult // todo: update this
    const payload = {
      OID,
      RID,
      ipAddress,
      panel
    }
    try {
      const result = await tideRequester.sendKrakenRequest('POST', '/api/orders/check-quota', payload)
      orderQuotaStatus = result.status
    } catch (err) {
      orderQuotaStatus = 'failure'
    }
    return orderQuotaStatus
  },

  async dedupe (uid: string, dedupeID: string, ips: string[]): Promise<void> {
    const payload = {
      dedupeID,
      ip_addresses: ips,
      uid
    }
    await tideRequester.sendKrakenRequest<void>('post', '/api/dedupe', payload)
  },

  async getBrowserInfo (): Promise<TideBrowserInfo> {
    const browserInfo = await tideRequester.sendKrakenRequest<ServerBrowserInfo>('get', '/api/browser-info', null, 'application/json', true)
    // translate browser info to use camel case variables
    return {
      clientBotName: browserInfo.client_bot_name,
      clientBrowserIsModern: browserInfo.client_browser_ismodern,
      clientBrowserName: browserInfo.client_browser_name,
      clientBrowserVersion: browserInfo.client_browser_version,
      clientDevice: browserInfo.client_device,
      clientIpAddress: browserInfo.client_ip_add,
      clientIsBot: browserInfo.client_isbot,
      clientOS: browserInfo.client_os,
      clientOSFullname: browserInfo.client_os_fullname,
      clientOSVersion: browserInfo.client_os_version
    }
  },

  async getDisqoRedirectUrl (queryString: string, compensationStatus: CompensationStatuses): Promise<string> {
    const res = await tideRequester.sendKrakenRequest<any>('POST', '/api/orders/generate-disqo-redirect-url', { compensationStatus: compensationStatus.provider, queryString })
    if (!res) return ''
    return res.disqoRedirectUrl
  },

  getPermutationCounter (surveyId: string, questionId?: string): Promise<number> {
    let targetUrl = `/api/get-permutations/${surveyId}`
    if (questionId) targetUrl = `${targetUrl}/${questionId}`
    return tideRequester.sendKrakenRequest<number>('get', targetUrl)
  },

  /** defaults to UNKNOWN, or LOW when {@link isDevMode}. can be forced explicitly if {@link VUE_APP_SAMPLECHAIN_KEY} starts with always_ */
  async getSampleChainScore (respondentSessionClientId: string, audienceId: string, PID?: string): Promise<SamplechainThreatPotential> {
    /** need to append month to deal with samplechain load issues for GP audience */
    if (audienceId === 'gp') {
      const curr_date = new Date()
      audienceId += curr_date.toISOString().replace(/-/g, '').slice(0, 6)
    }

    let threatPotential: SamplechainThreatPotential | undefined = isDevMode ? SamplechainThreatPotential.LOW : SamplechainThreatPotential.UNKNOWN
    if (VUE_APP_SAMPLECHAIN_KEY.startsWith('always_')) {
      const alwaysValue: keyof typeof SamplechainThreatPotential = VUE_APP_SAMPLECHAIN_KEY.substr(7).toUpperCase() as keyof typeof SamplechainThreatPotential
      threatPotential = SamplechainThreatPotential[alwaysValue]
      logger.info(`Samplechain key overriden in config: ${VUE_APP_SAMPLECHAIN_KEY} results in ${threatPotential}`)
      return threatPotential
    }

    const token = await getTokenFromSampleChain(isDevMode, respondentSessionClientId, audienceId, PID)
    if (!token) return threatPotential || SamplechainThreatPotential.HIGH

    threatPotential = await getThreatPotentialFromServer(isDevMode, token, respondentSessionClientId, audienceId, PID)

    if (!threatPotential) {
      throw new Error('Threat potential not found in response')
    }

    return threatPotential
  },

  async lucidHash (hashIngredients: { RID: string | number, OID: string | number, url: string, totalTimeInQueue: number }): Promise<string> {
    const lucidHashResp = await tideRequester.sendKrakenRequest<LucidHashPayload>('POST', '/api/orders/gen_lucid_hash', hashIngredients)
    if (!lucidHashResp) return '' // if time in queue < 30, then we get a 204 no content from qtest
    const { lucid_hash } = lucidHashResp
    return lucid_hash
  },

  async outgoingRRUser (userInfo: UserInfo) {
    await tideRequester.sendKrakenRequest('POST', '/api/orders/outgoing_user', userInfo)
  },

  plagcheck (answer: string): Promise<any> {
    const payload = {
      answer
    }
    return tideRequester.sendKrakenRequest<any>('post', '/api/red-flag/plagiarism-check', payload)
  },

  async rrSurvey (
    id: string,
    validEncodedDemographics: TideQueryParamMap<string | number>,
    queueType: QueueType,
    provider: PanelProvider,
    surveyQueueTarget: SurveyQueueTarget
  ): Promise<RRSurvey> {
    if (mockApi === 'true' && mockQueueId === 'twoAudiencesAllQuestionTypes') {
      return twoAudiencesAllQuestionTypes()
    }

    const payload: any = { ...validEncodedDemographics }

    payload.quickSource = queueType === QueueType.QuickSource
    if (queueType === QueueType.QuickSource) {
      payload.queueType = QueueType.QuickSource
    } else {
      payload.queueType = QueueType.Enterprise
    }

    const { url, httpMethod } = getSurveyQueueUrlForProvider(provider, surveyQueueTarget)
    const rrSurvey: RRSurvey = await tideRequester.sendKrakenRequest<RRSurvey>(<Method>httpMethod, `${url}/${id}`, payload, 'application/json', true)

    return rrSurvey
  },

  saveAttributionEvent (event: IAttributionEvent) {
    const message: TideMessageAttributionEvent = {
      payload: event,
      type: TideMessageType.AttributionEvent
    }
    return tideRequester.sendKrakenRequest('POST', '/api/events/tide', message)
  },

  // Track IR only for audiences that have screener questions
  // Audiences with no screener questions have IR tracked on server side
  async seenUserBefore (orderInfo: OrderInfo) {
    try {
      const result = await tideRequester.sendKrakenRequest('POST', '/api/orders/incoming_user', orderInfo)
      return result !== 'success'
    } catch (err) {
      logger.error('Could not successfully run user seen before check')
    }
  },

  async canRespondentTakeByoaSurvey (surveyId: string): Promise<boolean> {
    // need to do this a better way when we have time
    // for now, grab byoa survey and return true if there are any questions to answer
    const { url, httpMethod } = getSurveyQueueUrlForProvider(PanelProvider.BYOA, SurveyQueueTarget.IndividualSurvey)
    const rrSurvey: RRSurvey = await tideRequester.sendKrakenRequest<RRSurvey>(<Method>httpMethod, `${url}/${surveyId}`, undefined, 'application/json', true)

    const totalQuestions = rrSurvey?.questions?.length ?? 0
    return totalQuestions > 0
  }
}
