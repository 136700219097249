import { DemographicFilter } from '../demographicFilter'
import { DemographicFilterValues } from '../filterValues'
import { LucidPrecode } from '../lucid/types/precode'
import { DisqoPrecode } from '../disqo'
import { ReefQuestionType } from '@feedbackloop/types-graphql'

export enum LucidDemographicFilter {
  AGE = 'AGE',
  BIG_TICKET_PURCHASES='Big_Ticket_Purchases',
  ETHNICITY='ETHNICITY',
  GENDER='GENDER',
  HISPANIC='HISPANIC',
  STANDARD_HEALTH_INSURANCE_US='STANDARD_HEALTH_INSURANCE_US',
  STANDARD_CAR_USE='STANDARD_CAR_USE',
  FINANCIAL_PRODUCT='Financial_Product',
  MS_IS_MOBILE='MS_is_mobile',
  PARENTAL_STATUS_STANDARD='Parental_Status_Standard',
  REGION='REGION',
  RELATIONSHIP_STATUS = 'RELATIONSHIP_STATUS',
  STANDARD_B2B_DECISION_MAKER='STANDARD_B2B_DECISION_MAKER',
  STANDARD_COMPANY_DEPARTMENT='STANDARD_COMPANY_DEPARTMENT',
  STANDARD_EDUCATION='STANDARD_EDUCATION',
  STANDARD_EDUCATION_V2='STANDARD_EDUCATION_v2',
  STANDARD_EMPLOYMENT='STANDARD_EMPLOYMENT',
  STANDARD_HHI_US='STANDARD_HHI_US',
  STANDARD_HHI='STANDARD_HHI',
  STANDARD_HOUSEHOLD_TYPE='STANDARD_HOUSEHOLD_TYPE',
  STANDARD_INDUSTRY_PERSONAL='STANDARD_INDUSTRY_PERSONAL',
  STANDARD_JOB_TITLE='STANDARD_JOB_TITLE',
  STANDARD_NO_OF_EMPLOYEES='STANDARD_NO_OF_EMPLOYEES',
  STANDARD_RELATIONSHIP='STANDARD_RELATIONSHIP',
  STATE='STATE',
  ZIP = 'ZIP'
}

export enum DisqoDemographicFilterOnly {
  FINANCIAL_DECISION_MAKER = 'FINANCIAL_DECISION_MAKER',
  INSURANCE_TYPE = 'INSURANCE_TYPE',
  INVESTABLE_ASSETS = 'INVESTABLE_ASSETS',
  LOAN_TYPE = 'STANDARD_LOAN_TYPE'
}

export enum ProvidedDemographics {
  RID = 'RID',
  PID = 'PID',
  OID = 'OID',
  SUPPLIER_ID = 'SUPPLIER_ID',
  SUPPLIER_NAME = 'SUPPLIER_NAME',
  FULCRUM_UID='FULCRUM_UID',
  // REGION = 'REGION',
  PROVIDER = 'provider',
  COUNTRY = 'COUNTRY',
  INTERNATIONAL = 'INTERNATIONAL'
}

export enum FeedBackLoopQueryCallBackFields {
  QUANTITY='QQ',
  CPI='CC',
  SRC='src',
  QUICKSOURCE='quickSourcing'
}

export const TideQueryFieldNames = { ...ProvidedDemographics, ...FeedBackLoopQueryCallBackFields, ...LucidDemographicFilter, ...DisqoDemographicFilterOnly }
export type TideQueryFieldName = typeof TideQueryFieldNames[keyof typeof TideQueryFieldNames]

export type TideQueryParamMap<t> = Partial<Record<TideQueryFieldName, t>>

export const lucidToFblDemographicFilter: Record<LucidDemographicFilter, DemographicFilter> = {
  [LucidDemographicFilter.AGE]: DemographicFilter.AGE,
  [LucidDemographicFilter.BIG_TICKET_PURCHASES]: DemographicFilter.BIG_TICKET_PURCHASES,
  [LucidDemographicFilter.ETHNICITY]: DemographicFilter.ETHNICITY,
  [LucidDemographicFilter.GENDER]: DemographicFilter.GENDER,
  [LucidDemographicFilter.HISPANIC]: DemographicFilter.HISPANICS,
  [LucidDemographicFilter.STANDARD_HEALTH_INSURANCE_US]: DemographicFilter.HEALTH_INSURANCE,
  [LucidDemographicFilter.STANDARD_CAR_USE]: DemographicFilter.CAR,
  [LucidDemographicFilter.FINANCIAL_PRODUCT]: DemographicFilter.FINANCIAL_PRODUCT,
  [LucidDemographicFilter.MS_IS_MOBILE]: DemographicFilter.MOBILE_DEVICE,
  [LucidDemographicFilter.PARENTAL_STATUS_STANDARD]: DemographicFilter.PARENTAL_STATUS,
  [LucidDemographicFilter.REGION]: DemographicFilter.REGION,
  [LucidDemographicFilter.RELATIONSHIP_STATUS]: DemographicFilter.RELATIONSHIP_STATUS,
  [LucidDemographicFilter.STANDARD_B2B_DECISION_MAKER]: DemographicFilter.B2B_DECISION_MAKER,
  [LucidDemographicFilter.STANDARD_COMPANY_DEPARTMENT]: DemographicFilter.COMPANY_DEPARTMENT,
  [LucidDemographicFilter.STANDARD_EDUCATION]: DemographicFilter.EDUCATION,
  [LucidDemographicFilter.STANDARD_EDUCATION_V2]: DemographicFilter.EDUCATION,
  [LucidDemographicFilter.STANDARD_EMPLOYMENT]: DemographicFilter.EMPLOYMENT,
  [LucidDemographicFilter.STANDARD_NO_OF_EMPLOYEES]: DemographicFilter.EMPLOYEE_COUNT,
  [LucidDemographicFilter.STANDARD_HHI]: DemographicFilter.HHIUS,
  [LucidDemographicFilter.STANDARD_HHI_US]: DemographicFilter.HHIUS,
  [LucidDemographicFilter.STANDARD_INDUSTRY_PERSONAL]: DemographicFilter.INDUSTRY,
  [LucidDemographicFilter.STANDARD_HOUSEHOLD_TYPE]: DemographicFilter.HOUSEHOLD_TYPE,
  [LucidDemographicFilter.STANDARD_JOB_TITLE]: DemographicFilter.JOB_TITLE,
  [LucidDemographicFilter.STANDARD_RELATIONSHIP]: DemographicFilter.RELATIONSHIP_STATUS,
  [LucidDemographicFilter.STATE]: DemographicFilter.STATE,
  [LucidDemographicFilter.ZIP]: DemographicFilter.ZIP_CODE
}

export type DemographicQuestionAnswer<TAnswerValueText> = {
  text: TAnswerValueText
  value: LucidPrecode | DisqoPrecode
  shuffle: boolean
}

export type DemographicQuestion<TAnswerValueText = DemographicFilterValues> = {
  id: DemographicFilter
  answers?: Array<DemographicQuestionAnswer<TAnswerValueText>>
}

export interface DecodedDemographicsAndContext {
  demographicContext: DemographicQuestion
  participantSelection: any // TODO: Completion block by standardizing answer types
}

type AllowedTideDemographicQuestionType = ReefQuestionType.Number | ReefQuestionType.MultiSelect | ReefQuestionType.SingleSelect

export const demographicToQuestionType: Record<DemographicFilter, AllowedTideDemographicQuestionType> = {
  [DemographicFilter.AGE]: ReefQuestionType.Number,
  [DemographicFilter.B2B_DECISION_MAKER]: ReefQuestionType.MultiSelect,
  [DemographicFilter.BIG_TICKET_PURCHASES]: ReefQuestionType.MultiSelect,
  [DemographicFilter.COMPANY_DEPARTMENT]: ReefQuestionType.SingleSelect,
  [DemographicFilter.CAR]: ReefQuestionType.SingleSelect,
  [DemographicFilter.EDUCATION]: ReefQuestionType.SingleSelect,
  [DemographicFilter.EMPLOYEE_COUNT]: ReefQuestionType.SingleSelect,
  [DemographicFilter.EMPLOYMENT]: ReefQuestionType.SingleSelect,
  [DemographicFilter.ETHNICITY]: ReefQuestionType.SingleSelect,
  [DemographicFilter.FINANCIAL_DECISION_MAKER]: ReefQuestionType.SingleSelect,
  [DemographicFilter.FINANCIAL_PRODUCT]: ReefQuestionType.MultiSelect,
  [DemographicFilter.GENDER]: ReefQuestionType.SingleSelect,
  [DemographicFilter.HEALTH_INSURANCE]: ReefQuestionType.SingleSelect,
  [DemographicFilter.HHIUS]: ReefQuestionType.SingleSelect,
  [DemographicFilter.HISPANICS]: ReefQuestionType.SingleSelect,
  [DemographicFilter.HOUSEHOLD_TYPE]: ReefQuestionType.SingleSelect,
  [DemographicFilter.INDUSTRY]: ReefQuestionType.SingleSelect,
  [DemographicFilter.INSURANCE_TYPE]: ReefQuestionType.MultiSelect,
  [DemographicFilter.INVESTABLE_ASSETS]: ReefQuestionType.MultiSelect,
  [DemographicFilter.JOB_TITLE]: ReefQuestionType.SingleSelect,
  [DemographicFilter.LOAN_TYPE]: ReefQuestionType.MultiSelect,
  [DemographicFilter.MOBILE_DEVICE]: ReefQuestionType.SingleSelect,
  [DemographicFilter.PARENTAL_STATUS]: ReefQuestionType.MultiSelect,
  [DemographicFilter.REGION]: ReefQuestionType.SingleSelect,
  [DemographicFilter.RELATIONSHIP_STATUS]: ReefQuestionType.SingleSelect,
  [DemographicFilter.STATE]: ReefQuestionType.SingleSelect,
  [DemographicFilter.ZIP_CODE]: ReefQuestionType.Number
}
