import type { DirectiveOptions, DirectiveFunction } from 'vue'
import type { DirectiveBinding } from 'vue/types/options'
import { kebabCase } from 'lodash'

const setAttribute = (element: HTMLElement, value: string) => {
  element.setAttribute('data-test', kebabCase(value))
}

const updateAttributeValue = (element: HTMLElement, binding: DirectiveBinding) => {
  if (binding.value === binding.oldValue) {
    return
  }

  element.removeAttribute('data-test')
  setAttribute(element, binding.value)
}

const bind: DirectiveFunction = (el: HTMLElement, binding: DirectiveBinding) => {
  if (!binding.value) {
    return
  }
  setAttribute(el, binding.value)
}

const update: DirectiveFunction = (el: HTMLElement, binding: DirectiveBinding) => {
  updateAttributeValue(el, binding)
}

const testDirective: DirectiveOptions = {
  bind,
  update
}

export {
  testDirective
}
