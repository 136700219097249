import type { RRSurvey, RRQuestion } from '@feedbackloop/shared'
import type { AudienceGroup, ClientSurvey, RespondentSurvey } from '@/types'
import { SurveyQueueTarget } from '@/types'
import { cloneDeep } from 'lodash-es'
import LogHandler from '@/utilities/LogHandler'
import { PanelProvider } from '@feedbackloop/demographics'

function audienceHasRequiredScreeners (audience: AudienceGroup, autoTags: string[]): boolean {
  const allQuestionsInAudienceGroup = audience.surveys.reduce((questions: RRQuestion[], currentSurvey) => {
    questions.push(...currentSurvey.questions)
    return questions
  }, [])

  const allQuestionsRequireSuccessTag = allQuestionsInAudienceGroup.every(
    question => !!question.prequal.tags_set?.find(
      (tag: string) => !!tag.match('SUCCESS_')
    )
  )

  // eslint-disable-next-line camelcase
  const successTag = audience.surveys[0].questions[0].prequal.tags_set?.find(tag => typeof tag === 'string' && tag.match('SUCCESS_'))

  const autoTagSatisfiesAudience = (typeof successTag === 'string' && autoTags.includes(successTag))

  if (!audience.screeners && allQuestionsRequireSuccessTag && !autoTagSatisfiesAudience) {
    LogHandler.logger.error(`Surveys received without their required screeners. Audienience id: ${audience.id}`)
    return false
  } else {
    return true
  }
}

export function parseAudienceRespondentSurveys (rrSurvey: RRSurvey): RespondentSurvey {
  rrSurvey = cloneDeep(rrSurvey)

  const audiences: AudienceGroup[] = []
  let currentAudience: AudienceGroup
  let currentClientSurvey: ClientSurvey
  let herring: RRQuestion | undefined

  // Group questions into audience groups
  rrSurvey.questions.forEach((question: RRQuestion) => {
    // Don't include herring in groups
    if (question.id === 'herring') {
      herring = question
      return
    }

    // Set the working audience.
    // We know we have a new audience when the segment_name changes
    // TODO: .segment_name and .tap shouldn't be optional except of herring
    if (!currentAudience || currentAudience.id !== question.segment_name) {
      const id = question.segment_name ?? 'unknown'
      let name: string
      if (id === 'gp') {
        name = 'gp'
      } else {
        name = rrSurvey.segment_id_map && question.segment_name ? rrSurvey.segment_id_map[question.segment_name] : 'unknown'
      }

      currentAudience = {
        id,
        name,
        surveys: []
      }

      audiences.push(currentAudience)
    }

    // Set the working survey
    // Surveys change when tap id's change
    if (!currentClientSurvey || currentClientSurvey.id !== question.tap) {
      currentClientSurvey = {
        id: question.tap || 'unknown',
        type: question.segment_type || 'unknown',
        dedupeId: question.dedupeID,
        questions: []
      }
      // If its a screener add as screener
      if (question.segment_type === 'segment') {
        if (currentAudience.screeners) {
          LogHandler.logger.error('Audience already has screener')
          return
        }
        currentAudience.screeners = currentClientSurvey
      } else {
        currentAudience.surveys.push(currentClientSurvey)
      }
    }

    currentClientSurvey.questions.push(question)
  })
  const audiencesWithoutMissingScreeners = audiences.filter((audience) => audienceHasRequiredScreeners(audience, rrSurvey.auto_tags))

  return {
    audiences: audiencesWithoutMissingScreeners,
    herring
  }
}

export function parseRespondentSurvey (rrSurvey: RRSurvey, provider: PanelProvider, surveyId: string): RespondentSurvey {
  rrSurvey = cloneDeep(rrSurvey)

  const audiences: AudienceGroup[] = []
  const isByoa = provider === PanelProvider.BYOA
  const isActiveMeasure = provider === PanelProvider.ACTIVE_MEASURE
  const isExternalAudience = isByoa || isActiveMeasure
  let herring: RRQuestion | undefined
  let audienceId = surveyId
  if (isByoa) audienceId = 'byoa'
  if (isActiveMeasure) audienceId = 'active-measure'

  audiences.push({
    id: audienceId,
    name: audienceId,
    surveys: []
  })

  if (isExternalAudience && !rrSurvey.questions.length) {
    return { audiences, herring: undefined }
  }

  let surveyType: 'quant' | 'qual' | 'segment'
  if (rrSurvey.questions[1] && rrSurvey.questions[1].type === 'task') surveyType = 'qual'
  else if (rrSurvey.questions[0].is_audience) surveyType = 'segment'
  else surveyType = 'quant'

  const currentClientSurvey: ClientSurvey = {
    id: rrSurvey.questions[0].tap || 'unknown',
    type: surveyType,
    questions: []
  }
  audiences[0].surveys.push(currentClientSurvey)

  // Group questions into audience groups
  rrSurvey.questions.forEach((question: RRQuestion) => {
    // Don't include herring in groups
    if (question.id === 'herring') {
      herring = question
      return
    }

    currentClientSurvey.questions.push(question)
  })
  const audiencesWithoutMissingScreeners = audiences.filter((audience) => audienceHasRequiredScreeners(audience, rrSurvey.auto_tags))

  return {
    audiences: audiencesWithoutMissingScreeners,
    herring
  }
}

export function parseRespondentSurveyFromRRSurvey (rrSurvey: RRSurvey, surveyQueueTarget: SurveyQueueTarget, provider: PanelProvider, surveyId: string): RespondentSurvey {
  if (surveyQueueTarget === SurveyQueueTarget.Audience) {
    return parseAudienceRespondentSurveys(rrSurvey)
  }

  return parseRespondentSurvey(rrSurvey, provider, surveyId)
}
