/* eslint-disable camelcase */

import type { QtestDropType, RRQuestion, RRSurvey } from '@feedbackloop/shared'

interface GenerateQuestionOptions {
  type: QtestDropType['payload']['type']
  tapId: string
  clientAndDedupeId: string
  indexInSurvey: number
  asset?: { type: 'image' | 'video', url: string }
  requiredTags?: string[]
  tagsThatWillBeSet?: { tag: string, answerText: string }[]
}

/**
 * Generate a question that would be part of a queue. A quant survey question is the default, segment and qual questions can be generated via overrides.
 * @param options see GenerateQuestionOptions
 * @param overrides Fields will be overwritten at the top level
 * @returns RRQuestion
 */
function generateQuestion (options: GenerateQuestionOptions, overrides: Partial<RRQuestion> = {}): RRQuestion {
  const { type, tapId, clientAndDedupeId, indexInSurvey, asset, tagsThatWillBeSet } = options

  let initialQuestion: RRQuestion

  const assets = {
    images: asset?.type === 'image' ? [asset.url] : [],
    urls: [
      {
        title: '',
        url: asset?.type === 'video' ? asset.url : '',
        forceOpen: true
      }
    ]
  }

  const tags_set = options.requiredTags || []

  switch (type) {
    case 'description':
      initialQuestion = {
        type: 'description',
        icon: '”',
        instruction: 'ⓘ Hint: Describe a product or instruct the user',
        prequal: {
          tags_set,
          completed: 0
        },
        content: 'Now, we will be discussing <b>popcorn.&nbsp;</b><strong><br><br></strong>Be sure to answer each question honestly and to the best of your ability. Thanks!',
        viz_version: 2,
        assets,
        naOpt: false,
        followup: false,
        otherOpt: false,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        q_createdAt: 1596551329,
        id: `${tapId}_${indexInSurvey}`,
        tap: `${tapId}`,
        is_audience: false,
        mobile_test: false,
        active_views: null,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        auto_survey: null,
        dedupeID: `${clientAndDedupeId}`,
        account_id: null,
        pulse: false,
        subquota: null
      }
      break
    case 'number':
      initialQuestion = {
        type: 'number',
        icon: '#',
        instruction: '',
        prequal: {
          tags_set,
          completed: 0
        },
        content: 'How many times in a week do you eat popcorn?&nbsp;',
        scale_number: {
          properties: {
            minValue: 0,
            maxValue: 50,
            naOption: false
          }
        },
        viz_version: 2,
        assets,
        naOpt: false,
        followup: false,
        otherOpt: false,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        q_createdAt: 1596551329,
        id: `${tapId}_${indexInSurvey}`,
        tap: `${tapId}`,
        is_audience: false,
        mobile_test: false,
        active_views: null,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        auto_survey: null,
        dedupeID: `${clientAndDedupeId}`,
        account_id: null,
        pulse: false,
        subquota: null
      }
      break
    case 'single-select':
      initialQuestion = {
        account_id: null,
        active_views: null,
        answers: [
          {
            text: 'Every day',
            shuffle: false,
            label: 'Every day',
            herring: false,
            set_tag: []
          },
          {
            text: 'This option does nothing',
            shuffle: false,
            label: 'This option does nothing',
            herring: false,
            set_tag: []
          },
          {
            text: 'Once per week',
            shuffle: false,
            label: 'Once per week',
            herring: false,
            set_tag: []
          },
          {
            text: 'A few times per month',
            shuffle: false,
            label: 'A few times per month',
            herring: false,
            set_tag: []
          },
          {
            text: 'Once per month',
            shuffle: false,
            label: 'Once per month',
            herring: false,
            set_tag: []
          },
          {
            text: 'A few times per year',
            shuffle: false,
            label: 'A few times per year',
            herring: false,
            set_tag: []
          },
          {
            text: 'Never',
            shuffle: false,
            label: 'Never',
            herring: false,
            set_tag: []
          }
        ],
        assets,
        auto_survey: null,
        chart_title: 'Frequency of Behavior',
        content: 'How frequently do you<!---client-info-start-->&nbsp;purchase popcorn?',
        dedupeID: `${clientAndDedupeId}`,
        explainOpt: false,
        followup: false,
        herringOpt: false,
        icon: '⌾',
        id: `${tapId}_${indexInSurvey}`,
        instruction: 'ⓘ Instructional text: "Please select one:"',
        instruction_qual: 'ⓘ Instructional text: "Please select one."',
        inverse: false,
        is_audience: false,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        mobile_test: false,
        naOpt: false,
        otherOpt: false,
        post_answers_instruction_qual: 'ⓘ Instructional text: "Why did you select that answer? Please explain out loud."',
        prequal: {
          tags_set
        },
        pulse: false,
        q_createdAt: 1596551329,
        segment: false,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        sort: false,
        subquota: null,
        tap: `${tapId}`,
        type: 'single-select',
        viz_version: 1
      }
      break
    case 'multi-select':
      initialQuestion = {
        account_id: null,
        active_views: null,
        answers: [
          {
            text: 'Ranch',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [

            ],
            label: 'Ranch'
          },
          {
            text: 'Sriracha',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [

            ],
            label: 'Sriracha'
          },
          {
            text: 'Honey',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [

            ],
            label: 'Honey'
          },
          {
            text: 'Cajun spices',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [

            ],
            label: 'Cajun spices'
          },
          {
            text: 'Honey BBQ',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [

            ],
            label: 'Honey BBQ'
          },
          {
            text: 'Other',
            shuffle: false,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [

            ]
          }
        ],
        assets,
        auto_survey: null,
        content: "Please select your <b>Top 2 </b>potential&nbsp;flavors for popcorn you'd be most interested in.&nbsp;",
        dedupeID: `${clientAndDedupeId}`,
        followup: false,
        herringOpt: false,
        icon: '☑',
        id: `${tapId}_${indexInSurvey}`,
        instruction: 'ⓘ Instructional text: "Select all that apply:"',
        instruction_qual: 'ⓘ Instructional text: "Select all that apply."',
        is_audience: false,
        limitOpt: true,
        maximum: 2,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        mobile_test: false,
        naOpt: false,
        otherOpt: true,
        post_answers_instruction_qual: 'ⓘ Instructional text: "Why did you select that answer? Please explain out loud."',
        prequal: {
          completed: 0,
          tags_set
        },
        pulse: false,
        q_createdAt: 1596551329,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        subquota: null,
        tap: `${tapId}`,
        type: 'multi-select',
        viz_version: 2
      }
      break
    case 'rank':
      initialQuestion = {
        type: 'rank',
        icon: '⋱',
        instruction: 'ⓘ Instructional text: "Move the items below to reorder."',
        prequal: {
          tags_set,
          completed: 0
        },
        content: 'Please rank the following popcorn flavors from <b>m</b><strong>ost interested</strong> to <strong>least interested.</strong>',
        answers: [
          {
            text: 'Ranch',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Ranch'
          },
          {
            text: 'Sriracha',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Sriracha'
          },
          {
            text: 'Honey',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Honey'
          },
          {
            text: 'Cajun spices',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Cajun spices'
          },
          {
            text: 'Honey BBQ',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Honey BBQ'
          }
        ],
        viz_version: 2,
        assets,
        followup: false,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        q_createdAt: 1596551329,
        id: `${tapId}_${indexInSurvey}`,
        tap: `${tapId}`,
        is_audience: false,
        mobile_test: false,
        active_views: null,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        auto_survey: null,
        dedupeID: `${clientAndDedupeId}`,
        account_id: null,
        pulse: false,
        subquota: null
      }
      break
    case 'matrix-likert':
      initialQuestion = {
        type: 'matrix-likert',
        icon: '⊹',
        instruction: 'ⓘ Hint: Respondent will rate each of the following items.',
        prequal: {
          tags_set,
          completed: 0
        },
        content: 'Please rate how interested or not interested you are in these <b>popcorn flavors.</b>',
        scale: {
          dimension: 'interest',
          properties: {
            naOption: false,
            maxRating: 5,
            minRating: 1,
            minLabel: 'Not at all interested',
            maxLabel: 'Extremely interested'
          },
          grouping: [
            {
              percentage: {
                max: 1,
                min: 0.68
              },
              label: 'Interested'
            },
            {
              percentage: {
                max: 0.67,
                min: 0.33
              },
              label: 'Somewhat Interested'
            },
            {
              percentage: {
                max: 0.32,
                min: 0
              },
              label: 'Not Interested'
            }
          ]
        },
        answers: [
          {
            text: 'Ranch',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Ranch'
          },
          {
            text: 'Sriracha',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Sriracha'
          },
          {
            text: 'Honey',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Honey'
          },
          {
            text: 'Cajun spices',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Cajun spices'
          },
          {
            text: 'Honey BBQ',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [],
            label: 'Honey BBQ'
          }
        ],
        viz_version: 2,
        assets,
        naOpt: false,
        followup: false,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        q_createdAt: 1596551329,
        id: `${tapId}_${indexInSurvey}`,
        tap: `${tapId}`,
        is_audience: false,
        mobile_test: false,
        active_views: null,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        auto_survey: null,
        dedupeID: `${clientAndDedupeId}`,
        account_id: null,
        pulse: false,
        subquota: null
      }
      break
    case 'textarea':
      initialQuestion = {
        type: 'textarea',
        icon: '✎',
        instruction: 'ⓘ Hint: Respondent will use a text box to answer.',
        prequal: {
          tags_set,
          completed: 0
        },
        content: 'What other flavors, if any, would you like to see on <b>popcorn</b>? <i>Please explain your answer in detail.&nbsp;</i>',
        viz_version: 2,
        assets,
        naOpt: false,
        followup: false,
        otherOpt: false,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        q_createdAt: 1596551329,
        id: `${tapId}_${indexInSurvey}`,
        tap: `${tapId}`,
        is_audience: false,
        mobile_test: false,
        active_views: null,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        auto_survey: null,
        dedupeID: `${clientAndDedupeId}`,
        account_id: null,
        pulse: false,
        subquota: null
      }
      break
    case 'rate':
      initialQuestion = {
        type: 'rate',
        icon: '⩸',
        instruction: 'ⓘ Instructional text: "Please explain your answer."',
        instruction_qual: 'ⓘ Instructional text: "Why did you select that answer? Please explain out loud."',
        prequal: {
          tags_set,
          completed: 0
        },
        content: 'How interested or not interested would you be in popcorn that had <strong>no salt?</strong>',
        scale: {
          dimension: 'custom',
          properties: {
            naOption: false,
            maxRating: 5,
            minRating: 1,
            minLabel: 'Not at all intersted',
            maxLabel: 'Extremely interested'
          },
          grouping: [
            {
              percentage: {
                max: 1,
                min: 0.68
              },
              label: 'Extremely interested'
            },
            {
              percentage: {
                max: 0.67,
                min: 0.33
              },
              label: 'Neutral'
            },
            {
              percentage: {
                max: 0.32,
                min: 0
              },
              label: 'Not at all intersted'
            }
          ]
        },
        viz_version: 2,
        assets,
        naOpt: false,
        followup: false,
        otherOpt: false,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        q_createdAt: 1596551329,
        id: `${tapId}_${indexInSurvey}`,
        tap: `${tapId}`,
        is_audience: false,
        mobile_test: false,
        active_views: null,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        auto_survey: null,
        dedupeID: `${clientAndDedupeId}`,
        account_id: null,
        pulse: false,
        subquota: null
      }
      break
    case 'rate-only':
      initialQuestion = {
        type: 'rate-only',
        icon: '⩸',
        instruction: '',
        prequal: {
          tags_set,
          completed: 0
        },
        content: 'How interested or not interested would you be in popcorn that had <strong>no sugar?</strong>',
        scale: {
          dimension: 'custom',
          properties: {
            naOption: false,
            maxRating: 5,
            minRating: 1,
            minLabel: 'Not at all interested',
            maxLabel: 'Extremely interested'
          },
          grouping: [
            {
              percentage: {
                max: 1,
                min: 0.68
              },
              label: 'Extremely interested'
            },
            {
              percentage: {
                max: 0.67,
                min: 0.33
              },
              label: 'Neutral'
            },
            {
              percentage: {
                max: 0.32,
                min: 0
              },
              label: 'Not at all interested'
            }
          ]
        },
        viz_version: 2,
        assets,
        naOpt: false,
        followup: false,
        otherOpt: false,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        q_createdAt: 1596551329,
        id: `${tapId}_${indexInSurvey}`,
        tap: `${tapId}`,
        is_audience: false,
        mobile_test: false,
        active_views: null,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        auto_survey: null,
        dedupeID: `${clientAndDedupeId}`,
        account_id: null,
        pulse: false,
        subquota: null
      }
      break
    case 'variant':
      initialQuestion = {
        type: 'variant',
        icon: '⚄',
        instruction: 'ⓘ Hint: Respondent will be shown ONE variant option.',
        prequal: {
          tags_set,
          completed: 0
        },
        content: 'Variants',
        answers: [
          {
            text: 'Please CLICK ON THE IMAGE BELOW to open in a separate tab of your browser.<br><br>Take a few moments to review this image in its entirety until you have a sense for the information.<br><br>When you are finished, return here and click “Next" to begin the survey.',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: asset?.type === 'video' ? asset.url : ''
            },
            image: asset?.type === 'image' ? asset.url : '',
            set_tag: [],
            label: 'Variant A',
            var_tag: `q_${indexInSurvey}_var_1`
          },
          {
            text: 'Please CLICK ON THE IMAGE BELOW to open in a separate tab of your browser.<br><br>Take a few moments to review this image in its entirety until you have a sense for the information.<br><br>When you are finished, return here and click “Next" to begin the survey.',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: asset?.type === 'video' ? asset.url : ''
            },
            image: asset?.type === 'image' ? asset.url : '',
            set_tag: [],
            label: 'Variant B',
            var_tag: `q_${indexInSurvey}_var_2`
          }
        ],
        viz_version: 2,
        assets,
        naOpt: false,
        followup: false,
        otherOpt: false,
        meta: {
          client_id: `${clientAndDedupeId}`,
          card_id: '412'
        },
        q_createdAt: 1596551329,
        id: `${tapId}_${indexInSurvey}`,
        tap: `${tapId}`,
        is_audience: false,
        mobile_test: false,
        active_views: null,
        segment_name: null,
        segment_type: 'quant',
        segment_updated_at: 1596570182,
        auto_survey: null,
        dedupeID: `${clientAndDedupeId}`,
        account_id: null,
        pulse: false,
        subquota: null
      }
      break
    default:
      throw new Error(`Invalid question type: ${type}`)
  }

  const finalQuestion = Object.assign({}, initialQuestion, overrides)

  // This must happen after setting overrides in case the answerTexts for setting tags rely on answer text provided via overrides
  if (finalQuestion.answers && tagsThatWillBeSet) {
    finalQuestion.answers.forEach(answer => {
      tagsThatWillBeSet.forEach(({ tag, answerText }) => {
        if (answer.text.includes(answerText)) {
          answer.set_tag.push(tag)
        }
      })
    })
  }

  return finalQuestion
}

export function surveyWithEveryQuestionType (clientAndDedupeId: string): RRSurvey {
  const tapId = Math.floor(Math.random() * 10000000).toString()
  const survey: RRSurvey = {
    auto_tags: [

    ],
    questions: [
      generateQuestion({
        asset: {
          type: 'image',
          url: 'https://assets.alphahq.com/puvoxvdliqo7y750.png'
        },
        clientAndDedupeId,
        indexInSurvey: 1,
        tapId,
        type: 'description'
      }),
      generateQuestion({
        clientAndDedupeId,
        indexInSurvey: 2,
        tapId,
        type: 'number'
      }),
      generateQuestion({
        clientAndDedupeId,
        indexInSurvey: 3,
        tagsThatWillBeSet: [
          {
            answerText: 'Every day',
            tag: '1_ovsunk2qg6'
          },
          {
            answerText: 'This option does nothing',
            tag: '1_ovsunk2qg6'
          },
          {
            answerText: 'Once per week',
            tag: '1_ovsunk2qg6'
          },
          {
            answerText: 'A few times per month',
            tag: '1_ovsunk2qg6'
          },
          {
            answerText: 'Once per month',
            tag: '1_ovsunk2qg6'
          }
        ],
        tapId,
        type: 'single-select'
      }),
      generateQuestion({
        type: 'rank',
        clientAndDedupeId,
        tapId,
        indexInSurvey: 4,
        requiredTags: ['1_ovsunk2qg6']
      }),
      generateQuestion({
        type: 'matrix-likert',
        clientAndDedupeId,
        tapId,
        indexInSurvey: 5,
        requiredTags: ['1_ovsunk2qg6']
      }),
      generateQuestion({
        type: 'multi-select',
        clientAndDedupeId,
        tapId,
        indexInSurvey: 6,
        requiredTags: ['1_ovsunk2qg6']
      }),
      generateQuestion({
        type: 'textarea',
        clientAndDedupeId,
        tapId,
        indexInSurvey: 7,
        requiredTags: ['1_ovsunk2qg6']
      }),
      generateQuestion({
        type: 'rate',
        clientAndDedupeId,
        tapId,
        indexInSurvey: 8
      }),
      generateQuestion({
        type: 'rate-only',
        clientAndDedupeId,
        tapId,
        indexInSurvey: 9
      }),
      generateQuestion({
        type: 'variant',
        clientAndDedupeId,
        tapId,
        indexInSurvey: 10
      }),
      generateQuestion({
        type: 'single-select',
        clientAndDedupeId,
        tapId,
        indexInSurvey: 11
      }, {
        answers: [
          {
            text: 'Option A',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [

            ],
            label: 'Option A'
          },
          {
            text: 'Option B',
            shuffle: true,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [

            ],
            label: 'Option B'
          },
          {
            text: 'None of the above',
            shuffle: false,
            followup: false,
            herring: false,
            url: {
              title: '',
              url: ''
            },
            image: false,
            set_tag: [

            ]
          }
        ]
      })
    ],
    meta: {
      variants: {
        [`${tapId}_10`]: 'q_10_var_2'
      }
    },
    name: 'User Research Survey'
  }
  return Object.assign({}, survey)
}

function assignSurveyToAudience (survey: RRSurvey, tapId: string, segmentName: string) {
  survey.questions = survey.questions.map((question: RRQuestion) => {
    if (question.prequal.tags_set) {
      question.prequal.tags_set.push(`SUCCESS_${segmentName}`)
      question.segment_name = segmentName
    }
    return question
  })
  return survey
}

export function screenerQuestion (segmentName: string, options: GenerateQuestionOptions, overrides: Partial<RRQuestion> = {}) {
  const overridesIncludingNecessaryScreenerFields = Object.assign({}, overrides, {
    answers: [
      {
        herring: false,
        label: 'This option sets the tag',
        set_tag: [`SUCCESS_${segmentName}`],
        shuffle: false,
        text: 'This option sets the tag'
      },
      {
        herring: false,
        label: 'This option does nothing',
        set_tag: [],
        shuffle: false,
        text: 'This option does nothing'
      },
      {
        herring: false,
        label: 'This option disqualifies',
        qualification_impact: 'disqualifies',
        set_tag: [],
        shuffle: false,
        text: 'This option disqualifies'
      }
    ],
    dedupeID: null,
    is_audience: true,
    segment: true,
    segment_name: segmentName,
    segment_type: 'segment',
    segment_updated_at: 1596570182
  })
  return generateQuestion(options, overridesIncludingNecessaryScreenerFields)
}

export function twoAudiencesAllQuestionTypes () {
  const clientAndDedupeId1 = Math.floor(Math.random() * 10000000).toString()
  const clientAndDedupeId2 = Math.floor(Math.random() * 10000000).toString()
  const clientAndDedupeId3 = Math.floor(Math.random() * 10000000).toString()
  const screenerTapId1 = Math.floor(Math.random() * 10000000).toString()
  const screenerTapId2 = Math.floor(Math.random() * 10000000).toString()

  const screener1 = screenerQuestion('audienceId1', {
    clientAndDedupeId: clientAndDedupeId1,
    indexInSurvey: 1,
    tapId: screenerTapId1,
    type: 'single-select'
  })
  const screener2 = screenerQuestion('audienceId2', {
    clientAndDedupeId: clientAndDedupeId2,
    indexInSurvey: 1,
    tapId: screenerTapId2,
    type: 'single-select'
  })

  const survey1 = surveyWithEveryQuestionType(clientAndDedupeId1)
  const survey2 = surveyWithEveryQuestionType(clientAndDedupeId2)
  const survey3 = surveyWithEveryQuestionType(clientAndDedupeId3)

  return generateMockQueue('Stub', [{
    screener: [screener1],
    surveys: [survey1, survey2]
  }, {
    screener: [screener2],
    surveys: [survey3]
  }])
}

export function generateMockQueue (name: string, audiences: { screener?: RRQuestion[], surveys: RRSurvey[] }[], auto_tags: string[] = []) {
  const questions: RRQuestion[] = []
  const meta = {
    variants: {}
  }
  const segment_id_map: Record<string, string> = {}

  audiences.forEach(({ screener, surveys }) => {
    if (screener) {
      questions.push(...screener)
      segment_id_map[screener[0].segment_name!] = screener[0].segment_name!
      surveys = surveys.map(survey => assignSurveyToAudience(survey, screener[0].tap!, screener[0].segment_name!))
    } else {
      surveys = surveys.map(survey => {
        survey.questions.map(question => {
          question.segment_name = 'gp'
          return question
        })
        return survey
      })
    }

    surveys.forEach(survey => {
      questions.push(...survey.questions)
      Object.assign(meta.variants, survey.meta.variants)
    })
  })

  return {
    auto_tags,
    meta,
    name,
    questions,
    segment_id_map
  }
}

// TODO: Just use generateQuestion instead where this function is invoked
export const questionsWithRedHerring = (clientAndDedupeId: string) => {
  const { questions } = surveyWithEveryQuestionType(clientAndDedupeId)

  const singleSelect = questions.find(question => question.type === 'single-select')!
  const multiSelect = questions.find(question => question.type === 'multi-select')!

  const questionsToModify = [singleSelect, multiSelect]

  questionsToModify.forEach((question: any) => {
    question.prequal.tags_set = []
    question.herringOpt = true
    question.answers![0].herring = true
  })

  return { multiSelect, singleSelect }
}
