// these are channel enums, not demographic enums

export const EthnicitySlicingThresholdDate = new Date(2021, 2, 26) // talk to research cutoff date for asking custom ethnicity questions, so no clash on data

export enum RespondentSource {
  LUCID = 'lucid',
  CINT = 'cint'
}

export enum RespondentGender {
  MALE = 'Male',
  FEMALE = 'Female'
}

export enum RespondentRegion {
  NORTHEAST = 'Northeast',
  MIDWEST = 'Midwest',
  SOUTH = 'South',
  WEST = 'West'
}

export enum RespondentStandardEmployment {
  EMPLOYED_FULL_TIME = 'Employed full-time',
  EMPLOYED_PART_TIME = 'Employed part-time',
  SELF_EMPLOYED_FULL_TIME = 'Self-employed full-time',
  SELF_EMPLOYED_PART_TIME = 'Self-employed part-time',
  ACTIVE_MILITARY = 'Active military',
  INACTIVE_MILITARY_OR_VETERAN = 'Inactive military/Veteran',
  TEMPORARILY_UNEMPLOYED = 'Temporarily unemployed',
  FULL_TIME_HOMEMAKER = 'Full-time homemaker',
  RETIRED = 'Retired',
  STUDENT = 'Student',
  DISABLED = 'Disabled',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer'
}

export enum RespondentStandardNumberOfEmployees {
  N_1 = '1',
  N_2_TO_10 = '2-10',
  N_11_TO_50 = '11-50',
  N_51_TO_100 = '51-100',
  N_101_TO_500 = '101-500',
  N_501_TO_1000 = '501-1000',
  N_1001_TO_5000 = '1001-5000',
  N_MORE_THAN_5000 = 'Greater than 5000',
  UNKNOWN = "I don't work/I don't know"
}

export enum RespondentStandardHHI {
  N_0_TO_14999 = '$0 to $14,999',
  N_15000_TO_19999 = '$15,000 to $19,999',
  N_20000_TO_24999 = '$20,000 to $24,999',
  N_25000_TO_29999 = '$25,000 to $29,999',
  N_30000_TO_34999 = '$30,000 to $34,999',
  N_35000_TO_39999 = '$35,000 to $39,999',
  N_40000_TO_44999 = '$40,000 to $44,999',
  N_45000_TO_49999 = '$45,000 to $49,999',
  N_50000_TO_54999 = '$50,000 to $54,999',
  N_55000_TO_59999 = '$55,000 to $59,999',
  N_60000_TO_64999 = '$60,000 to $64,999',
  N_65000_TO_69999 = '$65,000 to $69,999',
  N_70000_TO_74999 = '$70,000 to $74,999',
  N_75000_TO_79999 = '$75,000 to $79,999',
  N_80000_TO_84999 = '$80,000 to $84,999',
  N_85000_TO_89999 = '$85,000 to $89,999',
  N_90000_TO_94999 = '$90,000 to $94,999',
  N_95000_TO_99999 = '$95,000 to $99,999',
  N_100000_TO_124999 = '$100,000 to $124,999',
  N_125000_TO_149999 = '$125,000 to $149,999',
  N_150000_TO_174999 = '$150,000 to $174,999',
  N_175000_TO_199999 = '$175,000 to $199,999',
  N_200000_TO_249999 = '$200,000 to $249,999',
  N_250000_OR_MORE = '$250,000 and above',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer'
}

export enum RespondentStandardHHIUS {
  N_LESS_THAN_5000 = 'Less than $5,000',
  N_5000_TO_9999 = '$5,000 to $9,999',
  N_10000_TO_14999 = '$10,000 to $14,999',
  N_15000_TO_19999 = '$15,000 to $19,999',
  N_20000_TO_24999 = '$20,000 to $24,999',
  N_25000_TO_29999 = '$25,000 to $29,999',
  N_30000_TO_34999 = '$30,000 to $34,999',
  N_35000_TO_39999 = '$35,000 to $39,999',
  N_40000_TO_44999 = '$40,000 to $44,999',
  N_45000_TO_49999 = '$45,000 to $49,999',
  N_50000_TO_54999 = '$50,000 to $54,999',
  N_55000_TO_59999 = '$55,000 to $59,999',
  N_60000_TO_64999 = '$60,000 to $64,999',
  N_65000_TO_69999 = '$65,000 to $69,999',
  N_70000_TO_74999 = '$70,000 to $74,999',
  N_75000_TO_79999 = '$75,000 to $79,999',
  N_80000_TO_84999 = '$80,000 to $84,999',
  N_85000_TO_89999 = '$85,000 to $89,999',
  N_90000_TO_94999 = '$90,000 to $94,999',
  N_95000_TO_99999 = '$95,000 to $99,999',
  N_100000_TO_124999 = '$100,000 to $124,999',
  N_125000_TO_149999 = '$125,000 to $149,999',
  N_150000_TO_174999 = '$150,000 to $174,999',
  N_175000_TO_199999 = '$175,000 to $199,999',
  N_200000_TO_249999 = '$200,000 to $249,999',
  N_250000_OR_MORE = '$250,000 and above',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer'
}

export enum RespondentStandardIndustryPersonal {
  ACCOUNTING = 'Accounting',
  ADVERTISING = 'Advertising',
  AGRICULTURE_FISHING = 'Agriculture/Fishing',
  ARCHITECTURE = 'Architecture',
  AUTOMOTIVE = 'Automotive',
  AVIATION = 'Aviation',
  BANKING_FINANCIAL = 'Banking/Financial',
  BIO_TECH = 'Bio-Tech',
  BROKERAGE = 'Brokerage',
  CARPENTING_ELECTRICAL_INSTALLATIONS_VVS = 'Carpenting/Electrical installations/VVS',
  CHEMICALS_PLASTICS_RUBBER = 'Chemicals/Plastics/Rubber',
  COMMUNICATIONS_INFORMATION = 'Communications/Information',
  COMPUTER_HARDWARE = 'Computer Hardware',
  COMPUTER_RESELLER_SOFTWARE_HARDWARE = 'Computer Reseller (software/hardware)',
  COMPUTER_SOFTWARE = 'Computer Software',
  CONSTRUCTION = 'Construction',
  CONSULTING = 'Consulting',
  CONSUMER_ELECTRONICS = 'Consumer Electronics',
  CONSUMER_PACKAGED_GOODS = 'Consumer Packaged Goods',
  EDUCATION = 'Education',
  ENERGY_UTILITIES_OIL_AND_GAS = 'Energy/Utilities/Oil and Gas',
  ENGINEERING = 'Engineering',
  ENVIRONMENTAL_SERVICES = 'Environmental Services',
  FASHION_APPAREL = 'Fashion/Apparel',
  FOOD_BEVERAGE = 'Food/Beverage',
  GOVERNMENT_PUBLIC_SECTOR = 'Government/Public Sector',
  HEALTHCARE = 'Healthcare',
  HOSPITALITY_TOURISM = 'Hospitality/Tourism',
  HUMAN_RESOURCES = 'Human Resources',
  INFORMATION_TECHNOLOGY = 'Information Technology/IT',
  INSURANCE = 'Insurance',
  INTERNET = 'Internet',
  LEGAL_LAW = 'Legal/Law',
  MANUFACTURING = 'Manufacturing',
  MARKET_RESEARCH = 'Market Research',
  MARKETING = 'Marketing',
  MARKETING_SALES = 'Marketing/Sales',
  MEDIA_ENTERTAINMENT = 'Media/Entertainment',
  MILITARY = 'Military',
  NON_PROFIT_SOCIAL_SERVICES = 'Non Profit/Social services',
  PERSONAL_SERVICES = 'Personal Services',
  PHARMACEUTICALS = 'Pharmaceuticals',
  PRINTING_PUBLISHINNG = 'Printing Publishing',
  PUBLIC_RELATIONS = 'Public Relations',
  REAL_ESTATE_PROPERTY = 'Real Estate/Property',
  RETAIL_WHOLESALE_TRADE = 'Retail/Wholesale trade',
  SALES = 'Sales',
  SECURITY = 'Security',
  SHIPPING_DISTRIBUTION = 'Shipping/Distribution',
  TELECOMMUNICATIONS = 'Telecommunications',
  TRANSPORTATION = 'Transportation',
  OTHER = 'Other',
  I_DO_NOT_WORK = "I don't work"
}

export enum RespondentStandardInsuranceType {
  AUTO_INSURANCE = 'Auto Insurance',
  HOME_INSURANCE = 'Home Insurance',
  RENTERS_INSURANCE = 'Renters Insurance',
  HEALTH_INSURANCE = 'Health Insurance',
  LIFE_INSURANCE = 'Life Insurance Policies Not Provided By Employer',
  LIFE_INSURANCE_EMPLOYER = 'Life Insurance Policies Provided By Employer',
  EMERGENCY_ROADSIDE_ASSISTANCE = 'Emergency Roadside Assistance',
  MOTORCYCLE_INSURANCE = 'Motorcycle Insurance',
  PET_INSURANCE = 'Pet Insurance',
  NONE = 'None of the Above'
}

export enum RespondentStandardInvestableAssets {
  N_LESS_THAN_50000 = 'Less than $50,000',
  N_50000_TO_74999 = '$50,000 - $74,999',
  N_75000_TO_99999 = '$75,000 - $99,999',
  N_100000_TO_149999 = '$100,000 - $149,999',
  N_150000_TO_199999 = '$150,000 - $199,999',
  N_200000_TO_494999 = '$200,000 - $499,999',
  N_500000_TO_999999 = '$500,000 - $999,999',
  N_1000000_OR_MORE = '$1,000,000 or more',
  NONE = 'No investable assets',
  PREFER_NOT_TO_ANSWER = 'Prefer not to say'
}

export enum RespondentStandardEducation {
  THIRD_GRADE_OR_LESS = '3rd Grade or less',
  MIDDLE_SCHOOL = 'Middle School - Grades 4 - 8',
  SOME_HIGH_SCHOOL = 'Completed some high school',
  HIGH_SCHOOL_GRADUATE = 'High school graduate',
  VOCATIONAL_TRAINING = 'Other post high school vocational training',
  SOME_COLLEGE = 'Completed some college, but no degree',
  ASSOCIATE_DEGREE = 'Associate Degree',
  COLLEGE_DEGREE = 'College Degree (such as B.A., B.S.)',
  SOME_GRADUATE = 'Completed some graduate, but no degree',
  MASTERS_DEGREE = 'Masters degree',
  DOCTORATE_DEGREE = 'Doctorate degree',
  OTHER = 'None of the above'
}

export enum RespondentStandardEducationV2 {
  SOME_HIGH_SCHOOL = 'Some high school or less',
  HIGH_SCHOOL_GRADUATE = 'High school graduate',
  VOCATIONAL_TRAINING = 'Other post high school vocational training',
  SOME_COLLEGE = 'Completed some college, but no degree',
  ASSOCIATE_DEGREE = 'Associate Degree',
  COLLEGE_DEGREE = 'Bachelor\'s Degree',
  MASTERS_DEGREE = 'Master\'s or professional degree',
  DOCTORATE_DEGREE = 'Doctorate degree',
  OTHER = 'None of the above'
}

export enum RespondentState {
  ALABAMA = 'ALABAMA',
  ALASKA = 'ALASKA',
  AMERICAN_SAMOA = 'American Samoa',
  ARIZONA = 'ARIZONA',
  ARKANSAS = 'ARKANSAS',
  CALIFORNIA = 'CALIFORNIA',
  COLORADO = 'COLORADO',
  CONNECTICUT = 'CONNECTICUT',
  DELAWARE = 'DELAWARE',
  DISTRICT_OF_COLUMBIA = 'DISTRICT OF COLUMBIA',
  FEDERATED_STATES_OF_MICRONESIA = 'Federated States of Micronesia',
  FLORIDA = 'FLORIDA',
  GEORGIA = 'GEORGIA',
  GUAM = 'Guam',
  HAWAII = 'HAWAII',
  IDAHO = 'IDAHO',
  ILLINOIS = 'ILLINOIS',
  INDIANA = 'INDIANA',
  IOWA = 'IOWA',
  KANSAS = 'KANSAS',
  KENTUCKY = 'KENTUCKY',
  LOUISIANA = 'LOUISIANA',
  MAINE = 'MAINE',
  MARSHALL_ISLANDS = 'Marshall Islands',
  MARYLAND = 'MARYLAND',
  MASSACHUSETTS = 'MASSACHUSETTS',
  MICHIGAN = 'MICHIGAN',
  MINNESOTA = 'MINNESOTA',
  MISSISSIPPI = 'MISSISSIPPI',
  MISSOURI = 'MISSOURI',
  MONTANA = 'MONTANA',
  NEBRASKA = 'NEBRASKA',
  NEVADA = 'NEVADA',
  NEW_HAMPSHIRE = 'NEW HAMPSHIRE',
  NEW_JERSEY = 'NEW JERSEY',
  NEW_MEXICO = 'NEW MEXICO',
  NEW_YORK = 'NEW YORK',
  NORTH_CAROLINA = 'NORTH CAROLINA',
  NORTH_DAKOTA = 'NORTH DAKOTA',
  NORTHERN_MARIANA_ISLANDS = 'Northern Mariana Islands',
  NOT_APPLICABLE = 'NOT APPLICABLE',
  OHIO = 'OHIO',
  OKLAHOMA = 'OKLAHOMA',
  OREGON = 'OREGON',
  PALAU = 'Palau',
  PENNSYLVANIA = 'PENNSYLVANIA',
  PUERTO_RICO = 'Puerto Rico',
  RHODE_ISLAND = 'RHODE ISLAND',
  SOUTH_CAROLINA = 'SOUTH CAROLINA',
  SOUTH_DAKOTA = 'SOUTH DAKOTA',
  TENNESSEE = 'TENNESSEE',
  TEXAS = 'TEXAS',
  UTAH = 'UTAH',
  VERMONT = 'VERMONT',
  VIRGIN_ISLANDS = 'Virgin Islands',
  VIRGINIA = 'VIRGINIA',
  WASHINGTON = 'WASHINGTON',
  WEST_VIRGINIA = 'WEST VIRGINIA',
  WISCONSIN = 'WISCONSIN',
  WYOMING = 'WYOMING'
}

export enum RespondentEthnicity {
  WHITE = 'White',
  BLACK_OR_AFRICAN_AMERICAN = 'Black or African American',
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'American Indian or Alaska Native',
  ASIAN_INDIAN = 'Asian *** Asian Indian',
  CHINESE = 'Asian *** Chinese',
  FILIPINO = 'Asian *** Filipino',
  JAPANESE = 'Asian *** Japanese',
  KOREAN = 'Asian *** Korean',
  VIETNAMESE = 'Asian *** Vietnamese',
  OTHER_ASIAN = 'Asian *** Other Asian',
  SAMOAN = 'Pacific Islander *** Samoan',
  NATIVE_HAWAIIAN = 'Pacific Islander *** Native Hawaiian',
  GUAMANIAN = 'Pacific Islander *** Guamanian',
  OTHER_PACIFIC_ISLANDER = 'Pacific Islander *** Other Pacific Islander',
  OTHER = 'Some other race',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer'
}

export enum RespondentHispanic {
  NO = 'No, not of Hispanic, Latino, or Spanish origin',
  MEXICAN_OR_CHICANO = 'Yes, Mexican, Mexican American, Chicano',
  CUBAN = 'Yes, Cuban',
  ARGENTINA = 'Yes, Argentina',
  COLOMBIA = 'Yes, Colombia',
  ECUADOR = 'Yes, Ecuador',
  EL_SALVADORE = 'Yes, El Salvadora',
  GUATEMALA = 'Yes, Guatemala',
  NICARAGUA = 'Yes, Nicaragua',
  PANAMA = 'Yes, Panama',
  PERU = 'Yes, Peru',
  SPAIN = 'Yes, Spain',
  VENEZUELA = 'Yes, Venezuela',
  PUERTO_RICO = 'Yes, Puerto Rican',
  OTHER = 'Yes, Other Country',
  PREFER_NOT_TO_ANSWER = 'Prefer not to answer'
}

export enum SamplechainThreatPotential {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  UNKNOWN = 'unknown'
}

export enum RespondentMobileDevice {
  YES = 'Yes',
  NO = 'No'
}

export enum RespondentStandardJobTitle {
  EXECUTIVE = 'C-Level (e.g. CEO, CFO), Owner, Partner, President',
  VICE_PRESIDENT = 'Vice President (EVP, SVP, AVP, VP)',
  DIRECTOR = 'Director (Group Director, Sr. Director, Director)',
  MANAGER = 'Manager (Group Manager, Sr. Manager, Manager, Program Manager)',
  ANALYST = 'Analyst',
  ASSISTANT_ASSOCIATE = 'Assistant or Associate',
  ADMINISTRATIVE = 'Administrative (Clerical or Support Staff)',
  CONSULTANT = 'Consultant',
  INTERN = 'Intern',
  VOLUNTEER = 'Volunteer',
  NONE_OF_THE_ABOVE = 'None of the above'
}

export enum RespondentStandardLoanType {
  AUTO = 'Auto Loan',
  STUDENT = 'Student Loan',
  MORTGAGE = 'Mortage',
  CREDIT_CARD = 'Credit Card Loan',
  PERSONAL = 'Personal Loans (Non Credit Card)',
  NONE = 'None of the Above'
}

export enum RespondentParentalStatusStandard {
  PREGNANT = 'I am pregnant/expecting a child within the next 9 months',
  HAVE_CHILDREN_UNDER_18 = 'I have one or more children under the age of 18 living in my household',
  HAVE_CHILDREN_OVER_18 = 'I have one or more children aged 18 or older living in my household',
  NO_CHILDREN_IN_HOUSEHOLD = 'I have no children living in my household and I am not pregnant/expecting a child within the next 9 months'
}

export enum RespondentStandardHouseholdType {
  RENTED_APARTMENT = 'Rented apartment',
  OWNED_APARTMENT = 'Owned apartment',
  RENTED_HOUSE = 'Rented house',
  OWNED_HOUSE = 'Owned house',
  FARM = 'Farm',
  UNIVERSITY_RESIDENCE = 'University Residence',
  PARENTS = 'Living with parents',
  OTHER = 'Other'
}

export enum RespondentStandardCompanyDepartment {
  ADMINISTRATION = 'Administration/General Staff',
  CUSTOMER_SERVICE = 'Customer Service/Client Service',
  EXECUTIVE_LEADERSHIP = 'Executive Leadership',
  FINANCE = 'Finance/Accounting',
  HUMAN_RESOURCES = 'Human Resources',
  LEGAL = 'Legal/Law',
  MARKETING = 'Marketing',
  OPERATIONS = 'Operations',
  PROCUREMENT = 'Procurement',
  SALES = 'Sales',
  TECHNOLOGY_HARDWARE = 'Technology Development Hardware (not only IT)',
  TECHNOLOGY_SOFTWARE = 'Technology Development Software (not only IT)',
  TECHNOLOGY_IMPLEMENTATION = 'Technology Implementation',
  OTHER = 'Other',
  DONT_WORK = 'I don\'t work'
}

export enum RespondentStandardB2BDecisionMaker {
  IT_HARDWARE = 'IT Hardware',
  IT_SOFTWARE = 'IT Software',
  PRINTERS_AND_COPIERS = 'Printers and copiers',
  FINANCES = 'Financial Department',
  HUMAN_RESOURCES = 'Human Resources',
  OFFICE_SUPPLIES = 'Office supplies',
  CORPORATE_TRAVEL = 'Corporate travel',
  TELECOMMUNICATIONS = 'Telecommunications',
  SALES = 'Sales',
  SHIPPING = 'Shipping',
  OPERATIONS = 'Operations',
  LEGAL_SERVICES = 'Legal services',
  MARKETING_ADVERTISING = 'Marketing / Advertising',
  SECURITY = 'Security',
  FOOD_SERVICES = 'Food services',
  AUTO_LEASING = 'Auto leasing / purchasing',
  PROCUREMENT = 'Procurement',
  OTHER = 'Other',
  NONE = 'I don\'t have influence or decision making authority'
}

export enum RespondentBigTicketPurchases {
  HOUSEHOLD_APPLIANCES = 'Household Appliances',
  TELEVISION = 'TV',
  MOBILE_PHONE = 'Mobile phone',
  LAPTOP = 'Laptop',
  CAR = 'Car',
  FLIGHT = 'Flight ticket',
  HOUSE = 'House',
  NONE_OF_THE_ABOVE = 'None of the above'
}

export enum RaceCategory {
  White='White',
  BlackAA='Black, or African American',
  AmericanIndianAlaskanNative='American Indian or Alaska Native',
  Asian='Asian',
  PacificIlander='Pacific Islander',
  Other='Some other race',
  NA='Prefer not to answer'
}

export enum HispanicCategory {
  No='No, not of Hispanic, Latino, or Spanish origin',
  Mexican='Yes, Mexican, Mexican American, Chicano',
  Cuban='Yes, Cuban',
  PuertoRican='Yes, Puerto Rican',
  OtherHispanic='Yes, another Hispanic, Latino, or Spanish origin',
  NA='Prefer not to answer'
}

export enum RaceAndHispanicCategory {
  Hispanic='Hispanic',
  White='White Non-Hispanic',
  Black='Black Non-Hispanic',
  Asian='Asian Non-Hispanic',
  Other='Other Non-Hispanic',
  NA='Prefer not to answer'
}
